export const RecipientsTranslations = {
  es: {
    texts: {
      spanTitle: 'Copiados',
      genericModalTitle1: 'Advertencia',
      genericModalBody1:
        'Existen varios RFC o ID asociados a esta cuenta de correo. Para continuar pregunta a tu firmante a que RFC o ID se debe asociar este contrato y completa la información en el campo de RFC.',
      p1: 'No hay copiados',
      option1: 'Elegir usuario',
    },
    inputs: {
      inputPlaceholder1: 'Buscar Copiados',
      inputPlaceholder2: 'Correo electrónico',
      inputPlaceholder3: 'Nombre',
      inputPlaceholder4: 'RFC',
    },
    buttons: {
      genericModalCancelButton1: 'Cancelar',
      button1: 'Agregar copiado +',
    },
  },
  en: {
    texts: {
      spanTitle: 'Copied recipients',
      genericModalTitle1: 'Warning',
      genericModalBody1:
        'There are several RFC or ID associated with this email account. To continue, ask your signer to which RFC or ID this contract should be associated with. Complete the information in the RFC field.',
      p1: 'There are no copied recipients',
      option1: 'Select user',
    },
    inputs: {
      inputPlaceholder1: 'Search copied recipients',
      inputPlaceholder2: 'Email',
      inputPlaceholder3: 'Name',
      inputPlaceholder4: 'RFC',
    },
    buttons: {
      genericModalCancelButton1: 'Cancel',
      button1: 'Add copied recipient +',
    },
  },
};
