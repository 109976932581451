import React from 'react';
import { branch } from 'baobab-react/higher-order';
import { statusInContractUtils } from '../../utils/contractsContainerUtils';
import {
  inputCheckbox,
  redirectTo,
  validateIcon,
} from '../../helpers/ContractRowHelpers';
import moment from 'moment';

class Row extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {}

  render() {
    const {
      signerOrAdmin,
      contract,
      labels,
      history,
      user,
      actualPage,
      selectContractToSign,
      downloadFile,
    } = this.props;
    return (
      <tr
        style={{
          fontWeight: contract.status === 'forMe' && !signerOrAdmin ? 700 : 100,
          cursor: 'pointer',
        }}
      >
        {!signerOrAdmin ? (
          <td
            className="center_table_data"
            title={statusInContractUtils(contract.status)}
          >
            {inputCheckbox(contract, user, selectContractToSign)}
            {validateIcon(contract)}
          </td>
        ) : (
          <td
            className="center_table_data"
            title={statusInContractUtils(contract.status)}
          >
            {validateIcon(contract)}
            {}
          </td>
        )}

        <td
          title={contract.title}
          onClick={() =>
            redirectTo(
              contract.id,
              contract.status,
              history,
              user,
              contract,
              signerOrAdmin,
              actualPage,
            )
          }
        >
          {contract.title.length <= 35
            ? contract.title
            : `${contract.title.substring(0, 30)}...`}
          <span
            className={
              labels?.length >= 5
                ? 'float-right mbm-10'
                : 'float-right align-center'
            }
          >
            {labels?.map((label) =>
              label.contract === contract.id ? (
                <span
                  key={label.id}
                  className="label"
                  style={{ background: `${label.color}` }}
                />
              ) : null,
            )}
          </span>
        </td>
        <td>
          {contract.createdAt
            ? moment(contract.createdAt).format('D MMM YYYY')
            : false}
        </td>
        <td title={contract.legalName}>
          {contract.legalName.length <= 35
            ? contract.legalName
            : `${contract.legalName.substring(0, 30)}`}
        </td>
        <td>
          {contract.expiresOn === 'Error'
            ? ''
            : contract.expiresOn === null ||
              contract.expiresOn === '0000-00-00 00:00:00'
            ? 'Indefinido'
            : moment(contract.expiresOn).format('DD MMM YYYY')}
        </td>
        {!signerOrAdmin ? (
          <td>{contract.addendums > 0 ? ` + ${contract.addendums}` : ''}</td>
        ) : null}
        <td className="center_table_data">
          {contract.status === 'ready' &&
          contract.hasOwnProperty('downloadedPdf') ? (
            <i
              className="fas fa-file-download"
              style={{
                marginRight: 7,
                color: contract.isDownload ? '#2851e5' : null,
              }}
              onClick={() => downloadFile(contract)}
            ></i>
          ) : null}
          {/* {!signerOrAdmin ? (
            <i
              className="fas fa-ellipsis-v"
              style={{ float: 'right', color: '#2851e5' }}
              onContextMenu={(event) => onContextMenu(event, contract, user)}
            ></i>
          ) : null} */}
        </td>
      </tr>
    );
  }
}

export default branch(
  {
    session: ['session'],
    labels: ['labels'],
  },
  Row,
);
