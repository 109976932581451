import React from 'react';
import Loader from '../Loader';
import moment from 'moment';
import ContractActions from '../../actions/Contract';
import { NotificationManager } from 'react-notifications';
import { branch } from 'baobab-react/higher-order';

class SelectDateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirming: false,
      contracts: 0,
      attribute: '',
      excelDownloadType: 'byDocument',
    };
  }

  showConfirmation = () => {
    this.setState({ confirming: true });
  };

  getContractsToDownload = async () => {
    const { signer, user, fromDate, toDate } = this.props;
    const { attribute } = this.state;
    const id = signer ? signer.rootUser : user.id;
    const legalRfc = signer ? signer.legalRfc : user.legalRfc;
    const { data } = await ContractActions.getContractsPendingToDownload({
      id,
      legalRfc,
      from: fromDate,
      to: toDate,
      signer,
      attribute,
    });
    if (data.error) {
      //TODO: translate response
      return NotificationManager.error(data.error, 'Error', 5000);
    }
    this.setState({ contracts: data });
    this.setState({ confirming: true });
  };

  handleChangeSelect = (e, type) => {
    if (type === 'excel') {
      return this.setState({ excelDownloadType: e.target.value });
    }
    this.setState({ attribute: e.target.value });
  };

  disabledButton = () => {
    const { attribute } = this.state;
    const { fromDate, toDate, loading, downloadType } = this.props;

    if (!downloadType) {
      return !fromDate || !toDate || !attribute || loading;
    }

    return !fromDate || !toDate || loading;
  };

  render() {
    const {
      handleCancel,
      onInputDateChange,
      downloadMassivePdfByDate,
      loading,
      fromDate,
      toDate,
      downloadType,
      downloadExcel,
      hasFilter,
    } = this.props;
    const { contracts, confirming, attribute, excelDownloadType } = this.state;
    const { lang, translations } = this.props;
    const { texts, inputs, buttons } =
      translations[lang].Modals.SelectDateModal;
    return (
      <div className="modal-container">
        <div className="modal modal_select_date">
          {loading ? (
            <div className="center_loading">
              <h3>{texts.h3}</h3>
              <p>{texts.p1}</p>
              <Loader />
            </div>
          ) : (
            <div className="selected_date_modal">
              <div className="header_modal">
                <div className="title_modal">
                  <h3>{downloadType ? texts.h3_2 : texts.h3_3}</h3>
                  <small>{texts.small1}</small>
                </div>
              </div>

              <div className="body_modal">
                {/* WARNING DOWNLOAD EXCEL */}
                {hasFilter && downloadType ? (
                  <div className="warning_download_excel">
                    <small>{texts.small7} </small>
                    <small>{texts.small8}</small>
                  </div>
                ) : null}

                {!downloadType ? (
                  <select
                    name="attribute"
                    className="dropdonw_download_pdf"
                    onChange={this.handleChangeSelect}
                  >
                    <option style={{ fontWeight: 'bold' }} selected disabled>
                      {inputs.selectOptionDefault}
                    </option>
                    <option value="createdAt">{inputs.selectOption1}</option>
                    <option value="lastSignedAt">{inputs.selectOption2}</option>
                  </select>
                ) : null}

                {/* INPUTS DATE */}
                {/* <div className=''>

                </div> */}
                <div className="choose_input_date">
                  <small>{texts.small2}</small>
                  <div className="div_input_date">
                    <input
                      type="date"
                      name="fromDate"
                      value={moment(fromDate).format('YYYY-MM-DD')}
                      onChange={(e) => onInputDateChange(e)}
                      required
                      placeholder={inputs.inputPlaceholder1}
                      autoComplete="nope"
                      className="input_nc"
                    />
                  </div>
                </div>

                <div className="choose_input_date">
                  <small>{texts.small3}</small>
                  <div className="div_input_date">
                    <input
                      type="date"
                      name="toDate"
                      value={moment(toDate).format('YYYY-MM-DD')}
                      onChange={(e) => onInputDateChange(e)}
                      required
                      placeholder={inputs.inputPlaceholder2}
                      autoComplete="nope"
                      className="input_nc"
                    />
                  </div>
                </div>
                {downloadType && (
                  <div style={{ textAlign: 'left' }}>
                    <small>{texts.small4}</small>
                    <select
                      name="excelDownloadType"
                      value={excelDownloadType}
                      className="dropdonw_download_pdf"
                      onChange={(e) => this.handleChangeSelect(e, 'excel')}
                    >
                      <option value="byDocument">{inputs.selectOption3}</option>
                      <option value="bySigners">{inputs.selectOption4}</option>
                    </select>
                  </div>
                )}

                {/* SEE TOTAL CONTRACTS */}
                {fromDate && toDate && attribute && !downloadType ? (
                  <div className="see_total_contracts">
                    <small
                      className="see_total_ask"
                      onClick={() => this.getContractsToDownload()}
                    >
                      {texts.small5}
                    </small>
                    <br></br>
                    {confirming ? (
                      <small className="see_total">
                        {texts.small6(contracts)}
                      </small>
                    ) : null}
                  </div>
                ) : null}

                {/* WARNING DOWNLOAD PDFS */}
                {!downloadType ? (
                  <div className="warning_download_pdfs">
                    <small>{texts.small9}</small>
                    <small>{texts.small10}</small>
                  </div>
                ) : null}
              </div>

              <div className="footer_modal">
                <button
                  className="color-grey"
                  type="button"
                  onClick={handleCancel}
                  disabled={loading}
                >
                  {buttons.button1}
                </button>
                <button
                  className="primary"
                  type="button"
                  disabled={this.disabledButton()}
                  onClick={() =>
                    !downloadType
                      ? downloadMassivePdfByDate(attribute)
                      : downloadExcel(excelDownloadType)
                  }
                >
                  {buttons.button2}
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="background-screen" />
      </div>
    );
  }
}

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  SelectDateModal,
);
