import React from 'react';

export const ListVerificationDoc = ({
  verificationDocs,
  localSigner,
  onClickDownloadIdentityDocument,
  changeDocName,
  texts,
}) => {
  return (
    <div className="verifications_user_docs">
      <div className="title_verification">
        {verificationDocs.length && localSigner.verified ? (
          <span style={{ color: '#7ed321' }}>{texts.spanTitle1}</span>
        ) : !localSigner.verified && localSigner.requestVerification ? (
          <span style={{ color: '#666a75' }}>{texts.spanTitle2}</span>
        ) : null}
      </div>
      <div className="user_documents">
        {verificationDocs.map((doc, i) =>
          localSigner.userId === doc.userId ? (
            <span
              key={i}
              className="doc"
              style={{
                border: '1px solid #7ed321',
                color: '#7ed321',
              }}
              onClick={() =>
                onClickDownloadIdentityDocument(
                  doc,
                  'verificationUser',
                  localSigner.legalRfc ||
                    localSigner.uuid ||
                    localSigner.secondaryEmail,
                )
              }
            >
              {changeDocName(doc.typeId)}{' '}
            </span>
          ) : null,
        )}
      </div>
    </div>
  );
};
