export const AuthorizersTranslations = {
  es: {
    texts: {
      span2: 'Autorizadores',
      tooltip1: 'ID generado por SORA para tu firmante',
      p1: 'No hay autorizadores',
      genericModalTitle1: 'Advertencia',
      genericModalBody1:
        'Existen varios RFC o ID asociados a esta cuenta de correo. Para continuar pregunta a tu firmante a que RFC o ID se debe asociar este contrato.',
      option1: 'Elegir usuario',
    },
    inputs: {
      span1: 'Agregar flujo de autorización',
      inputPlaceholder1: 'Buscar autorizadores',
      inputPlaceholder2: 'Correo electrónico',
      inputPlaceholder3: 'Nombre',
      inputPlaceholder4: 'RFC',
    },
    buttons: {
      button1: 'Agregar autorizador +',
      button2: 'Agregarme +',
      genericModalButton1: 'Cancelar',
    },
    notifications: {
      notif1Title: 'Advertencia',
      notif1Text: 'No puedes tener autorizadores con el mismo RFC',
      notif2Title: 'Advertencia',
      notif2Text: 'No puedes firmar por dos partes',
    },
  },
  en: {
    texts: {
      span2: 'Authorizers',
      tooltip1: "ID generated by Sora's for signer",
      p1: "You don't have any authorizers yet.",
      genericModalTitle1: 'Warning',
      genericModalBody1:
        "There are several RFC's or IDs associated with this email. Please ask your signer which RFC or ID they want to use.",
      option1: 'Select user',
    },
    inputs: {
      span1: 'Add authorization flow',
      inputPlaceholder1: ' Search authorizers',
      inputPlaceholder2: 'Email',
      inputPlaceholder3: 'Name',
      inputPlaceholder4: 'RFC',
    },
    buttons: {
      button1: 'Add authorizer +',
      button2: 'Add me +',
      genericModalButton1: 'Cancel',
    },
    notifications: {
      notif1Title: 'Warning',
      notif1Text: 'You cannot have two authorizers with the same RFC',
      notif2Title: 'Warning',
      notif2Text: 'You cannot sign for two parties',
    },
  },
};
