import React, { Fragment } from 'react';
import { NotificationManager } from 'react-notifications';
import { branch } from 'baobab-react/higher-order';

class ModalToAssignFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  continueProcess = () => {
    const {
      repeteatedUsers,
      skipRepeatedUsers,
      closeModal,
      translations,
      lang,
    } = this.props;
    const { notifications } =
      translations[lang].NewContractMassive.ModalToAssignFiles;
    if (skipRepeatedUsers) {
      return closeModal();
    }

    const unSelectedUsers = repeteatedUsers.filter((user) => !user.userId);
    if (unSelectedUsers.length) {
      return NotificationManager.warning(
        notifications.notifBody1,
        notifications.notifTitle1,
        4000,
      );
    }

    closeModal();
    // showConfirmModal();
    // handleSubmit();
  };

  render() {
    const {
      repeteatedUsers,
      skipRepeatedUsers,
      skipRepeatedUsersFn,
      updateSigner,
      translations,
      lang,
    } = this.props;

    const { texts, buttons } =
      translations[lang].NewContractMassive.ModalToAssignFiles;

    return (
      <div
        className="modal-container"
        // style={{ width: '50%', margin: '0 auto' }}
      >
        <div className="modal">
          <div className="header_modal">
            <h1 style={{ color: '#2851e5', fontSize: 16, textAling: 'center' }}>
              {texts.h1}
            </h1>
          </div>
          <div className="body_modal">
            <div className="select_user_massive">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="checkbox"
                  id="cbox2"
                  checked={skipRepeatedUsers}
                  name="skipRepeatedUsers"
                  onChange={(e) => skipRepeatedUsersFn(e)}
                />
                <small style={{ marginLeft: 10 }}>{texts.small1}</small>
              </div>
              <div className="header_select_user_massive">
                <small className="bold">{texts.small2}</small>
                <small className="bold">{texts.small3}</small>
              </div>
              <div className="body_select_user_massive">
                {repeteatedUsers?.map((repeatedUser, index) => (
                  <Fragment key={index}>
                    <small style={{ fontWeight: 'normal' }}>
                      {repeatedUser.file.path}
                    </small>
                    <div className="select_display">
                      <select
                        name="signer"
                        autoComplete="nope"
                        className="input_nc"
                        style={{
                          // marginRight: 10,
                          marginBottom: 10,
                          ...(!repeatedUser.userId && {
                            border: '1px solid red',
                          }),
                        }}
                        onChange={(event) =>
                          updateSigner(
                            repeatedUser?.sameUsers[
                              event.target.selectedIndex - 1
                            ],
                            index,
                            'signersPdf',
                          )
                        }
                        // disabled={!this.disabledButton(localSigner)}
                      >
                        <option
                          value=""
                          disabled
                          selected={!Boolean(repeatedUser.userId)}
                        >
                          {texts.option1}
                        </option>
                        {repeatedUser?.sameUsers.map((user, i) => (
                          <option
                            key={i}
                            value={JSON.stringify(user)}
                            selected={repeatedUser.userId === user.id}
                          >
                            {user.legalName} - {user.legalRfc}
                          </option>
                        ))}
                      </select>
                      <i
                        className="fas fa-angle-down	"
                        style={{ color: 'black' }}
                      />
                    </div>
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
          <div className="footer_modal">
            <small></small>
            <button
              className="primary"
              type="button"
              onClick={this.continueProcess}
            >
              {buttons.button1}
            </button>
          </div>
        </div>
        <div className="background-screen" />
      </div>
    );
  }
}

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  ModalToAssignFiles,
);
