import { endpointHandler } from '../createEndpoint';

const {
  addSignerToProfileEP,
  signersEP,
  adminsEP,
  getLastSignersEP,
  templatesEP,
  couponsEP,
  signUserEP,
  addEmailEP,
  getUserProfileEP,
  removeSignerEP,
  removeAdminEP,
  forceDeleteEP,
  addTemplateEP,
  deleteTemplateEP,
  addCouponEP,
  updateCouponEP,
  couponContractEP,
  notifyAPIRequestEP,
  userInfoEP,
  updateUserEP,
  addProfileImageEP,
  updateSignerEp,
  updateNotificationsEP,
  downloadContractToViewProfileEP,
  registerUsersToDownloadContractsEP,
  getMyUsersEP,
  createContractToAuthorizeDownloadEP,
  sendResponseToAuthorizeEP,
  searchUserByDataEP,
  updateUserDataEP,
} = endpointHandler();

export const profileServices = {
  // GET
  signersService: (reqConfig, urlVar) => {
    return signersEP.get(reqConfig, urlVar);
  },
  adminsService: (reqConfig, urlVar) => {
    return adminsEP.get(reqConfig, urlVar);
  },
  getLastSignersService: (reqConfig, urlVar) => {
    return getLastSignersEP.get(reqConfig, urlVar);
  },
  templatesService: (reqConfig, urlVar) => {
    return templatesEP.get(reqConfig, urlVar);
  },
  couponsService: (reqConfig, urlVar) => {
    return couponsEP.get(reqConfig, urlVar);
  },
  userInfoService: (reqConfig, urlVar) => {
    return userInfoEP.get(reqConfig, urlVar);
  },
  getUserProfileService: (reqConfig, urlVar) => {
    return getUserProfileEP.get(reqConfig, urlVar);
  },
  downloadContractToViewProfileService: (reqConfig, urlVar) => {
    return downloadContractToViewProfileEP.get(reqConfig, urlVar);
  },

  getMyUsersService: (reqConfig, urlVar) => {
    return getMyUsersEP.get(reqConfig, urlVar);
  },

  // POST
  addSignerToProfileService: (reqConfig, urlVar) => {
    return addSignerToProfileEP.post(reqConfig, urlVar);
  },
  signUserService: (data, reqConfig, urlVar) => {
    return signUserEP.post(data, reqConfig, urlVar);
  },
  addEmailService: (data, reqConfig, urlVar) => {
    return addEmailEP.post(data, reqConfig, urlVar);
  },
  removeSignerService: (data, reqConfig, urlVar) => {
    return removeSignerEP.post(data, reqConfig, urlVar);
  },
  removeAdminService: (data, reqConfig, urlVar) => {
    return removeAdminEP.post(data, reqConfig, urlVar);
  },
  forceDeleteService: (data, reqConfig, urlVar) => {
    return forceDeleteEP.post(data, reqConfig, urlVar);
  },
  addTemplateService: (data, reqConfig, urlVar) => {
    return addTemplateEP.post(data, reqConfig, urlVar);
  },
  deleteTemplateService: (data, reqConfig, urlVar) => {
    return deleteTemplateEP.post(data, reqConfig, urlVar);
  },
  addCouponService: (data, reqConfig, urlVar) => {
    return addCouponEP.post(data, reqConfig, urlVar);
  },
  couponContractService: (data, reqConfig, urlVar) => {
    return couponContractEP.post(data, reqConfig, urlVar);
  },
  notifyAPIRequestService: (data, reqConfig, urlVar) => {
    return notifyAPIRequestEP.post(data, reqConfig, urlVar);
  },
  updateUserService: (data, reqConfig, urlVar) => {
    return updateUserEP.put(data, reqConfig, urlVar);
  },
  updateUserDataService: (data, reqConfig, urlVar) => {
    return updateUserDataEP.put(data, reqConfig, urlVar);
  },

  addProfileImageService: (data, reqConfig, urlVar) => {
    return addProfileImageEP.post(data, reqConfig, urlVar);
  },

  updateSignersService: (data, reqConfig, urlVar) => {
    return updateSignerEp.post(data, reqConfig, urlVar);
  },

  updateNotificationUserService: (data, reqConfig, urlVar) => {
    return updateNotificationsEP.post(data, reqConfig, urlVar);
  },

  registerUsersToDownloadContractsService: (data, reqConfig, urlVar) => {
    return registerUsersToDownloadContractsEP.post(data, reqConfig, urlVar);
  },

  createContractToAuthorizeDownloadService: (data, reqConfig, urlVar) => {
    return createContractToAuthorizeDownloadEP.post(data, reqConfig, urlVar);
  },

  sendResponseToAuthorizeService: (data, reqConfig, urlVar) => {
    return sendResponseToAuthorizeEP.post(data, reqConfig, urlVar);
  },

  searchUserByDataService: (data, reqConfig, urlVar) => {
    return searchUserByDataEP.post(data, reqConfig, urlVar);
  },

  // PUT
  updateCouponService: (data, reqConfig, urlVar) => {
    return updateCouponEP.put(data, reqConfig, urlVar);
  },
};
