import Baobab from 'baobab';
import { translationsState } from './translations/translations';

const { NODE_ENV } = process.env;

const options = {
  autocommit: false,
  immutable: NODE_ENV === 'development',
};

const initialState = {
  session: null,
  contract: null,
  secondaryEmail: null,
  contracts: [],
  labels: [],
  myLabels: [],
  mySigners: [],
  myAdmins: [],
  myCards: [],
  myTemplates: [],
  myCoupons: [],
  myApiUsers: [],
  totalLoading: 0,
  contractUrl: null,
  width: { width: window.innerWidth },
  backdrop: { show: false, message: '' },
  translations: { ...translationsState },
  lang: 'es',
};

const state = new Baobab(initialState, options);
if (NODE_ENV === 'development') window.$state = state;

export default state;
