import React from 'react';
import { NotificationManager } from 'react-notifications';
import { branch } from 'baobab-react/higher-order';
// Actions
import Signature from '../../utils/signature';
import ProfileActions from '../../actions/Profile';
// Components
import GenericContractPreview from '../../components/GenericContractPreview';
import GenericConfirmModal from '../../components/Modals/GenericConfirmModal';
import Loader from '../../components/Loader';
import SignerModal from '../Signer/SignerModal';
import { emailRegex } from '../../utils/constants';
import LabelActions from '../../actions/LabelActions';
import SignersOrAdminsDisplay from './SignersOrAdminsDisplay';
import { profileServices } from '../../API/services/profileServices';
import { getLocationUtils } from '../../utils/locationUtils';
import IframeToSign from '../ContractDetails/IframeToSign';

class MySigners extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userToAdd: {
        legalRfc: '',
        legalName: '',
        email: '',
        canCreateContract: false,
        canSee: false,
        canCreateSigner: false,
        canCreateAdmin: false,
        canSign: true,
        protectedUser: false,
        protectedDocs: null,
        userId: null,
        disclaimerMultipleUsers: false,
        sameUsers: [],
      },
      loading: false,
      showModal: false,
      checkCount: 0,
      showRemoveModal: false,
      showCancelModal: false,
      signerOrAdminToDelete: '',
      userWhenDelete: '',
      text: '',
      signerOrAdminModal: false,
      editSignerOrAdminButton: false,
      showErrors: {
        email: false,
        legalName: false,
        legalRfc: false,
      },
      originalLabels: [],
      myLabels: [],
      filteredLabels: [],
      protectedDocs: new Set(),
      totalLoading: 0,
      contractUrl: null,
      cannotSign: false,
      locationUser: null,
      showAlertLocation: false,
      newMessageAlert: false,
      showIframe: false,
      multipleUsers: false,
      sameUsers: [],
    };
  }

  async componentDidMount() {
    const { signer, type, session } = this.props;
    const { userToAdd } = this.state;
    userToAdd.canSign = true;
    if (type === 'signer') {
      this.setState({ userToAdd });
    }
    // getLocationUtils(
    //   this.onSuccessLocation,
    //   this.onErrorLocation,
    //   this.verifyIfUserHasLocation,
    // );
    if (signer) {
      const myLabels = await LabelActions.rootLabels(signer.rootUser);
      this.setState({ myLabels, originalLabels: myLabels });
      return;
    }
    const myLabels = await LabelActions.myLabels(session.user.id);
    this.setState({ myLabels, originalLabels: myLabels });
  }

  onSuccessLocation = (position) => {
    const {
      coords: { latitude, longitude },
    } = position;
    this.setState({
      locationUser: {
        latitude,
        longitude,
      },
    });
    this.setState({ showAlertLocation: false, cannotSign: false });
  };

  onErrorLocation = (error) => {
    this.setState({
      cannotSign: true,
      showAlertLocation: false,
      newMessageAlert: true,
    });
  };

  verifyIfUserHasLocation = (result) => {
    if (result.state === 'prompt') {
      this.setState({ showAlertLocation: true, cannotSign: true });
    }
  };

  getSignersOrAdmins = async () => {
    const {
      session: { user },
      signer,
      type,
    } = this.props;
    const id = signer ? signer.rootUser : user.id;
    if (type === 'signer') {
      return await ProfileActions.signers(id);
    }

    await ProfileActions.admins(id);
  };

  showSignerOrAdminModal = (typeModal) => {
    const { type } = this.props;
    if (typeModal === 'new') {
      this.setState({
        userToAdd: {
          legalRfc: '',
          legalName: '',
          email: '',
          canCreateContract: false,
          canSee: false,
          canCreateSigner: false,
          canCreateAdmin: false,
          canSign: type === 'signer' ? true : false,
          protectedUser: false,
          userId: null,
        },
        editSignerOrAdminButton: false,
      });
    }
    const { signerOrAdminModal } = this.state;
    this.setState({ signerOrAdminModal: !signerOrAdminModal });
  };

  downloadFileToView = async (doc, docType, keyState) => {
    const { translations, lang } = this.props;
    const { notifications } = translations[lang].Profile.MySignerOrMyAdmins;
    const response = await profileServices.downloadContractToViewProfileService(
      {
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          const percentage =
            Math.max((progressEvent.loaded * 100) / progressEvent.total) || 0;
          this.setState({ totalLoading: percentage.toFixed(0), loading: true });
          if (percentage === 100) {
            this.setState({ loading: false, totalLoading: 0 });
          }
        },
      },
      { id: `${doc.id};${docType}` },
    );

    if (response.status >= 500 || response.status === 404) {
      NotificationManager.error(
        notifications.notifBody1,
        notifications.notifTitle1,
        10000,
      );
      return this.setState({ [keyState]: false, loading: false });
    }
    const objUrl = URL.createObjectURL(response.data);
    this.setState({ contractUrl: objUrl, [keyState]: true, loading: false });
  };

  forcePDF = async (signedUser, table) => {
    const user = { ...signedUser };
    this.setState({
      signedUser: user,
      loading: true,
      contract: { fileUrl: signedUser.signedCopyUrl || signedUser.fileUrl },
    });
    await this.downloadFileToView(user, table, 'showModal');
  };

  signerOrAdminInputChange = (event, permission) => {
    const { name, value } = event.target;

    const { userToAdd, filteredLabels, myLabels, protectedDocs } = this.state;

    if (name === 'protectedDocs') {
      protectedDocs.add(value);
      const filterMyLabels = myLabels.filter(
        (label) => label.id.toString() !== value,
      );
      const labelToAdd = myLabels.filter(
        (label) => label.id.toString() === value,
      );

      this.setState({
        myLabels: filterMyLabels,
        filteredLabels: [...filteredLabels, ...labelToAdd],
      });
    }

    let signers = {};

    if (name === 'legalRfc' || name === 'email') {
      const newValue = value.replace(/ /g, '');
      signers = {
        ...userToAdd,
        [name]: permission ? permission.active : newValue,
      };
    } else {
      signers = {
        ...userToAdd,
        [name]: permission ? permission.active : value,
      };
    }

    this.setState({ userToAdd: signers });
  };

  handleAddSignersOrAdmins = () => {
    const { userToAdd } = this.state;
    const { lang, translations } = this.props;
    const { notifications } = translations[lang].Profile.MySignerOrMyAdmins;
    const {
      mySigners,
      session: { user },
    } = this.props;

    const filterUsersWithId = mySigners.filter((signer) => signer.userId);
    const filterUsersWithOutId = mySigners.filter((signer) => !signer.userId);

    if (userToAdd.userId) {
      const userExist = filterUsersWithId.some(
        (signer) => signer.userId === userToAdd.userId,
      );
      if (userExist) {
        return NotificationManager.warning(
          notifications.notifBody2,
          notifications.notifTitle2,
          5000,
        );
      }
    }

    if (!userToAdd.userId) {
      const userExist = filterUsersWithOutId.some(
        (signer) => signer.secondaryEmail === userToAdd.email,
      );

      if (userExist) {
        return NotificationManager.warning(
          notifications.notifBody2,
          notifications.notifTitle2,
          5000,
        );
      }
    }

    if (userToAdd.userId === user.id) {
      return NotificationManager.warning(
        notifications.notifBody3,
        notifications.notifTitle3,
        5000,
      );
    }

    this.setState({ loading: true, signerOrAdminModal: false });
    this.createAndUploadSignersOrAdmins();
  };

  createAndUploadSignersOrAdmins = async () => {
    const { userToAdd, protectedDocs } = this.state;
    const { signer, type, session, lang, translations } = this.props;
    const { notifications } = translations[lang].Profile.MySignerOrMyAdmins;
    try {
      const addProtectedDocs = protectedDocs.size
        ? JSON.stringify(Array.from(protectedDocs))
        : null;
      userToAdd.legalRfc = userToAdd.legalRfc
        ? userToAdd.legalRfc.toUpperCase()
        : '';
      userToAdd.rootUser = signer ? signer.rootUser : null;
      userToAdd.protectedDocs = addProtectedDocs;
      userToAdd.canSign = type === 'signer' ? true : false;
      const registeredUser = await ProfileActions.addSigner({
        ...userToAdd,
        createdBy: session?.user?.id,
        type,
      });

      return this.forcePDF(registeredUser, registeredUser.table);
    } catch (error) {
      console.log('hubo un error', error);
      return NotificationManager.error(
        notifications.notifBody5,
        notifications.notifTitle5,
        15000,
      );
    }
  };

  onKeySearchByUserByData = async (e) => {
    const { value } = e.target;
    const { userToAdd } = this.state;

    if (value.match(emailRegex)) {
      const response = await ProfileActions.searchUserByData({
        value,
      });

      const data = response?.data;
      if (data?.type) {
        const { type, users } = data;

        if (type === 'USER_NOT_FOUND') {
          userToAdd.legalName = '';
          userToAdd.legalRfc = '';
          userToAdd.userId = null;
          return this.setState({
            userToAdd: userToAdd,
            multipleUsers: false,
            sameUsers: [],
          });
        }

        if (type === 'SINGLE_USER') {
          userToAdd.legalName = users.legalName;
          userToAdd.legalRfc = users.legalRfc;
          userToAdd.userId = users.id;
          return this.setState({
            userToAdd: userToAdd,
            multipleUsers: false,
            sameUsers: [],
          });
        }
        if (type === 'MULTIPLE_USERS') {
          return this.setState({ multipleUsers: true, sameUsers: users });
        }
      }
    }
  };

  onBlurCheckValidity = (e) => {
    const { name } = e.target;
    const { userToAdd, showErrors } = this.state;

    if (userToAdd.legalRfc?.length < 12 && name === 'legalRfc') {
      return this.setState({ showErrors: { ...showErrors, [name]: true } });
    }

    if (userToAdd.legalName?.length < 10 && name === 'legalName') {
      return this.setState({ showErrors: { ...showErrors, [name]: true } });
    }

    if (!userToAdd.email?.match(emailRegex) && name === 'email') {
      return this.setState({ showErrors: { ...showErrors, [name]: true } });
    }

    this.setState({ showErrors: { ...showErrors, [name]: false } });
  };

  canAddSignerOrAdmin = () => {
    const { userToAdd } = this.state;
    const signersOrAdmins = [userToAdd];
    return signersOrAdmins.every(
      (signerOrAdmin) =>
        // signerOrAdmin.legalRfc &&
        signerOrAdmin.legalName && signerOrAdmin.email,
    );
  };

  addChip = (chip) => {
    const { filteredLabels } = this.state;
    const newChip = {
      content: chip.content,
      color: chip.color,
      id: chip.id,
    };
    this.setState({ filteredLabels: [...filteredLabels, newChip] });
  };

  removeChip = (e) => {
    const { filteredLabels, myLabels, protectedDocs } = this.state;
    const array = [...filteredLabels];
    const filteredArray = array.filter((chip) => chip !== e);
    protectedDocs.delete(e.id.toString());
    this.setState({ filteredLabels: filteredArray });
    this.setState({ myLabels: [...myLabels, e] });
  };

  handleChipsKeyDown = async (e) => {
    const { myLabels, filteredLabels, protectedDocs } = this.state;
    const { signer, lang, translations } = this.props;
    const { notifications } = translations[lang].Profile.MySignerOrMyAdmins;

    const newLabelsSet = protectedDocs;
    if (e.keyCode === 13) {
      let { value } = e.target;
      const chip = {};
      if (myLabels.find((element) => element.content === value)) {
        return NotificationManager.warning(
          notifications.notifBody4,
          notifications.notifTitle4,
          3000,
        );
      }
      chip.content = value;
      // eslint-disable-next-line no-bitwise
      chip.color = `#${(((1 << 24) * Math.random()) | 0).toString(16)}`;
      chip.rootUser = signer ? signer.rootUser : null;
      const label = await LabelActions.create(chip);
      newLabelsSet.add(label.id.toString());
      this.setState({
        protectedDocs: newLabelsSet,
        filteredLabels: [label, ...filteredLabels],
      });
    }
  };

  updateSignerOrAdmin = async () => {
    const { userToAdd, protectedDocs } = this.state;
    const signer = { ...userToAdd };
    delete signer.secondaryEmail;
    signer.protectedDocs = protectedDocs.size
      ? JSON.stringify(Array.from(protectedDocs))
      : null;
    const {
      data: { status, message },
    } = await ProfileActions.updateSigners(signer);

    if (status) {
      // TODO: translate response
      return NotificationManager.error(message, 'Atención', 3000);
    }
    this.getSignersOrAdmins();
    this.setState({ signerOrAdminModal: false });
    // TODO: translate response
    return NotificationManager.success(message, 'Atención', 3000);
  };

  onSign = async (modalState) => {
    const credentials = modalState.state;
    credentials.type = 'login_signers';
    const { signedUser, locationUser } = this.state;
    const {
      session: { user },
      lang,
      translations,
    } = this.props;
    const { notifications } = translations[lang].Profile.MySignerOrMyAdmins;
    this.setState({
      showModal: false,
      loading: true,
    });
    NotificationManager.info(
      notifications.notifBody6,
      notifications.notifTitle6,
      10000,
    );

    try {
      const { cuteSign, legalRfc } = await Signature(
        credentials,
        signedUser.hash,
      );

      if (!cuteSign) {
        this.setState({ loading: false });
        return NotificationManager.error(
          notifications.notifBody7,
          notifications.notifTitle7,
          10000,
        );
      }

      if (user.legalRfc && user.legalRfc !== legalRfc) {
        this.setState({ loading: false });
        return NotificationManager.error(
          notifications.notifBody8,
          notifications.notifTitle8,
          10000,
        );
      }

      const signResponse = await ProfileActions.signUser(
        signedUser,
        credentials.cer,
        cuteSign,
        locationUser,
      );
      if (signResponse.error) {
        this.setState({ loading: false });
        return NotificationManager.error(
          signResponse.error || notifications.notifBody7,
          notifications.notifTitle7,
          10000,
        );
      }
      signResponse.signed = true;
      this.forcePDF(signResponse, signedUser.table);
      this.getSignersOrAdmins();
      NotificationManager.success(
        notifications.notifBody9(signedUser.legalName),
        notifications.notifTitle9,
        5000,
      );
    } catch (error) {
      console.log('Hubo un error', error);
      return NotificationManager.error(
        notifications.notifBody7,
        notifications.notifTitle7,
        10000,
      );
    }
  };

  showCancelModal = (e) => {
    const { translations, lang } = this.props;
    const { texts } = translations[lang].Profile.MySignerOrMyAdmins;
    if (e.signed) {
      return this.setState({ showModal: false });
    }
    this.setState({
      showCancelModal: true,
      text: texts.genericModalBody2,
      // FIXME: Esto debe de ir en el componente como prop, no en el estado porque nunca cambia
    });
    this.setState({ userWhenDelete: e });
  };

  hideCancelModal = () => {
    this.setState({ showCancelModal: false });
  };

  hideModal = () => {
    const { userWhenDelete } = this.state;
    const { lang, translations } = this.props;
    const { notifications } = translations[lang].Profile.MySignerOrMyAdmins;
    if (!userWhenDelete.signed) {
      ProfileActions.forceDelete(userWhenDelete);
      this.setState({ showModal: false });
      NotificationManager.warning(
        notifications.notifBody10(userWhenDelete.legalName),
        notifications.notifTitle10,
        3000,
      );
      this.getSignersOrAdmins();
    } else {
      this.setState({ showModal: false });
    }
    this.setState({ showCancelModal: false });
  };

  editSignerOrAdmin = (signer) => {
    const { originalLabels } = this.state;
    if (!signer.protectedDocs) {
      return this.setState({
        userToAdd: signer,
        signerOrAdminModal: true,
        editSignerOrAdminButton: true,
        protectedDocs: new Set(),
        filteredLabels: [],
        myLabels: originalLabels,
      });
    }
    const labelsAssigned = JSON.parse(signer.protectedDocs);
    let setProtectedDocs = new Set(labelsAssigned);
    const labelsList = originalLabels.filter((label) => {
      return labelsAssigned.indexOf(label.id.toString()) > -1;
    });

    if (labelsAssigned.length !== labelsList.length) {
      const ids = labelsList.map((label) => label.id.toString());
      setProtectedDocs = new Set(ids);
    }

    const labelsNotSelected = originalLabels.filter((label) => {
      return labelsAssigned.indexOf(label.id.toString()) === -1;
    });

    this.setState({
      userToAdd: signer,
      signerOrAdminModal: true,
      editSignerOrAdminButton: true,
      protectedDocs: setProtectedDocs,
      filteredLabels: labelsList,
      myLabels: labelsNotSelected,
    });
  };

  removeSigner = async (e) => {
    const { signerOrAdminToDelete } = this.state;
    const { type, lang, translations } = this.props;
    const { notifications } = translations[lang].Profile.MySignerOrMyAdmins;

    if (signerOrAdminToDelete.id) {
      const executeQuery =
        type === 'signer'
          ? await ProfileActions.removeSigner(signerOrAdminToDelete)
          : await ProfileActions.removeAdmin(signerOrAdminToDelete);

      const response = executeQuery;
      if (response) {
        NotificationManager.warning(
          notifications.notifBody10(signerOrAdminToDelete.legalName),
          notifications.notifTitle10,
          3000,
        );
        await this.getSignersOrAdmins();
      }
    }
    this.setState({ showRemoveModal: false });
  };

  showRemoveModal = (signer) => {
    this.setState({ showRemoveModal: true });
    this.setState({ signerOrAdminToDelete: signer });
  };

  hideRemoveModal = () => {
    this.setState({ showRemoveModal: false });
  };

  replaceTextSignerOrAdmin = () => {
    const { type, signer, lang, translations } = this.props;
    const { texts } = translations[lang].Profile.MySignerOrMyAdmins;

    if (type === 'signer' && signer) {
      return texts.label1(signer.legalName);
    }

    if (type === 'admin' && signer) {
      return texts.label2(signer.legalName);
    }

    if (type === 'signer') {
      return texts.label3;
    }

    return texts.label4;
  };

  showIframeToSignContract = () => {
    this.setState({ showIframe: true });
  };

  onKeyUpGenerateSoraId = (e) => {
    /* const { value } = e.target;
    const { userToAdd } = this.state;
    const { type } = this.props;
    if (userToAdd.signerType === 'rfc') return;
    if (value.match(emailRegex)) {
      setTimeout(async () => {
        const response = await ContractActions.generateSoraId({
          secondaryEmail: userToAdd.email,
          participant: type,
        });
        const data = response?.data;
        if (!data?.success && data?.type === 'MULTIPLE_USERS') {
          return this.setState({ multipleUsers: true });
        }
        this.setState({ userToAdd: { ...userToAdd, legalRfc: data?.rfc } });
      }, 500);
    } */
  };

  handleUpdateAdminOrSigner = (signer) => {
    const { userToAdd } = this.state;
    userToAdd.legalName = signer.legalName;
    userToAdd.legalRfc = signer.legalRfc;
    userToAdd.userId = signer.id;
    this.setState({ userToAdd });
  };

  render() {
    const {
      userToAdd,
      loading,
      showModal,
      signedUser,
      contract,
      showRemoveModal,
      showCancelModal,
      text,
      signerOrAdminModal,
      editSignerOrAdminButton,
      showErrors,
      myLabels,
      filteredLabels,
      contractUrl,
      totalLoading,
      showAlertLocation,
      newMessageAlert,
      cannotSign,
      showIframe,
      multipleUsers,
      sameUsers,
    } = this.state;
    const { mySigners, session, type, lang, translations } = this.props;
    const translation = translations[lang].Profile.MySignerOrMyAdmins;
    const { texts, buttons } = translation;

    return (
      <>
        {/* MY SIGNERS DATA */}
        <div
          className={`my_signers_admins ${
            this.props.signer ? 'admin_view' : ''
          }`}
        >
          <div className="title_add_signer_admin">
            <label>{this.replaceTextSignerOrAdmin()}</label>
            <span
              className="add_signer_admin"
              hidden={userToAdd.length > 1}
              onClick={() => this.showSignerOrAdminModal('new')}
            >
              {buttons.button1}
            </span>
          </div>

          <div className="list_my_signers_admins">
            {loading ? (
              <div className="center_loading">
                <Loader totalLoading={totalLoading} />
              </div>
            ) : (
              <SignersOrAdminsDisplay
                mySigners={mySigners.filter(
                  (signer) => signer.userId !== session.user.id,
                )}
                forcePDF={this.forcePDF}
                editSignerOrAdmin={this.editSignerOrAdmin}
                showRemoveModal={this.showRemoveModal}
                translation={translation}
              />
            )}
          </div>
        </div>
        {showModal ? (
          <GenericContractPreview
            handleConfirm={this.onSign}
            handleCancel={this.showCancelModal}
            signedUser={signedUser}
            type="sign"
            contract={contract}
            user={session.user}
            userType="signer"
            contractUrl={contractUrl}
            cannotSign={cannotSign}
            showIframeToSignContract={this.showIframeToSignContract}
          />
        ) : null}

        {showIframe ? (
          <IframeToSign
            contract={signedUser}
            type="signer"
            closeIframe={() => this.setState({ showIframe: false })}
          />
        ) : null}

        {signerOrAdminModal ? (
          <SignerModal
            signerOrAdminInputChange={this.signerOrAdminInputChange}
            signer={userToAdd}
            handleAddSignersOrAdmins={this.handleAddSignersOrAdmins}
            editSignerOrAdminButton={editSignerOrAdminButton}
            editSignerOrAdmin={this.updateSignerOrAdmin}
            showSignerOrAdminModal={this.showSignerOrAdminModal}
            onBlurCheckValidity={this.onBlurCheckValidity}
            showErrors={showErrors}
            canAddSignerOrAdmin={this.canAddSignerOrAdmin}
            myLabels={myLabels}
            filteredLabels={filteredLabels}
            addChip={this.addChip}
            removeChip={this.removeChip}
            handleChipsKeyDown={this.handleChipsKeyDown}
            type={type}
            onKeyUpGenerateSoraId={this.onKeyUpGenerateSoraId}
            onKeySearchByUserByData={this.onKeySearchByUserByData}
            updateSigner={this.handleUpdateAdminOrSigner}
            multipleUsers={multipleUsers}
            sameUsers={sameUsers}
          />
        ) : null}
        {showRemoveModal ? (
          <GenericConfirmModal
            title={texts.genericModalTitle1}
            message={texts.genericModalBody1}
            okButton={buttons.genericModalConfirmButton1}
            cancelButton={buttons.genericModalCancelButton1}
            cancelAction={() => this.hideRemoveModal()}
            acceptAction={() => this.removeSigner()}
          />
        ) : null}
        {showCancelModal ? (
          <GenericConfirmModal
            title={texts.genericModalTitle2}
            message={text}
            okButton={buttons.genericModalConfirmButton2}
            cancelButton={buttons.genericModalCancelButton2}
            cancelAction={() => this.hideCancelModal()}
            acceptAction={() => this.hideModal()}
          />
        ) : null}

        {showAlertLocation ? (
          <GenericConfirmModal
            title={
              newMessageAlert
                ? texts.genericModalTitle3
                : texts.genericModalTitle4
            }
            message={
              newMessageAlert
                ? texts.genericModalBody3
                : texts.genericModalBody4
            }
            okButton={buttons.genericModalConfirmButton3}
            cancelButton={buttons.genericModalCancelButton3}
            cancelAction={() => this.setState({ showAlertLocation: false })}
            acceptAction={() => this.setState({ showAlertLocation: false })}
          />
        ) : null}
      </>
    );
  }
}

export default branch(
  {
    session: ['session'],
    translations: ['translations'],
    lang: ['lang'],
  },
  MySigners,
);
