import { branch } from 'baobab-react/higher-order';
import React from 'react';

const SignersListNewContract = ({
  dropdownSigners,
  index,
  signerInputChange,
  signer,
  dropdown,
  onKeyUpSearchByEmail,
  removeSigner,
  orderedSigners,
  updateSigner,
  translations,
  lang,
}) => {
  const { texts, inputs } =
    translations[lang].NewContract.Signers.SignersListNewContract;
  return (
    <>
      <div className="container_inputs_signers">
        <div
          className={`inputs_signers ${
            dropdownSigners ? 'dropdown_active' : ''
          }`}
        >
          {!dropdownSigners ? (
            <>
              <div className="email_signer_nc">
                {orderedSigners ? <small>{index + 1}.</small> : null}
                <input
                  type="email"
                  name="email"
                  autoComplete=""
                  value={signer.secondaryEmail || signer.email}
                  className="input_nc"
                  onChange={(e) => signerInputChange(index, e)}
                  required
                  placeholder="email"
                  onKeyUp={(e) =>
                    onKeyUpSearchByEmail(e, index, signer.signType)
                  }
                />
              </div>
              {signer?.disclaimerMultipleUsers ? (
                <div className="select_display legalName_signer_nc">
                  <select
                    name="signer"
                    onChange={(event) =>
                      updateSigner(
                        signer?.sameUsers[event.target.selectedIndex - 1],
                        index,
                      )
                    }
                    autoComplete="nope"
                    className="input_nc legalName_signer_nc"
                    // disabled={!this.disabledButton(localSigner)}
                  >
                    <option
                      value=""
                      disabled
                      selected={!Boolean(signer.userId)}
                    >
                      {texts.option1}
                    </option>
                    {signer?.sameUsers.map((user, i) => (
                      <option
                        key={i}
                        value={JSON.stringify(user)}
                        selected={signer.userId === user.id}
                      >
                        {user.legalName} - {user.legalRfc}
                      </option>
                    ))}
                  </select>
                  <i className="fas fa-angle-down	" style={{ color: 'black' }} />
                </div>
              ) : (
                <input
                  type="text"
                  name="legalName"
                  value={signer.legalName}
                  className="input_nc legalName_signer_nc"
                  style={{
                    textTransform: 'capitalize',
                  }}
                  required
                  maxLength="100"
                  minLength="2"
                  onChange={(e) => signerInputChange(index, e)}
                  placeholder="Nombre"
                />
              )}
            </>
          ) : (
            <>
              <div className="select_signature_active">
                {orderedSigners ? <small>{index + 1}.</small> : null}
                <select
                  name="signType"
                  onChange={(e) => signerInputChange(index, e)}
                  autoComplete="nope"
                  value={signer.signType || ''}
                  className="input_nc"
                >
                  <option value="" disabled>
                    {inputs.selectOption1}
                  </option>
                  <option value={1}>{inputs.selectOption2}</option>
                  <option value={2}>{inputs.selectOption3}</option>
                  <option value={3}>{inputs.selectOption4}</option>
                  <option value={4}>{inputs.selectOption5}</option>
                </select>
                <i className="fas fa-angle-down	" style={{ color: 'black' }} />
              </div>
              <input
                type="email"
                name="email"
                autoComplete=""
                value={signer.secondaryEmail || signer.email}
                className="input_nc email_signer_nc"
                onChange={(e) => signerInputChange(index, e)}
                required
                placeholder="email"
                // onKeyUp={(e) => onKeyUpSearchByEmail(e, index, signer.signType)}
              />
              {signer.disclaimerMultipleUsers ? (
                <div className="select_display legalName_signer_nc">
                  <select
                    name="signer"
                    onChange={(event) =>
                      updateSigner(
                        signer?.sameUsers[event.target.selectedIndex - 1],
                        index,
                      )
                    }
                    autoComplete="nope"
                    className="input_nc legalName_signer_nc"
                    // disabled={!this.disabledButton(localSigner)}
                  >
                    <option value="" disabled>
                      Elegir usuario
                    </option>
                    {signer?.sameUsers.map((user, i) => (
                      <option
                        key={i}
                        value={JSON.stringify(user)}
                        selected={signer.userId === user.id}
                      >
                        {user.legalName} - {user.legalRfc}
                      </option>
                    ))}
                  </select>
                  <i className="fas fa-angle-down	" style={{ color: 'black' }} />
                </div>
              ) : (
                <input
                  type="text"
                  name="legalName"
                  value={signer.legalName}
                  className="input_nc legalName_signer_nc"
                  style={{
                    textTransform: 'capitalize',
                  }}
                  required
                  maxLength="100"
                  minLength="2"
                  onChange={(e) => signerInputChange(index, e)}
                  placeholder="Nombre"
                />
              )}
            </>
          )}
        </div>
        <div className="action_delete_signer_nc">
          {orderedSigners ? <small>{index + 1}.</small> : <small></small>}
          <i
            className={`fas fa-minus-circle `}
            style={{
              color: '#f95c5e',
              cursor: 'pointer',
            }}
            onClick={() => removeSigner(signer)}
          />
        </div>
      </div>
    </>
  );
};

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  SignersListNewContract,
);
