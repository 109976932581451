import React, { Fragment } from 'react';
import Loader from '../../components/Loader';
import { NotificationManager } from 'react-notifications';
// Actions
import Signature from '../../utils/signature';
import SessionActions from '../../actions/Session';
import { branch } from 'baobab-react/higher-order';
import { emailRegex } from '../../utils/constants';
import Checkbox from 'react-simple-checkbox';

class EfirmaRegisterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cer: null,
      message: null,
      key: null,
      passphrase: '',
      email: '',
      demo: false,
      loading: false,
      register: false,
      successMessage: false,
      privacyPolicyAccepted: false,
    };
  }

  /**
   * Handle input changes
   * @param  {Object} event Input change event
   */
  onInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  privacyPolicyChange = (value) => {
    this.setState({ privacyPolicyAccepted: value });
  };

  /**
   * Create a fake file input to allow the user to input a file
   * @param  {Object} event Input click event
   */
  promptFile = (event) => {
    event.target.blur();
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    const { name } = event.target;
    if (name === 'cer') {
      input.setAttribute('accept', '.cer');
    } else {
      input.setAttribute('accept', '.key');
    }
    input.onchange = (e) => {
      const { files } = e.target;
      const [file] = files;
      this.setState({ [name]: file });
    };
    input.click();
  };

  /**
   * Adds user to DB
   * @param event
   */
  handleSubmit = (event) => {
    event.preventDefault();
    const { cer, email, key, passphrase, privacyPolicyAccepted } = this.state;
    const { lang } = this.props;
    this.setState({ loading: true });
    Signature({
      cer,
      key,
      passphrase,
    })
      .then(async () => {
        await SessionActions.register(cer, email, lang, privacyPolicyAccepted)
          .then((response) => {
            this.setState({ loading: false });
            if (response.error) {
              NotificationManager.warning(response.error, 'Advertencia', 30000);
            } else {
              this.setState({ successMessage: true });
              NotificationManager.success(response.message, 'Éxito', 30000);
            }
          })
          .catch(() => {
            this.setState({ loading: false });
            NotificationManager.warning(
              'Credenciales inválidas',
              'Advertencia',
              30000,
            );
          });
      })
      .catch((error) => {
        this.setState({ loading: false });
        NotificationManager.warning(error.message, 'Advertencia', 30000);
      });
  };

  /**
   * Validates registration
   * @returns {*}
   */
  canContinue = () => {
    const { cer, email, key, passphrase, privacyPolicyAccepted } = this.state;
    // eslint-disable-next-line no-useless-escape
    return (
      cer &&
      key &&
      email.length &&
      passphrase.length &&
      email.match(emailRegex) &&
      privacyPolicyAccepted
    );
  };

  /**
   * Redirects to Demo
   */
  handleRegister = () => {
    this.setState({ register: true });
  };

  render() {
    const { translations, lang, setForm } = this.props;
    const {
      cer,
      key,
      passphrase,
      email,
      loading,
      message,
      successMessage,
      privacyPolicyAccepted,
    } = this.state;

    const translation = translations[lang].Registration;
    const { texts, buttons, inputs } = translation;

    return (
      <Fragment>
        <div style={{ margin: '0 auto', marginBottom: 20 }}>
          <img
            src="https://sora-contratos.s3.us-east-2.amazonaws.com/images/soracontratos+nuevo.png"
            alt="logo"
            style={{ width: 250 }}
          />
        </div>
        <div className="sife_info">
          <h4>{texts.h4}</h4>
        </div>
        <div className="no_account_sife">
          <small>{texts.small1}</small>{' '}
          <a href="/login" className="register_link">
            &nbsp;{texts.a}
          </a>
        </div>
        <div className="inputs_login_form">
          {message ? <p className="error_message"> {message}</p> : null}
          {successMessage ? (
            <p className="success_message">{texts.p5}</p>
          ) : null}

          <input
            className="input_nc"
            onChange={this.onInputChange}
            name="email"
            type="email"
            value={email}
            placeholder={inputs.email}
          />
          <input
            className="input_nc"
            onClick={this.promptFile}
            onChange={() => {}}
            name="cer"
            type="text"
            value={cer ? cer.name : ''}
            placeholder={inputs.cer}
          />
          <input
            className="input_nc"
            onClick={this.promptFile}
            onChange={() => {}}
            name="key"
            type="text"
            value={key ? key.name : ''}
            placeholder={inputs.key}
          />
          <input
            className="input_nc"
            onChange={this.onInputChange}
            name="passphrase"
            type="password"
            value={passphrase}
            placeholder={inputs.passphrase}
          />
          <div
            style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}
          >
            <Checkbox
              color={{
                backgroundColor: '#2648E8',
                borderColor: '#2648E8',
                uncheckedBorderColor: '#637381',
                tickColor: '#fff',
              }}
              size="2.2"
              borderThickness="1.2"
              tickSize="2"
              backAnimationDuration={100}
              tickAnimationDuration={200}
              checked={privacyPolicyAccepted}
              onChange={this.privacyPolicyChange}
            />
            <div style={{ marginTop: 10, marginLeft: 4 }}>
              <small style={{ fontSize: 13, lineHeight: 2 }}>
                {texts.small2}
              </small>{' '}
              <a
                href="https://sora.mx/politica-de-privacidad/"
                target="_blank"
                rel="noreferrer"
                style={{ fontSize: 13, lineHeight: 2 }}
              >
                {texts.small3}
              </a>
              <small style={{ fontSize: 13, lineHeight: 2 }}>
                {texts.small4}
              </small>
            </div>
          </div>
        </div>
        <div className="start_session">
          {loading ? (
            <div className="center_loading">
              <Loader />
            </div>
          ) : (
            <button
              className="primary_button_landing session"
              hidden={loading}
              disabled={!this.canContinue()}
              type="button"
              onClick={this.handleSubmit}
            >
              {buttons.send}
            </button>
          )}
        </div>
        <div className="divider">
          <span className="divider-text">o</span>
        </div>
        <div className="footer_login">
          <button
            className="secondary_button_landing"
            type="button"
            onClick={() => setForm('sife')}
          >
            {buttons.registerForm} SIFE
          </button>
        </div>
      </Fragment>
    );
  }
}

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  EfirmaRegisterForm,
);
