import { AddSignersDisplayTranslations } from './AddSignersDisplay.translations';
import { ChipsDisplayMassiveTranslations } from './ChipsDisplayMassive.translations';
import { ErrorsUploadFileTranslations } from './ErrorsUploadFile.translations';
import { SelectContractsToSignersTranslations } from './SelectContractsToSigners.translation';
import { SelectDateDisplayTranslations } from './SelectDateDisplay.translations';
import { UploadFilesDisplayTranslations } from './UploadFilesDisplay.translations';

export const NewContractMassiveTranslations = {
  es: {
    texts: {
      h1: 'Múltiples documentos',
      p1: (aproximateTime) => `Tiempo aproximado ${aproximateTime}`,
      p2: 'Subiendo PDFS... Esto puede tardar unos minutos, no salgas de la pagina.',
      genericModalTitle1: 'Cancelar',
      genericModalBody1: (filesLength) =>
        `Estás a punto de subir ${filesLength} documentos. ¿Desea continuar?`,
      genericModalTitle2: 'Pendientes por subir',
      genericModalBody2: (filesLength) =>
        `Quedaban ${filesLength} documentos pendientes por subir. ¿Desea subirlos ahora?`,
      p3: 'Documentos completados:',
      validateFile1:
        'Verifica la estructura del nombre del documento. Corrige y vuelve a cargar',
      validateFile2: 'El correo del nombre del documento no es valido',
      message1: 'Ocurrió un error al subir el documento',
      message2: 'Subiendo documentos...',
      message3: 'Verificando documentos...',
    },
    inputs: {
      inputLabel1: 'Nombre:',
      inputLabelSpan1: '(Se agrega el RFC del pdf al titulo)',
    },
    buttons: {
      button1: 'Subir Documentos',
      genericModalConfirmButton1: 'Continuar',
      genericModalCancelButton1: 'Volver',
      genericModalConfirmButton2: 'Continuar',
      genericModalCancelButton2: 'Volver',
    },
    notifications: {
      notifTitle1: 'Atención',
      notifBody1: 'No puedes firmar por dos partes',
      notifTitle2: 'Atención',
      notifBody2: (typeOfPerson, indexPlusOne) =>
        `El RFC del ${typeOfPerson} ${indexPlusOne} no es válido`, // indexPlusOne = i + 1
      notifTitle3: 'Atención',
      notifBody3: (typeOfPerson, indexPlusOne) =>
        `El nombre del ${typeOfPerson} ${indexPlusOne} no es válido`, // indexPlusOne = i + 1
      notifTitle4: 'Atención',
      notifBody4: (typeOfPerson, indexPlusOne) =>
        `El correo electrónico del ${typeOfPerson} ${indexPlusOne} no es válido`, // indexPlusOne = i + 1
      notifTitle5: 'Error', // TODO: translate response
      notifBody5: '', // TODO: translate response
      notifTitle6: 'Éxito', // TODO: translate response
      notifBody6: '', // TODO: translate response
      notifTitle7: 'Atención',
      notifBody7: (value) => `Ya existe '${value}`,
      notifTitle8: 'Atención',
      notifBody8: 'No puedes firmar por dos partes',
      notifTitle9: 'Atención',
      notifBody9: 'Solo puedes subir de 2 documentos en adelante',
      notifBody10:
        'Intentaste subir más de 250 MB, quedaron pendientes tus ultimos documentos, al finalizar la primer carga podrás subir los restantes',
      notifTitle10: 'Atención',
      notifBody11:
        'Un usuario está como firmante en tu listado y también dentro del PDF, corrigelo por favor',
      notifTitle11: 'Error',
      notifBody12:
        'Hay documentos que no se subieron por algún error, verificalos en la tabla y vuelve a cargarlos',
      notifTitle12: 'Error',
      notifBody13: 'Documentos cargados con éxito',
      notifTitle13: 'Éxito',
      notifBody14:
        'No te resta ningún cupon para subir documentos. Si deseas adquirir más contacta a soporte@sora.mx',
      notifTitle14: 'Advertencia',
      notifBody15: (usesLeft, totalFiles) =>
        `Te restán ${usesLeft} cupones y estás intentando subir ${totalFiles} documentos. Si deseas adquirir más contacta a soporte@sora.mx`,
      notifTitle15: 'Advertencia',
    },
    AddSignersDisplay: { ...AddSignersDisplayTranslations.es },
    ChipsDisplayMassive: { ...ChipsDisplayMassiveTranslations.es },
    ErrorsUploadFile: { ...ErrorsUploadFileTranslations.es },
    SelectDateDisplay: { ...SelectDateDisplayTranslations.es },
    UploadFilesDisplay: { ...UploadFilesDisplayTranslations.es },
    ModalToAssignFiles: { ...SelectContractsToSignersTranslations.es },
  },
  en: {
    texts: {
      h1: 'Multiple documents',
      p1: (aproximateTime) => `Approximate time ${aproximateTime}`,
      p2: 'Uploading PDFS... This can take a few minutes, do not leave the page.',
      genericModalTitle1: 'Cancel',
      genericModalBody1: (filesLength) =>
        `You are about to upload ${filesLength} documents. Do you want to continue?`,
      genericModalTitle2: 'Pending to upload',
      genericModalBody2: (filesLength) =>
        `${filesLength} documents were pending to upload. Do you want to upload them now?`,
      p3: 'Completed documents:',
      validateFile1:
        'Verify the structure of the document name. Correct and upload again',
      validateFile2: 'The email in the document name is not valid',
      message1: 'An error occurred while uploading the document',
      message2: 'Uploading documents...',
      message3: 'Verifying documents...',
    },
    inputs: {
      inputLabel1: 'Name:',
      inputLabelSpan1: '(The RFC of the pdf is added to the title)',
    },
    buttons: {
      button1: 'Upload Documents',
      genericModalConfirmButton1: 'Continue',
      genericModalCancelButton1: 'Back',
      genericModalConfirmButton2: 'Continue',
      genericModalCancelButton2: 'Back',
    },
    notifications: {
      notifTitle1: 'Attention',
      notifBody1: 'You can not sign for two parties',
      notifTitle2: 'Attention',
      notifBody2: (typeOfPerson, indexPlusOne) =>
        `The RFC of the ${typeOfPerson} ${indexPlusOne} is not valid`, // indexPlusOne = i + 1
      notifTitle3: 'Attention',
      notifBody3: (typeOfPerson, indexPlusOne) =>
        `The name of the ${typeOfPerson} ${indexPlusOne} is not valid`, // indexPlusOne = i + 1
      notifTitle4: 'Attention',
      notifBody4: (typeOfPerson, indexPlusOne) =>
        `The email of the ${typeOfPerson} ${indexPlusOne} is not valid`, // indexPlusOne = i + 1
      notifTitle5: 'Error', // TODO: translate response
      notifBody5: '', // TODO: translate response
      notifTitle6: 'Success', // TODO: translate response
      notifBody6: '', // TODO: translate response
      notifTitle7: 'Attention',
      notifBody7: (value) => `'${value}' already exists`,
      notifTitle8: 'Attention',
      notifBody8: 'You can not sign for two parties',
      notifTitle9: 'Attention',
      notifBody9: 'You can only upload 2 documents or more',
      notifBody10:
        'You tried to upload more than 250 MB, your latest documents were left pending. Once the first upload is finished, you can upload the remaining ones.',
      notifTitle10: 'Attention',
      notifBody11:
        'A user is listed as a signer in your list and also inside the PDF, please correct it',
      notifTitle11: 'Error',
      notifBody12:
        'Some documents were not uploaded due to an error, please check them in the table and upload them again',
      notifTitle12: 'Error',
      notifBody13: 'Documents successfully uploaded',
      notifTitle13: 'Success',
      notifBody14:
        'You have no remaining coupons to upload documents. If you wish to acquire more, please contact support@sora.mx.',
      notifTitle14: 'Warning',
      notifBody15: (
        usesLeft,
        totalFiles,
      ) => `You have ${usesLeft} coupons remaining and you are trying to upload ${totalFiles} documents. If you wish to acquire more, please contact support@sora.mx.,
      `,
      notifTitle15: 'Warning',
    },
    AddSignersDisplay: { ...AddSignersDisplayTranslations.en },
    ChipsDisplayMassive: { ...ChipsDisplayMassiveTranslations.en },
    ErrorsUploadFile: { ...ErrorsUploadFileTranslations.en },
    SelectDateDisplay: { ...SelectDateDisplayTranslations.en },
    UploadFilesDisplay: { ...UploadFilesDisplayTranslations.en },
    ModalToAssignFiles: { ...SelectContractsToSignersTranslations.en },
  },
};
