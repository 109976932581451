import React from 'react';
import { branch } from 'baobab-react/higher-order';

import Loader from '../../components/Loader';
import ContractActions from '../../actions/Contract';
import { NotificationManager } from 'react-notifications';
import {
  contractsToDownloadExcelUtils,
  getContractsToSign,
  massiveDownloadFilesUtils,
} from '../../utils/contractsContainerUtils';

import moment from 'moment';
import { downloadExcel } from '../../utils/downloadFilesUtils';
import SelectDateModal from '../../components/Modals/SelectDateModal';
import Signature from '../../utils/signature';
import GenericSignModal from '../../components/Modals/GenericSignModal';

class ButtonsActionsFilesDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingDownload: false,
      contractsForMe: [],
      showModal: false,
      modalSelectDate: false,
      fromDate: null,
      toDate: null,
      downloadType: null,
      responses: [],
      successSign: false,
      errors: [],
    };
  }

  canDownloadPdf = () => {
    const { contracts } = this.props;

    const contractsActive = contracts.filter(
      (contract) => contract?.isDownload && contract?.status === 'ready',
    );

    return contractsActive;
  };

  canSignAllContracts = () => {
    const { contracts } = this.props;
    const contractsActive = contracts.filter((contract) => contract.isActive);
    return contractsActive.length > 0;
  };

  onClickShowModalToSelectDate = (downloadType) => {
    const { modalSelectDate } = this.state;
    if (downloadType === 'excel') {
      this.setState({ downloadType });
    }

    if (modalSelectDate) {
      this.setState({ downloadType: null });
    }
    this.setState({ modalSelectDate: !modalSelectDate });
  };

  onInputDateChange = (e) => {
    if (e.target.name === 'fromDate') {
      return this.setState({ fromDate: e.target.value });
    }

    this.setState({ toDate: e.target.value });
  };

  onClickDownloadFiles = async (contractsActive) => {
    const { session, signer, translations, lang } = this.props;
    const { activeAllDownload } = this.state;
    const { notifications } = translations[lang].Contracts.ButtonsActionsFiles;

    this.setState({ loadingDownload: true });
    const top = 300;
    if (contractsActive.length > top) {
      contractsActive.length =
        contractsActive.length - (contractsActive.length - top);
    }
    if (!activeAllDownload) {
      const { data } = await ContractActions.massiveDownloadFiles(
        contractsActive,
        signer,
      );
      if (data.error) {
        this.setState({ loadingDownload: false });
        return NotificationManager.error(
          notifications.notifBody1,
          notifications.notifTitle1,
          10000,
        );
      }
      this.setState({ loadingDownload: false });
      return massiveDownloadFilesUtils(data, session);
    }
    const contractsActiveToSign = contractsActive.filter(
      (contract) =>
        contract.isDownload &&
        contract.status === 'ready' &&
        !contract.downloadedPdf,
    );
    const { data } = await ContractActions.massiveDownloadFiles(
      contractsActiveToSign,
      signer,
    );
    if (data.error) {
      this.setState({ loadingDownload: false });
      return NotificationManager.error(
        notifications.notifBody1,
        notifications.notifTitle1,
        10000,
      );
    }
    this.setState({ loadingDownload: false });
    const nameZip = signer
      ? signer.legalRfc || signer.legalName
      : session.user.legalRfc || session.user.legalName;
    return massiveDownloadFilesUtils(data, nameZip);
  };

  onCheckboxChange = () => {
    const {
      contracts,
      session: { user },
    } = this.props;
    const contractsToSign = getContractsToSign(contracts, user);

    //LIMIT FILES TO SIGN
    const top = 500;
    if (contractsToSign.length > top) {
      contractsToSign.length =
        contractsToSign.length - (contractsToSign.length - top);
    }

    this.setState({ contractsForMe: contractsToSign, showModal: true });
  };

  onClickDownloadExcel = async (excelDownloadType) => {
    this.setState({ loading: true });
    const { contracts, lang, translations } = this.props;
    const { fromDate, toDate } = this.state;
    const { notifications } = translations[lang].Contracts.ButtonsActionsFiles;

    const contractsSelected = [...contracts];

    if (excelDownloadType === 'bySigners') {
      const ids = contractsSelected.map((contract) => contract?.id);

      const contractsSigners = await ContractActions.getExcelReport({
        contractIds: ids,
        fromDate,
        toDate: moment(toDate).add(1, 'day').format('YYYY-MM-DD'),
      });
      if (!contractsSigners?.data?.success) {
        return NotificationManager.error(
          contractsSigners?.data,
          'Atención',
          5000,
        );
      }
      downloadExcel(contractsSigners.data.data, 'contrato_con_firmantes');
      this.setState({ loading: false });
      return NotificationManager.success(
        notifications.notifBody2,
        notifications.notifTitle2,
        5000,
      );
    }

    const allContracts = contractsToDownloadExcelUtils(
      contractsSelected,
      fromDate,
      toDate,
    );
    if (!allContracts?.length) {
      this.setState({ loading: false });
      return NotificationManager.warning(
        notifications.notifBody3,
        notifications.notifTitle3,
        5000,
      );
    }

    downloadExcel(allContracts, 'todos_mis_contratos');
    this.setState({ loading: false });
    return NotificationManager.success(
      notifications.notifBody2,
      notifications.notifTitle2,
      5000,
    );
  };

  downloadMassivePdfByDate = async (attribute) => {
    this.setState({ loading: true });
    const {
      signer,
      session: { user },
    } = this.props;
    const { fromDate, toDate } = this.state;
    const id = signer ? signer.rootUser : user.id;
    const legalRfc = signer ? signer.legalRfc : user.legalRfc;
    const { data } = await ContractActions.downloadPdfByDate({
      id,
      legalRfc,
      from: fromDate,
      to: toDate,
      signer,
      attribute,
    });
    if (data.error) {
      this.setState({ loading: false });
      return NotificationManager.error(data.error, 'Error', 10000);
    }
    this.setState({ loading: false });
    const nameZip = signer
      ? signer.legalRfc || signer.legalName
      : user.legalRfc || user.legalName;
    return massiveDownloadFilesUtils(data, nameZip);
  };

  onSign = async (modalState) => {
    const { contractsForMe, errors, responses } = this.state;
    const {
      session: { user },
      admin,
      locationUser,
      contracts,
      globalContracts,
      updateState,
      translations,
      lang,
    } = this.props;

    const { notifications } = translations[lang].Contracts.ButtonsActionsFiles;

    if (!contractsForMe?.length) {
      return NotificationManager.warning(
        notifications.notifBody4,
        notifications.notifTitle4,
        8000,
      );
    }

    if (!locationUser) {
      return NotificationManager.warning(
        notifications.notifBody5,
        notifications.notifTitle5,
        8000,
      );
    }

    this.setState({ loading: true });
    NotificationManager.info(
      notifications.notifBody6,
      notifications.notifTitle6,
      8000,
    );
    const credentials = modalState.state;
    const signerUser = { ...user };
    signerUser.representing = admin ? admin?.representing : null;
    signerUser.representedRfc = admin ? admin?.legalRfc || null : null;
    // this.hideModal();
    const copiedContracts = [...contracts];
    const copiedGlobalContracts = [...globalContracts];

    for (let i = 0; i < contractsForMe.length; i++) {
      const contract = contractsForMe[i];
      try {
        const cuteSign = await Signature(
          credentials,
          contract.lastHash || contract.hash,
        );

        const response = await ContractActions.sign({
          contract,
          credentials,
          user: signerUser,
          cuteSign,
          locationUser,
        });

        if (response !== 1) {
          errors.push({
            doc: contract.title,
            error: response?.message || notifications.error1,
          });
          // this.setState({ errors: [...errors, contract.id] });
          continue;
        }

        const contractFound = copiedContracts.findIndex(
          (con) => con.id === contract.id,
        );
        const globalContractFound = copiedGlobalContracts.findIndex(
          (con) => con.id === contract.id,
        );
        let contractSigned = {
          ...contract,
          signed: 1,
          status:
            contract.participants === contract.lastSignedTurn + 1
              ? 'ready'
              : 'byMe',
          isActive: false,
          allPartiesSigned:
            contract.participants === contract.lastSignedTurn + 1
              ? true
              : false,
        };
        contracts.splice(contractFound, 1, contractSigned);
        globalContracts.splice(globalContractFound, 1, contractSigned);
        updateState({ contracts, globalContracts });
        responses.push(response);
      } catch (error) {
        errors.push({
          doc: contract.title,
          error: notifications.error1,
        });
        // this.setState({ errors: [...this.state.errors, contract.id] });
      }
    }

    if (responses?.length) {
      this.setState({ successSign: true });
      try {
        await ContractActions.sendEmailToUserWhenSignMassive(
          user,
          contractsForMe.length,
        );
        this.setState({ loading: false });
        if (responses.length === contractsForMe.length) {
          return NotificationManager.success(
            `${responses.length} ${notifications.notifBody7}`,
            notifications.notifTitle7,
            10000,
          );
        }
      } catch (error) {
        console.log(error);
        this.setState({ loading: false });
      }
    }

    if (errors?.length) {
      this.setState({ loading: false, errors });
      NotificationManager.error(
        notifications.notifBody8,
        notifications.notifTitle8,
        10000,
      );
      return;
    }
  };

  render() {
    const { cannotSign, signer, session, hasFilters, lang, translations } =
      this.props;
    const { texts, buttons } = translations[lang].Contracts.ButtonsActionsFiles;

    const {
      loading,
      loadingDownload,
      showModal,
      contractsForMe,
      modalSelectDate,
      fromDate,
      toDate,
      downloadType,
      responses,
      successSign,
      errors,
    } = this.state;

    return (
      <>
        {loadingDownload ? (
          <div className="loader_download_contracts">
            <Loader />
            <p>{texts.p1}</p>
          </div>
        ) : (
          <div className="button_actions">
            {signer ? (
              <>
                {this.canDownloadPdf().length ? (
                  <div
                    className="download_files_contracts"
                    onClick={() =>
                      this.onClickDownloadFiles(this.canDownloadPdf())
                    }
                  >
                    <i className="fas fa-file-download" />
                    {texts.div1}
                  </div>
                ) : null}

                <i
                  className="far fa-file-excel"
                  onClick={() => this.onClickShowModalToSelectDate('excel')}
                />

                <i
                  className="far fa-file-archive"
                  onClick={() => this.onClickShowModalToSelectDate()}
                />
              </>
            ) : null}
            {!signer ? (
              cannotSign ? (
                <button type="button" className="cancel">
                  {buttons.button1}
                </button>
              ) : (
                <>
                  {this.canDownloadPdf().length ? (
                    <div
                      className="download_files_contracts"
                      onClick={() =>
                        this.onClickDownloadFiles(this.canDownloadPdf())
                      }
                    >
                      <i
                        className="fas fa-file-download"
                        style={{ fontSize: 16 }}
                      />
                      {texts.div2}
                    </div>
                  ) : null}

                  <i
                    className="far fa-file-excel"
                    onClick={() => this.onClickShowModalToSelectDate('excel')}
                  />

                  <i
                    className="far fa-file-archive"
                    onClick={() => this.onClickShowModalToSelectDate()}
                  />
                  <button
                    type="button"
                    id="myCheck"
                    className="primary"
                    disabled={!this.canSignAllContracts()}
                    onClick={this.onCheckboxChange}
                  >
                    {buttons.button2}
                  </button>
                </>
              )
            ) : null}
          </div>
        )}

        {showModal ? (
          <GenericSignModal
            contractsForMe={contractsForMe}
            handleConfirm={this.onSign}
            handleCancel={() =>
              this.setState({
                showModal: false,
                errors: [],
              })
            }
            contracts={contractsForMe}
            type="sign"
            loading={loading}
            responses={responses}
            successSign={successSign}
            errors={errors}
          />
        ) : null}

        {modalSelectDate ? (
          <SelectDateModal
            handleCancel={this.onClickShowModalToSelectDate}
            onInputDateChange={this.onInputDateChange}
            downloadMassivePdfByDate={this.downloadMassivePdfByDate}
            loading={loading}
            fromDate={fromDate}
            toDate={toDate}
            signer={signer}
            user={session.user}
            downloadType={downloadType}
            downloadExcel={this.onClickDownloadExcel}
            hasFilter={hasFilters}
          />
        ) : null}
      </>
    );
  }
}

export default branch(
  {
    session: ['session'],
    translations: ['translations'],
    lang: ['lang'],
  },
  ButtonsActionsFilesDisplay,
);
