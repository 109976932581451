import React from 'react';
import ContractActions from '../../actions/Contract';
import { NotificationManager } from 'react-notifications';
import Loader from '../Loader';
import { emailRegex } from '../../utils/constants';
import ProfileActions from '../../actions/Profile';
import { branch } from 'baobab-react/higher-order';

class UpdateSignerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataToUpdate: '',
      loading: false,
      userToAdd: {
        legalName: '',
        legalRfc: '',
        email: '',
        signType: '',
        userExist: false,
        userId: null,
        requestVerification: false,
      },
      multipleUsers: false,
      sameUsers: [],
    };
  }

  componentDidMount() {
    const { signerToUpdate } = this.props;
    this.setState({
      userToAdd: {
        legalName: signerToUpdate.legalName,
        legalRfc: signerToUpdate.legalRfc || '',
        email: signerToUpdate.secondaryEmail || signerToUpdate.email,
        signType: signerToUpdate.signType,
        userExist: Boolean(signerToUpdate.userId),
        userId: signerToUpdate.userId,
        requestVerification: Boolean(signerToUpdate.requestVerification),
      },
    });
  }

  handleChange = async (e) => {
    const { userToAdd } = this.state;
    const { value, name, checked } = e.target;
    let newValue = '';

    if (name === 'legalRfc' || name === 'email') {
      newValue = value.replace(/\s/g, '');
    } else {
      newValue = value;
    }

    if (newValue?.includes('.')) {
      newValue = newValue.toLowerCase();
    }

    this.setState({
      userToAdd: {
        ...userToAdd,
        [name]: name === 'requestVerification' ? checked : newValue,
      },
    });

    if (newValue.match(emailRegex)) {
      await this.searchUserByData(newValue);
    }

    this.setState({ dataToUpdate: newValue });
  };

  searchUserByData = async (value) => {
    const { userToAdd } = this.state;
    const response = await ProfileActions.searchUserByData({
      value,
    });
    const data = response?.data;

    if (data.type) {
      const { type, users } = data;
      if (type === 'USER_NOT_FOUND') {
        this.setState({
          userToAdd: {
            ...userToAdd,
            email: value,
            legalName: '',
            legalRfc: '',
            userExist: false,
            userId: null,
          },
          multipleUsers: false,
          sameUsers: [],
        });
        return;
      }

      if (type === 'SINGLE_USER') {
        this.setState({
          userToAdd: {
            ...userToAdd,
            email: value,
            legalName: users.legalName,
            legalRfc: users.legalRfc || '',
            userExist: true,
            userId: users.id,
          },
          multipleUsers: false,
          sameUsers: [],
        });
        return;
      }

      if (type === 'MULTIPLE_USERS') {
        this.setState({
          userToAdd: {
            ...userToAdd,
            email: value,
            legalName: '',
            legalRfc: '',
            userExist: false,
            userId: null,
          },
          multipleUsers: true,
          sameUsers: users,
        });
        return;
      }
    }
  };

  updateUser = async () => {
    const { userToAdd, multipleUsers } = this.state;

    const { contract, signerToUpdate, typeOfUpdate, lang, translations } =
      this.props;
    const { notifications } = translations[lang].Modals.UpdateSignerModal;

    if (userToAdd.legalRfc && userToAdd.legalRfc.length < 12) {
      return NotificationManager.error(
        notifications.notifBody1,
        notifications.notifTitle1,
        3000,
      );
    }

    if (!userToAdd.email || !userToAdd.email.match(emailRegex)) {
      return NotificationManager.error(
        'El correo no es correcto',
        'Error',
        3000,
      );
    }

    if (!userToAdd.email || !userToAdd.legalName) {
      return NotificationManager.error(
        'El correo o nombre no pueden ir vacíos',
        'Error',
        3000,
      );
    }

    if (multipleUsers && !userToAdd.userId) {
      return NotificationManager.error(
        'Selecciona un usuario del listado',
        'Error',
        3000,
      );
    }

    if (!signerToUpdate) {
      return NotificationManager.error(
        notifications.notifBody2,
        notifications.notifTitle2,
        3000,
      );
    }
    this.setState({ loading: true });

    const response = await ContractActions.updateSigner(
      [],
      contract,
      signerToUpdate,
      userToAdd,
      typeOfUpdate,
    );

    if (!response?.success) {
      this.setState({ loading: false });
      return NotificationManager.error(
        response?.message || 'Hubo un error al actualizar',
        'Error',
        6000,
      );
    }

    NotificationManager.success(
      notifications.notifBody4,
      notifications.notifTitle4,
      3000,
    );
    this.setState({ loading: true });
    window.location.reload();
  };

  handleUpdateUser = (user) => {
    const { userToAdd } = this.state;
    this.setState({
      userToAdd: {
        ...userToAdd,
        legalName: user.legalName,
        legalRfc: user.legalRfc || '',
        userExist: true,
        userId: user.id,
      },
    });
  };

  render() {
    const { closeModal, lang, translations } = this.props;
    const { loading, userToAdd, multipleUsers, sameUsers } = this.state;
    const { texts, inputs, buttons } =
      translations[lang].Modals.UpdateSignerModal;
    return (
      <div
        className="modal-container"
        style={{ width: '50%', margin: '0 auto' }}
      >
        <div className="modal">
          <div className="header_modal">
            <h1 style={{ color: '#2851e5', fontSize: 16, textAling: 'center' }}>
              {texts.h1}
            </h1>
            {userToAdd.userExist && (
              <p style={{ fontSize: 12, textAling: 'center' }}>{texts.p3}</p>
            )}
          </div>
          <div className="body_modal">
            {loading ? (
              <div className="center_loading">
                <Loader />
                <p>{texts.p2}</p>
              </div>
            ) : (
              <>
                <input
                  value={userToAdd.email}
                  onChange={this.handleChange}
                  name="email"
                  type="text"
                  className="input_nc"
                  required
                  placeholder={inputs.inputPlaceholder1}
                  autoFocus
                  style={{ textTransform: 'lowercase' }}
                />
                {multipleUsers && (
                  <div className="select_display">
                    <select
                      name="signer"
                      onChange={(event) =>
                        this.handleUpdateUser(
                          sameUsers[event.target.selectedIndex - 1],
                        )
                      }
                      autoComplete="nope"
                      className="input_nc"
                      // disabled={!this.disabledButton(localSigner)}
                    >
                      <option value="" disabled selected>
                        {inputs.inputPlaceholder2}
                      </option>
                      {sameUsers.map((user, i) => (
                        <option key={i} value={JSON.stringify(user)}>
                          {user.legalName} - {user.legalRfc}
                        </option>
                      ))}
                    </select>
                    <i
                      className="fas fa-angle-down	"
                      style={{ color: 'black' }}
                    />
                  </div>
                )}
                <input
                  value={userToAdd.legalRfc}
                  onChange={this.handleChange}
                  name="legalRfc"
                  type="text"
                  className="input_nc"
                  required
                  placeholder={inputs.inputPlaceholder3}
                  autoFocus
                  disabled={userToAdd.userExist || multipleUsers}
                  style={{ textTransform: 'uppercase' }}
                />
                <input
                  value={userToAdd.legalName}
                  onChange={this.handleChange}
                  name="legalName"
                  type="text"
                  className="input_nc"
                  required
                  placeholder={inputs.inputPlaceholder4}
                  autoFocus
                  disabled={userToAdd.userExist || multipleUsers}
                  style={{ textTransform: 'capitalize' }}
                />
                <div className="update_signer_request_verification">
                  <div className="input_checkbox">
                    <input
                      type="checkbox"
                      id="cbox2"
                      checked={userToAdd.requestVerification}
                      name="requestVerification"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="tooltip_checkbox">
                    <span>{texts.span1}</span>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="footer_modal">
            <button
              className="color-grey"
              type="button"
              onClick={() => closeModal()}
              disabled={loading}
            >
              {buttons.button1}
            </button>
            <button
              className="primary"
              type="button"
              //   disabled={!this.disabledButton()}
              onClick={() => this.updateUser()}
            >
              {buttons.button2}
            </button>
          </div>
        </div>
        <div className="background-screen" />
      </div>
    );
  }
}

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  UpdateSignerModal,
);
