import React from 'react';
import { branch } from 'baobab-react/higher-order';
import { NotificationManager } from 'react-notifications';
import ContractActions from '../actions/Contract';
import ViewPdf from '../containers/_common/ViewPdf';
import CerFields from '../containers/_common/CerFields';

class GenericContractPreview extends React.Component {
  /**
   * Builds the component
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      confirming: false,
      signModalSifeSignerAndAdmin: false,
      showModalNormal: true,
      numPages: null,
      openNewViewInputs: false,
    };
  }

  openNewIputs = () => {
    this.setState({ openNewViewInputs: true });
  };

  showModalSifeSignerAndAdmin = () => {
    const { contract, user, signedUser } = this.props;
    ContractActions.createContractSignerSife(contract, user, signedUser).then(
      (response) => {
        if (response.status === 201) {
          this.setState({ signModalSifeSignerAndAdmin: true });
          this.setState({ showModalNormal: false });
        }
      },
    );
  };

  getDataFromInputs = (data) => {
    this.setState(data);
  };

  canSign = () => {
    const { key, cer, passphrase } = this.state;
    return passphrase?.length && key && cer;
  };

  // ESTE ES PARA CONTRATOS
  onLoadFrame = async () => {
    const { contract, user, signedUser } = this.props;
    ContractActions.createContractSignerSife(contract, user, signedUser).then(
      (response) => {
        if (response.status === 201) {
          // this.setState({ signModalSifeSignerAndAdmin: true });
          this.setState({ showModalNormal: false });
        }
      },
    );

    let eventMethod = window.addEventListener
      ? 'addEventListener'
      : 'attachEvent';
    let eventer = window[eventMethod];
    let messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

    eventer(messageEvent, async (e) => {
      // TODO
      if (e.data.message === 'DOC_SIGNED') {
        window.location = '/perfil';
      }
    });
  };

  windowOpen = ({ url, title, w, h }) => {
    // Fixes dual-screen position Most browsers      Firefox

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.screen.height;

    const left = width / 2 - w / 2;
    const top = height / 2 - h / 2;
    const newWindow = window.open(
      url,
      title,
      `
      scrollbars=yes,
      width=${w}, 
      height=${h}, 
      top=${top}, 
      left=${left}
      `,
    );
    // FIXME: for some reason loading executes onunload and not onload
    newWindow.onunload = this.onLoadFrame;
    newWindow.onload = function () {
      // console.log("heeeeeyyy");
    };

    if (window.focus) newWindow.focus();
    // setWindowObjRef(newWindow);
  };

  showIframeContract = async () => {
    const {
      contract,
      user,
      signedUser,
      showIframeToSignContract,
      translations,
      lang,
    } = this.props;
    const { notifications } = translations[lang].Modals.GenericContractPreview;
    const contractResponse = await ContractActions.createContractSignerSife(
      contract,
      user,
      signedUser,
    );
    if (!contractResponse?.data?.success || !contractResponse) {
      return NotificationManager.error(
        notifications.notifBody1,
        notifications.notifTitle1,
        5000,
      );
    }
    showIframeToSignContract();
  };

  handleButton = () => {
    const {
      signedUser,
      cannotSign,
      user,
      handleConfirm,
      type,
      width,
      translations,
      lang,
    } = this.props;
    const { buttons } = translations[lang].Modals.GenericContractPreview;
    const { openNewViewInputs } = this.state;
    const isNotMobile = width?.width > 992;

    if (cannotSign) {
      return (
        <button className="primary" type="button" disabled>
          {buttons.button1}
        </button>
      );
    }

    if (type === 'demo') {
      return null;
    }

    if (signedUser && signedUser?.signed) {
      return (
        <button
          style={{
            background: '#fff',
            color: 'black',
          }}
          className="status"
          type="button"
        >
          {buttons.button2}
        </button>
      );
    }

    if (user?.typeUser === 'sife') {
      return (
        <div className="elements_sign_button" onClick={this.showIframeContract}>
          <p className="text_button_sife">{buttons.button3}</p>
        </div>
      );
    }

    return isNotMobile ? (
      <button
        className="primary"
        type="button"
        disabled={!this.canSign()}
        onClick={() => handleConfirm(this)}
      >
        {buttons.button4}
      </button>
    ) : (
      <button
        className="primary"
        type="button"
        onClick={() =>
          openNewViewInputs ? handleConfirm(this) : this.openNewIputs()
        }
      >
        {openNewViewInputs ? buttons.button5 : buttons.button6}
      </button>
    );
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  render() {
    const { showModalNormal, openNewViewInputs } = this.state;
    const {
      handleCancel,
      contractUrl,
      signedUser,
      width,
      type,
      translations,
      lang,
      user,
    } = this.props;
    const { texts, buttons } = translations[lang].Modals.GenericContractPreview;

    return (
      <div className="modal-container-sign">
        {showModalNormal ? (
          <div className="modal-sign">
            {!openNewViewInputs || width?.width > 992 ? (
              <ViewPdf contractUrl={contractUrl} />
            ) : null}
            {!signedUser?.signed &&
            localStorage?.getItem('sessionType') === 'efirma' &&
            !user?.typeUser ? (
              <div className="body_modal">
                {width?.width > 992 && type !== 'demo' ? (
                  <CerFields getDataFromInputs={this.getDataFromInputs} />
                ) : openNewViewInputs ? (
                  <div className="mobile_version">
                    <div className="information">
                      <h3>{texts.h3}</h3>
                      <p>{texts.p1}</p>
                    </div>
                    <CerFields getDataFromInputs={this.getDataFromInputs} />
                  </div>
                ) : null}
              </div>
            ) : null}

            <div className="footer_modal" style={{ padding: 5 }}>
              <button
                className="color-grey"
                type="button"
                onClick={() =>
                  openNewViewInputs
                    ? this.setState({ openNewViewInputs: false })
                    : handleCancel(signedUser)
                }
              >
                {buttons.button7}
              </button>
              {this.handleButton()}
            </div>
          </div>
        ) : null}
        <div className="background-screen" />
      </div>
    );
  }
}

export default branch(
  {
    width: ['width'],
    translations: ['translations'],
    lang: ['lang'],
  },
  GenericContractPreview,
);
