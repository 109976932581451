import { branch } from 'baobab-react/higher-order';
import React, { Fragment } from 'react';
import Loader from '../Loader';

class GenericSignModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirming: false,
    };
  }

  showConfirmation = () => {
    this.setState({ confirming: true });
  };

  /**
   * Shows the File Input and Accepts Key & Cer
   * @param event
   */
  promptFile = (event) => {
    event.target.blur();
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    const { name } = event.target;
    if (name === 'cer') {
      input.setAttribute('accept', '.cer');
    } else {
      input.setAttribute('accept', '.key');
    }
    input.onchange = (e) => {
      const file = e.target.files[0];
      this.setState({ [name]: file });
    };
    input.click();
  };

  /**
   * Handle input changes
   * @param  {Object} event Input change event
   */
  onInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  /**
   * Can Sign the Contract if all inputs
   * @returns {*}
   */
  canSign = () => {
    const { key, cer, passphrase } = this.state;
    return passphrase && key && cer;
  };

  render() {
    const {
      handleCancel,
      handleConfirm,
      contracts,
      loading,
      responses,
      successSign,
      lang,
      translations,
      errors,
    } = this.props;
    const { cer, key, passphrase } = this.state;
    const { texts, buttons, inputs } =
      translations[lang].Contracts.GenericSignModal;

    if (errors?.length) {
      return (
        <div className="modal-container">
          <div className="modal generic_sign_modal">
            <div className="info_sign_documents_modal">
              <i
                className="fas fa-check-circle fa-2x"
                style={{ color: '#7ED321', marginBottom: 20 }}
              />{' '}
              <br></br>
              <small> </small>
              <small
                style={{ fontSize: 16 }}
              >{`${responses.length} ${texts.small3}`}</small>
              <br />
              <small style={{ color: 'red', fontSize: 16 }}>
                {texts.small5}
              </small>
            </div>
            <div
              className="body_modal"
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              <div className="select_user_massive">
                <div className="header_select_user_massive">
                  <small className="bold">{texts.small6}</small>
                  <small className="bold">{texts.small7}</small>
                </div>
                <div
                  className="body_select_user_massive"
                  style={{ maxHeight: 400, overflow: 'auto' }}
                >
                  {errors?.map((error, index) => (
                    <Fragment key={index}>
                      <small>{error.doc}</small>
                      <div>
                        <small>{error.error}</small>
                      </div>
                    </Fragment>
                  ))}
                </div>
              </div>
            </div>
            <div className="footer_modal">
              <button
                className="color-grey"
                type="button"
                onClick={handleCancel}
                disabled={loading}
              >
                {buttons.button1}
              </button>
              <button
                className="primary"
                type="button"
                disabled={true}
                // onClick={() => handleConfirm(this)}
              >
                {buttons.button2}
              </button>
            </div>
          </div>
          <div className="background-screen" />
        </div>
      );
    }

    return (
      <div className="modal-container">
        <div className="modal generic_sign_modal">
          {contracts.length > 0 ? (
            <>
              {loading ? (
                <>
                  <div className="info_sign_documents_modal">
                    <h3>{texts.text1}</h3>
                    <small>{`${contracts.length} ${texts.small1}`}</small>
                  </div>
                  <div className="center_loading">
                    <Loader />
                    <p>{`${responses.length} ${texts.small2}`}</p>
                  </div>
                </>
              ) : successSign ? (
                <div className="info_sign_documents_modal">
                  <i
                    className="fas fa-check-circle fa-2x"
                    style={{ color: '#7ED321', marginBottom: 20 }}
                  />{' '}
                  <br></br>
                  <small>{`${responses.length} ${texts.small3}`}</small>
                  <div className="footer_modal">
                    <button
                      className="color-grey"
                      type="button"
                      onClick={handleCancel}
                      disabled={loading}
                    >
                      {buttons.button1}
                    </button>
                    <button
                      className="primary"
                      type="button"
                      disabled={!this.canSign() || loading || successSign}
                      onClick={() => handleConfirm(this)}
                    >
                      {buttons.button2}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="sign_contract_modal">
                  <div className="header_modal">
                    <h3>{texts.text2}</h3>
                    <small
                      style={{ fontSize: 14 }}
                    >{`${contracts.length} ${texts.small1}`}</small>
                  </div>

                  <div className="body_modal">
                    <input
                      onClick={this.promptFile}
                      onChange={() => {}}
                      name="cer"
                      type="text"
                      required
                      value={cer ? cer.name : ''}
                      placeholder={inputs.inputPlaceholder1}
                      className="input_nc"
                    />
                    <input
                      onClick={this.promptFile}
                      onChange={() => {}}
                      name="key"
                      type="text"
                      required
                      value={key ? key.name : ''}
                      placeholder={inputs.inputPlaceholder2}
                      className="input_nc"
                    />
                    <input
                      onChange={this.onInputChange}
                      name="passphrase"
                      type="password"
                      required
                      value={passphrase || ''}
                      placeholder={inputs.inputPlaceholder3}
                      className="input_nc"
                    />
                    <div className="warning_save_key">
                      <small>{texts.small4}</small>
                    </div>
                  </div>

                  <div className="footer_modal">
                    <button
                      className="color-grey"
                      type="button"
                      onClick={handleCancel}
                      disabled={loading}
                    >
                      {buttons.button1}
                    </button>
                    <button
                      className="primary"
                      type="button"
                      disabled={!this.canSign() || loading || successSign}
                      onClick={() => handleConfirm(this)}
                    >
                      {buttons.button2}
                    </button>
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="header_modal">
                <h3>{texts.text3}</h3>
              </div>
              <div className="footer_modal">
                <button
                  className="color-grey"
                  type="button"
                  onClick={handleCancel}
                  disabled={loading}
                >
                  {buttons.button1}
                </button>
                <button
                  className="primary"
                  type="button"
                  disabled={!this.canSign() || loading || successSign}
                  onClick={() => handleConfirm(this)}
                >
                  {buttons.button2}
                </button>
              </div>
            </>
          )}
        </div>
        <div className="background-screen" />
      </div>
    );
  }
}

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  GenericSignModal,
);
