import React from 'react';
import Loader from '../../components/Loader';
import ActionButtons from '../_common/ActionButtons';
import UploadIdentityDocs from './UploadIdentityDocs';
import VerificationWarning from './VerificationWarning';
import { branch } from 'baobab-react/higher-order';

const SignContractDisplay = (props) => {
  const {
    contract,
    loading,
    totalLoading,
    authorizers,
    authorizer,
    myDocuments,
    showModalContract,
    showModalContractSigned,
    updateStateIdentityDocuments,
    signer,
    onClickOpenCameraModal,
    isMyTurnToSign,
    showModalAuthorizer,
    verifySife,
    signersDropdown,
    onClickUpdateSigner,
    translations,
    lang,
  } = props;
  const { buttons } = translations[lang].ContractDetail.SignContractDisplay;

  if (loading) {
    return (
      <div className="sign_contracts_cd">
        <div className="center_loading">
          <Loader totalLoading={totalLoading} />
        </div>
      </div>
    );
  }

  return (
    <div className="sign_contracts_cd">
      {signersDropdown ? (
        <>
          <button
            type="button"
            className="update"
            onClick={onClickUpdateSigner}
          >
            {buttons.button1}
          </button>
          <br />
        </>
      ) : null}
      {Boolean(contract.is_valid) ? (
        <>
          {loading ? (
            <div className="center_loading">
              <Loader totalLoading={totalLoading} />
            </div>
          ) : !authorizers.every((auth) => auth.signed) ? (
            <button
              style={{
                background:
                  !authorizer.signed && !authorizer.noSigned
                    ? '#2851e5'
                    : '#6C6C6C',
              }}
              type="button"
              onClick={showModalAuthorizer}
            >
              {!authorizer.signed && !authorizer.noSigned
                ? buttons.button2
                : buttons.button3}
            </button>
          ) : //Esto se usa para subir los documentos de identidad
          !myDocuments.every((document) => document.uploaded) ? (
            <UploadIdentityDocs
              myDocuments={myDocuments}
              showModalContractSigned={showModalContractSigned}
              updateStateIdentityDocuments={updateStateIdentityDocuments}
            />
          ) : //Modal para verificar usuario signer.requestVerification &&
          signer &&
            signer.requestVerification &&
            !signer.verified &&
            verifySife.includes('efirma') ? (
            <div className="main_documents">
              <VerificationWarning />
              <button
                type="button"
                className="primary"
                onClick={onClickOpenCameraModal}
              >
                {buttons.button4}
              </button>
            </div>
          ) : //Botones para abrir modal de firma
          signer &&
            !signer.verified &&
            signer.requestVerification &&
            verifySife.includes('sife') ? (
            <div className="main_documents">
              <VerificationWarning />
              <button
                style={{
                  background: isMyTurnToSign ? '#2851e5' : '#6C6C6C',
                }}
                type="button"
                onClick={
                  showModalContract
                  /* signer && !signer.signed
                  ? showModalContract
                  : showModalContractSigned */
                }
              >
                {isMyTurnToSign ? buttons.button5 : buttons.button6}
              </button>
            </div>
          ) : (
            <button
              style={{
                background: isMyTurnToSign ? '#2851e5' : '#6C6C6C',
              }}
              type="button"
              onClick={
                showModalContract
                /* signer && !signer.signed
                ? showModalContract
                : showModalContractSigned */
              }
            >
              {isMyTurnToSign ? buttons.button7 : buttons.button8}
            </button>
          )}
        </>
      ) : (
        <button
          style={{
            background: '#6C6C6C',
          }}
          type="button"
          onClick={
            showModalContractSigned
            /* signer && !signer.signed
        ? showModalContract
        : showModalContractSigned */
          }
        >
          {buttons.button8}
        </button>
      )}

      <br></br>
      {contract.allPartiesSigned ? null : <ActionButtons contract={contract} />}
    </div>
  );
};

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  SignContractDisplay,
);
