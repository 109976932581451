import React from 'react';
import { branch } from 'baobab-react/higher-order';
import { NotificationManager } from 'react-notifications';
import { emailRegex } from '../../utils/constants';

class UploadButtonDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  verifyUsersHasSameRfc = () => {
    const {
      checkAuthorizers,
      authorizers,
      signers,
      recipients,
      updateStateNewContract,
    } = this.props;

    if (authorizers?.length && checkAuthorizers) {
      for (let i = 0; i < authorizers.length; i++) {
        const authorizer = authorizers[i];
        const sameSigner = signers?.find(
          (signer) =>
            signer?.email === authorizer?.email &&
            signer?.legalRfc !== authorizer?.legalRfc,
        );
        if (sameSigner) {
          const indexSigner = signers.findIndex(
            (signer) => signer.legalRfc === sameSigner.legalRfc,
          );
          sameSigner.legalRfc = authorizer.legalRfc;
          signers.splice(indexSigner, 1, sameSigner);
          updateStateNewContract({ signers });
        }

        if (recipients?.length) {
          const sameRecipient = recipients?.find(
            (rec) =>
              rec?.email === authorizer?.email &&
              rec?.legalRfc !== authorizer?.legalRfc,
          );

          if (sameRecipient) {
            const indexRecipients = recipients.findIndex(
              (rec) => rec.legalRfc === sameRecipient.legalRfc,
            );
            sameRecipient.legalRfc = authorizer.legalRfc;
            recipients.splice(indexRecipients, 1, sameRecipient);
            updateStateNewContract({ recipients });
          }
        }
      }
    }

    if (signers?.length && !checkAuthorizers) {
      for (let i = 0; i < signers.length; i++) {
        const signer = signers[i];

        if (recipients?.length) {
          const sameRecipient = recipients?.find(
            (rec) =>
              rec?.email === signer?.email &&
              rec?.legalRfc !== signer?.legalRfc,
          );

          if (sameRecipient) {
            const indexRecipients = recipients.findIndex(
              (rec) => rec.legalRfc === sameRecipient.legalRfc,
            );
            sameRecipient.legalRfc = signer.legalRfc;
            recipients.splice(indexRecipients, 1, sameRecipient);
            updateStateNewContract({ recipients });
          }
        }
      }
    }
  };

  showModal = () => {
    const {
      signers,
      authorizers,
      recipients,
      checkAuthorizers,
      updateStateNewContract,
      signTypeSigner,
      activeButtonUploadPdf,
      coupon,
      translations,
      lang,
    } = this.props;
    const { notifications } = translations[lang].NewContract.UploadButton;
    if (!signTypeSigner && !activeButtonUploadPdf) {
      return NotificationManager.warning(
        notifications.notifBody1,
        notifications.notifTitle1,
        4000,
      );
    }

    this.addRecipientIsNotParticipant();
    this.verifyUsersHasSameRfc();
    let errorsAuthorizers = [];
    const errorsSigners = this.validateFields(signers, 'firmante');
    if (checkAuthorizers) {
      errorsAuthorizers = this.validateFields(authorizers, 'autorizador');
    }
    const errorsRecipients = this.validateFields(recipients, 'copiado');
    const errors = [
      ...errorsSigners,
      ...errorsAuthorizers,
      ...errorsRecipients,
    ];
    const participants = [];
    if (errors.some((error) => !error)) {
      return;
    }
    if (checkAuthorizers) {
      participants.push(...authorizers);
    }

    if (!checkAuthorizers) {
      participants.push(...signers);
    }

    const sameUsers = this.verifySameUsers([...participants, ...recipients]);

    if (sameUsers?.length) {
      const info = sameUsers.map((user) => user.legalRfc || user.email);
      const infoJoin = info.join(',');

      return NotificationManager.warning(
        `Los siguientes usuarios ${infoJoin} están repetidos en apoderados, firmantes o copiados`,
        'Atención',
        5000,
      );
    }

    if (!coupon || coupon?.usesLeft < 10) {
      return updateStateNewContract({ showModal: true });
    }

    updateStateNewContract({ showConfirmModal: true });
  };

  validateFields = (participants, key) => {
    const { activeButtonUploadPdf, translations, lang } = this.props;
    const { notifications } = translations[lang].NewContract.UploadButton;
    const errors = participants.map((user, i) => {
      let isValidUpload = true;
      /* if (user.legalRfc.length !== 13 && user.legalRfc.length !== 12) {
        NotificationManager.warning(
          notifications.notifBody2(key, i + 1),
          notifications.notifTitle2,
          5000,
        );
        isValidUpload = false;
      } */

      if (user.legalName.length < 2) {
        NotificationManager.warning(
          notifications.notifBody3(key, i + 1),
          notifications.notifTitle3,
          5000,
        );
        isValidUpload = false;
      }

      if (!user.email.match(emailRegex)) {
        NotificationManager.warning(
          notifications.notifBody4(key, i + 1),
          notifications.notifTitle4,
          5000,
        );
        isValidUpload = false;
      }
      if (
        key === 'firmante' &&
        activeButtonUploadPdf &&
        !user.hasOwnProperty('signType')
      ) {
        NotificationManager.warning(
          notifications.notifBody5(key, i + 1),
          notifications.notifTitle5,
          5000,
        );
        isValidUpload = false;
      }
      return isValidUpload;
    });
    return errors;
  };

  addRecipientIsNotParticipant = () => {
    const { checkAuthorizers, authorizers, signers, recipients, contractUser } =
      this.props;
    //VER SI ESTA DENTRO DE LOS AUTORIZADORES
    if (checkAuthorizers) {
      const allUsers = [...authorizers, ...recipients];
      const userId = contractUser.rootUser || contractUser.id;
      const findUser = allUsers.find((user) => user.userId === userId);
      return this.addRecipient(findUser, 'autorizadores');
    }

    //VER SI ESTA DENTRO DE LOS FIRMANTES
    const allUsers = [...signers, ...recipients];
    const userId = contractUser.rootUser || contractUser.id;
    const findSigner = allUsers.find((user) => user.userId === userId);

    return this.addRecipient(findSigner, 'firmantes');
  };

  addRecipient = (user, typeUser) => {
    const {
      recipients,
      updateStateNewContract,
      contractUser,
      translations,
      lang,
    } = this.props;
    const { legalRfc, legalName } = contractUser;

    const { notifications } = translations[lang].NewContract.UploadButton;

    if (!user) {
      NotificationManager.info(
        notifications.notifBody6(typeUser),
        notifications.notifTitle6,
        4000,
      );
      return updateStateNewContract({
        recipients: [
          ...recipients,
          {
            legalRfc,
            legalName,
            email: contractUser.secondaryEmail || contractUser.email,
            userId: contractUser.rootUser || contractUser.id,
            disableLegalRfc: true,
          },
        ],
      });
    }
  };

  verifySameUsers = (participants) => {
    const participantsExist = participants.filter((user) => user.userId);
    const participantsNotExist = participants.filter((user) => !user.userId);
    const repeatedObjectsExist = participantsExist.filter(
      (participant, index, arr) =>
        arr
          .slice(index + 1)
          .some(
            (otherPart) =>
              otherPart.userId === participant.userId ||
              otherPart.legalRfc === participant.legalRfc,
          ),
    );
    const repeatedObjectsNotExist = participantsNotExist.filter(
      (participant, index, arr) =>
        arr
          .slice(index + 1)
          .some(
            (otherPart) =>
              otherPart.email === participant.email ||
              (otherPart.legalRfc &&
                otherPart.legalRfc === participant.legalRfc),
          ),
    );

    const sameUsers = [...repeatedObjectsExist, ...repeatedObjectsNotExist];
    return sameUsers;
  };

  canUpload = () => {
    const { authorizers, signers, title, file } = this.props;
    return !authorizers.length || !signers.length || !title || !file;
  };

  render() {
    const { translations, lang } = this.props;
    const { buttons } = translations[lang].NewContract.UploadButton;
    return (
      <button
        type="button"
        className="primary"
        onClick={this.showModal}
        disabled={this.canUpload()}
        style={{ marginTop: 20 }}
      >
        {buttons.button1}
      </button>
    );
  }
}

export default branch(
  {
    session: ['session'],
    translations: ['translations'],
    lang: ['lang'],
  },
  UploadButtonDisplay,
);
