import ContractActions from '../actions/Contract';

export function validateIcon(contract) {
  if (contract.allPartiesSigned && contract.status === 'ready') {
    return <i className="fas fa-check-circle" style={{ color: '#7ED321' }} />;
  }
  if (contract.status === 'waitingOthers') {
    return <i className="far fa-clock" style={{ color: '#e19614' }} />;
  }
  if (contract.status === 'byMe') {
    return <i className="fas fa-check" style={{ color: '#7ED321' }} />;
  }
  if (contract.status === 'copied') {
    return <i className="far fa-copy" style={{ color: '#6C6C6C' }} />;
  }
  if (contract.is_valid === 0 && contract.id !== '0') {
    return <i className="fas fa-ban" style={{ color: '#D0021B' }} />;
  }

  if (contract.status === 'authPending') {
    return <i className="far fa-flag" style={{ color: '#ffb432' }} />;
  }

  if (contract.status === 'noAuthorized') {
    return <i className="far fa-flag" style={{ color: '#f95c5e' }} />;
  }
}

export const redirectTo = async (
  contractId,
  status,
  history,
  user,
  contract,
  signerOrAdmin,
  actualPage,
) => {
  // TRAER FIRMANTES Y SIGNERS para comparar que es!!!
  try {
    const recipients = await ContractActions.getThisRecipients(contractId);
    const { authorizer } = await ContractActions.getAuthorizers(contractId);
    localStorage.setItem('currentPage', actualPage);
    if (signerOrAdmin) {
      return history.push({
        pathname: `/public/contracts/${contractId}`,
        state: {
          from: '/contratos',
          admin: signerOrAdmin,
        },
      });
    }

    if (user.id === contract.updatedBy) {
      return history.push({
        pathname: `/public/contracts/${contractId}`,
        state: {
          from: '/contratos',
        },
      });
    }

    if (authorizer.length > 0) {
      if (authorizer[0].signed === 0 || authorizer[0].noSigned === 0) {
        return history.push({
          pathname: `/contratos/${contractId}`,
          state: {
            from: '/contratos',
          },
        });
      }
    }

    if (recipients.length <= 0) {
      return history.push({
        pathname: `/contratos/${contractId}`,
        state: {
          from: '/contratos',
        },
      });
    }
    const recipient = recipients.map((rec) => rec.userId);
    if (!recipient.includes(user.id)) {
      return history.push({
        pathname: `/contratos/${contractId}`,
        state: {
          from: '/contratos',
        },
      });
    }
    history.push({
      pathname: `/public/contracts/${contractId}`,
      state: {
        from: '/contratos',
      },
    });
    // });
  } catch (error) {
    // TODO handle error
    console.log(error);
  }
};

export const inputCheckbox = (contract, user, selectContractToSign) => {
  const { status, signType, id, isActive } = contract;

  if (
    !user.typeUser &&
    status === 'forMe' &&
    (signType === '1' || signType === '2')
  ) {
    if (
      (contract.requestDocument && !contract.allDocsUploaded) ||
      (contract.requestVerification && !contract.verified)
    ) {
      return null;
    }

    return (
      <input
        type="checkbox"
        name="check"
        key={id}
        id={`myCheck${id}`}
        onChange={(e) => selectContractToSign(e, contract)}
        checked={isActive}
        value={id}
      />
    );
  }

  if (user.typeUser === 'sife' && status === 'forMe' && signType === '2') {
    if (
      (contract.requestDocument && !contract.allDocsUploaded) ||
      (contract.requestVerification && !contract.verified)
    ) {
      return null;
    }
    return (
      <input
        type="checkbox"
        name="check"
        key={id}
        id={`myCheck${id}`}
        onChange={(e) => selectContractToSign(e, contract)}
        defaultChecked={isActive}
        value={id}
      />
    );
  }
};
