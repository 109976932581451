import React from 'react';
import { branch } from 'baobab-react/higher-order';
import Checkbox from 'react-simple-checkbox';
import { myStatus } from '../../utils/constants';
import ContractActions from '../../actions/Contract';
import { NotificationManager } from 'react-notifications';

class TableContracts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labelsSelected: [],
      currentStatus: null,
    };
  }

  async componentDidMount() {}

  noRows = (type) => {
    const { updateState } = this.props;
    updateState({
      contracts: [
        {
          id: 0,
          title: 'La búsqueda no arrojó resultados',
          legalName: '',
          validUntil: null,
          createdAt: null,
          status: null,
        },
      ],
      ...(type === 'search' && { labelsFilter: [], statusFilter: null }),
      ...(type === 'labels' && { searchFiter: '', statusFilter: null }),
      ...(type === 'filterStatus' && { labelsFilter: [], searchFiter: '' }),
    });
  };

  filterList = (event) => {
    const { value } = event.target;
    const { globalContracts, updateState } = this.props;
    const searchContracts = [...globalContracts];
    const lastChar = value[value.length - 1];
    if (lastChar === '\\') return;
    const updatedList = searchContracts.filter(
      (contract) =>
        contract?.title?.toLowerCase().search(value.toLowerCase()) !== -1 ||
        contract?.legalName?.toLowerCase().search(value.toLowerCase()) !== -1 ||
        contract?.id?.toString().search(value) !== -1,
    );

    this.setState({ labelsSelected: [], currentStatus: null });
    if (!updatedList?.length) {
      return this.noRows('search');
    }

    updateState({
      contracts: updatedList,
      searchFilter: value,
      labelsFilter: [],
      statusFilter: null,
    });
  };

  selectLabel = (label) => {
    const { updateState } = this.props;
    const { labelsSelected } = this.state;
    const selectedLabels = [...labelsSelected];
    const index = selectedLabels.findIndex(
      (selectedLabel) => selectedLabel.content === label.content,
    );

    if (index === -1) {
      // Si la etiqueta no estaba seleccionada, agregarla
      selectedLabels.push(label);
      this.setState({ labelsSelected: selectedLabels, currentStatus: null });
    } else {
      // Si la etiqueta ya estaba seleccionada, quitarla
      selectedLabels.splice(index, 1);
      this.setState({ labelsSelected: selectedLabels, currentStatus: null });
    }

    // Filtrar los contratos que coinciden con las etiquetas seleccionadas
    const filteredContracts = this.filterLabel(selectedLabels);

    if (!filteredContracts?.length) {
      return this.noRows('labels');
    }

    // Mostrar los contratos filtrados
    updateState({
      contracts: filteredContracts,
      labelsFilter: selectedLabels,
      searchFiter: '',
      statusFilter: null,
    });
  };

  filterLabel = (selectedLabels) => {
    const { globalContracts, labels } = this.props;

    if (!selectedLabels.length) {
      return globalContracts;
    }

    // Si hay etiquetas seleccionadas, filtrar los contratos que coinciden
    const filteredContracts = globalContracts.filter((contract) => {
      // Obtener las etiquetas correspondientes al contrato actual
      const contractLabels = labels.filter(
        (label) => label.contract === contract.id,
      );
      // Verificar si todas las etiquetas seleccionadas coinciden con las del contrato
      return selectedLabels.every((selectedLabel) =>
        contractLabels.some(
          (contractLabel) => contractLabel.content === selectedLabel.content,
        ),
      );
    });

    return filteredContracts;
  };

  /**
   * Filter by contract status
   */
  filterStatus = (checked, status) => {
    const { globalContracts, updateState } = this.props;

    if (!checked) {
      this.setState({ currentStatus: null, labelsSelected: [] });
      updateState({
        contracts: globalContracts,
        filteredContracts: [],
        statusFilter: null,
      });
      return;
    }

    const newList = globalContracts.filter(
      (contract) => contract.status === status.key,
    );
    this.setState({ currentStatus: status, labelsSelected: [] });
    if (!newList?.length) {
      return this.noRows('filterStatus');
    }

    updateState({
      contracts: newList,
      statusFilter: status,
      labelsFilter: [],
      searchFiter: '',
    });
  };

  resetAllDownloads = async () => {
    const { signer, globalContracts, contracts, updateState } = this.props;
    const response = await ContractActions.resetAllDownloads(signer);
    if (response.data.status) {
      return NotificationManager.error(response.data.message, 'Error', 10000);
    }

    const copiedGlobalContracts = [...globalContracts];
    const copiedContracts = [...contracts];

    const copiedGlobalModified = copiedGlobalContracts.map((contract) => {
      const contractReset = { ...contract };
      contractReset.downloadedPdf = false;
      return contractReset;
    });

    const contractsModified = copiedContracts.map((contract) => {
      const contractReset = { ...contract };
      contractReset.downloadedPdf = false;
      return contractReset;
    });

    updateState({
      globalContracts: copiedGlobalModified,
      contracts: contractsModified,
    });
    return NotificationManager.success(response.data.message, 'Éxito', 10000);
  };

  render() {
    const { myLabels, lang, translations } = this.props;
    const { labelsSelected, currentStatus } = this.state;
    const { texts, inputs } = translations[lang].Contracts.FilterToolbar;

    return (
      <>
        <div className="filter search_contract">
          <input
            type="text"
            placeholder={inputs.inputPlaceholder1}
            className="search"
            onChange={this.filterList}
          />
        </div>

        <div className="filter filter_label">
          <label className="label-title">
            <i className="fas fa-tags" />
            {texts.labelTitle1}
          </label>
          <ul>
            {myLabels.length ? (
              myLabels.map((label) => (
                <li key={label.id}>
                  <Checkbox
                    backAnimationDuration={50}
                    tickAnimationDuration={200}
                    color={label.color}
                    size="3"
                    tickSize="1"
                    borderThickness="2"
                    id={label.id}
                    checked={labelsSelected?.some(
                      (selected) => selected.id === label.id,
                    )}
                    onChange={() => this.selectLabel(label)}
                  />
                  <label className="label-text" htmlFor={label.id}>
                    {label.content}
                  </label>
                </li>
              ))
            ) : (
              <li>
                <label className="label-text">{texts.labelText1}</label>
              </li>
            )}
          </ul>
        </div>

        <div className="filter filter_status">
          <label className="label-title">
            <i className="fas fa-check-double" />
            {texts.labelTitle2}
          </label>
          <ul>
            {myStatus.map((stat, i) => (
              <li key={stat.key}>
                <Checkbox
                  backAnimationDuration={50}
                  tickAnimationDuration={200}
                  color="#6C6C6C"
                  size="3"
                  tickSize="1"
                  borderThickness="2"
                  id={stat.key}
                  checked={Boolean(currentStatus?.key === stat?.key)}
                  onChange={(e) => this.filterStatus(e, stat)}
                  className="mtm-5"
                />
                <label className="label-text" htmlFor={stat.key}>
                  <i className={stat.icon} />
                  &nbsp; {texts[`li${i + 1}`]}
                </label>
              </li>
            ))}
          </ul>
        </div>

        <div className="filter reset_contracts tooltip top">
          <label className="tiptext">{texts.labelTooltip1}</label>
          <label
            className="label-title"
            style={{ cursor: 'pointer' }}
            onClick={() => this.resetAllDownloads()}
          >
            <i className="fas fa-check-double mr-5" />
            {texts.labelTitle3}
          </label>
        </div>
      </>
    );
  }
}

export default branch(
  {
    session: ['session'],
    labels: ['labels'],
    translations: ['translations'],
    lang: ['lang'],
  },
  TableContracts,
);
