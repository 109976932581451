import moment from 'moment-timezone';
import state from '../state';
import { contractsServices } from '../API/services/contractServices';

const ContractActions = {
  /**
   * Create a new contract
   * @param  {Object} properties Contract properties
   * @return {Promise}
   */
  async create(properties) {
    const formData = new FormData();
    formData.append('file', properties.file);

    const { data: fileData } = await contractsServices.fileContractService(
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    const { data: createData } = await contractsServices.createContractService({
      ...properties,
      fileUrl: fileData.Location,
      hash: fileData.hash,
    });
    state.select(['contracts']).push(createData);
    return createData;
  },

  // CREATE CONTRACT SIFE
  async createContractSife(data) {
    const { contract, user, type, locationUser, signer } = data;
    const email = type === 'sign' ? user.secondaryEmail : user.email;
    const rfc = user.legalRfc;
    const rfcUser = user.legalRfc ? true : false;
    const userType = user.typeUser ? user.typeUser : 'efirma';
    const docsArray = [
      {
        docObjToHash: {
          docInfo: {
            fechaInicio: moment(contract.createdAt).format('YYYY-MM-DD'),
            fechaFinal: moment(contract.validUntil).format('YYYY-MM-DD'),
            monto: '5000',
          },
        },
        docHash: contract.lastHash || contract.hash,
        docId: contract.unique_id,
        docName: contract.title,
        userEmail: user.secondaryEmail,
        ...(user.legalRfc && { userRFC: user.legalRfc }),
        // userRFC: user.legalRfc,
        hashAlgorithm: 'SHA256',
        //este va como true al menos que el usuario pida que no se haga
        verifReqAtSigCreation: signer.verifyCreateSign,
        verifReqAtDocSigning: signer.requestVerification,
        geolocReqAtDocSigning: false,
      },
    ];
    const payload = {
      docsArray,
      userType,
      rfcUser,
      email,
      rfc,
      locationUser,
      userIdToUpdate: user.id,
      originalContractId: contract.id,
    };
    const response = await contractsServices.createContractSifeService(payload);
    return response;
  },

  async getContractSife(contractId) {
    const response = await contractsServices.getContractSifeService(
      {},
      { contractId },
    );
    return response;
  },

  async createContractSignerSife(contract, user, userSigner) {
    const nameSplit = contract.fileUrl.split('/');
    const name = `${nameSplit[nameSplit.length - 1]}`;
    const email = user.email;
    const rfc = user.legalRfc;
    const rfcUser = user.legalRfc ? true : false;
    const userType = user.typeUser ? user.typeUser : 'efirma';
    const docsArray = [
      {
        docObjToHash: {
          docInfo: {
            fechaInicio: moment(userSigner.createdAt).format('YYYY-MM-DD'),
            fechaFinal: moment(userSigner.createdAt).format('YYYY-MM-DD'),
            monto: '5000',
          },
        },
        docHash: userSigner.hash,
        docId: userSigner.unique_id,
        docName: name,
        userEmail: user.email,
        ...(user.legalRfc && { userRFC: user.legalRfc }),
        hashAlgorithm: 'SHA256',
        geolocReqAtDocSigning: false,
      },
    ];
    const payload = {
      docsArray,
      userType,
      rfcUser,
      email,
      rfc,
    };
    const response = await contractsServices.createContractSignerSifeService(
      payload,
    );
    return response;
  },

  /**
   * Get all Contracts from my User
   * @returns {Promise<*>}
   */
  async list({ id }) {
    const { data } = await contractsServices.listContractsService(
      {},
      { userId: id },
    );
    // state.select(['contracts']).set(data);
    return data;
  },

  /**
   * View a contract by Id
   * @param id
   * @returns {Promise<*>}
   */
  async view(id) {
    const { data } = await contractsServices.viewContractService({}, { id });
    state.select(['contract']).set(data);
    return data;
  },

  async updateSigner(signers, contract, signer, dataToUpdate, typeOfUpdate) {
    const { data } = await contractsServices.updateSignerService({
      signers,
      contract,
      signer,
      dataToUpdate,
      typeOfUpdate,
    });
    return data;
  },

  async listContractAuthorizers() {
    const { data } = await contractsServices.listContractAuthorizerService();
    return data;
  },

  async updateAuthorizerContract(payload) {
    const { data } = await contractsServices.updateAuthorizerContractService(
      payload,
    );
    return data;
  },

  async getAuthorizers(contractId) {
    const { data } = await contractsServices.getAuthorizerService(
      {},
      { contractId },
    );
    return data;
  },

  /**
   * @param signer
   * @param user
   * @param contract
   * @returns {Promise}
   */
  async changeSigner(signer, user, contract) {
    const payload = {
      signer,
      user,
      contract,
    };
    const { data } = await contractsServices.changeSignerService(payload);
    return data;
  },

  /**
   * Get labels for this contract
   * @returns {Promise<*>}
   */
  async getThisLabels(id) {
    const { data } = await contractsServices.getThisLabelsService({}, { id });
    return data;
  },

  /**
   * Get signers for this contract
   * @returns {Promise<*>}
   */
  async getThisSigners(id) {
    const { data } = await contractsServices.getThisSignersService({}, { id });
    return data;
  },

  /**
   *
   * @param id
   * @returns {Promise<*>}
   */
  async getThisRecipients(id) {
    const { data } = await contractsServices.getThisRecipientsService(
      {},
      { id },
    );
    return data;
  },

  /**
   * Search a user by RFC
   * @param signer
   * @returns {Promise<*>}
   */
  async searchByRfc(signer) {
    const { legalRfc } = signer;
    const { data } = await contractsServices.searchByRfcService(
      {},
      { legalRfc },
    );
    return data;
  },

  /**
   * Sign with State user
   * @param contract
   * @param credentials
   * @param user
   * @param allPartiesSigned
   * @param cuteSign
   * @returns {Promise<*>}
   */
  async sign(info) {
    const { contract, credentials, user, cuteSign, locationUser } = info;
    const { id } = contract;
    const formData = new FormData();
    formData.append('cer', credentials.cer);
    formData.append('pdf', contract.fileUrl);
    formData.append('legalName', user.legalName);
    formData.append('legalRfc', user.legalRfc);
    if (user.representing) {
      formData.append('representing', user.representing);
    }
    if (user.representedRfc) {
      formData.append('representedRfc', user.representedRfc);
    }
    formData.append('cuteSign', cuteSign);
    formData.append('locationUser', JSON.stringify(locationUser));

    const { data } = await contractsServices.signContractService(
      formData, // data
      {
        // rqConfig
        headers: { 'Content-Type': 'multipart/form-data' },
      },
      { id }, // urlVar
    );
    return data;
  },

  async softDeleteContract(id, user) {
    const { data } = await contractsServices.softDeleteContractService(
      id,
      {},
      { id },
    );
    return data;
  },

  async cancelContract(id) {
    const { data } = await contractsServices.cancelContractService(
      id,
      {},
      { id },
    );
    return data;
  },

  async getAllContractsMonthly(date) {
    const { data } = await contractsServices.getAllContractsMonthlyService(
      date,
      {},
      { date },
    );
    return data;
  },

  async sendEmailToUserWhenSignMassive(user, contracts, type) {
    const data = {
      user,
      contracts,
      type,
    };
    const response =
      await contractsServices.sendEmailToUserWhenSignMassiveService(data);
    return response;
  },

  async massiveDownloadFiles(contracts, signer, notCheck) {
    const data = { contracts, signer, notCheck };
    const response = await contractsServices.massiveDownloadFilesService(data);
    return response;
  },

  async resetAllDownloads(signerOrAdmin) {
    const response = await contractsServices.resetAllDownloadsService(
      signerOrAdmin,
    );
    return response;
  },

  async downloadPdfByDate(data) {
    const response = await contractsServices.downloadPdfByDateService(data);
    return response;
  },

  async getContractsPendingToDownload(data) {
    const response =
      await contractsServices.getContractsPendingToDownloadService(data);
    return response;
  },

  async uploadIdentityDocuments(formData) {
    const response = await contractsServices.uploadIdentityDocumentsService(
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response;
  },

  async verifyFrontId(payload) {
    const response = await contractsServices.verifyFrontIdService(payload);
    return response;
  },

  async verifyBackId(payload) {
    const response = await contractsServices.verifyBackIdService(payload);
    return response;
  },

  async verifySelfie(payload) {
    const response = await contractsServices.verifySelfieService(payload);
    return response;
  },

  async verifyFacesMatch(payload) {
    const response = await contractsServices.verifyFacesMatchService(payload);
    return response;
  },

  async getUserSife(userId) {
    const { data } = await contractsServices.getUserSifeService({}, { userId });
    return data;
  },

  async getInfoVerificationSife({ docId, userId, type }) {
    const { data } = await contractsServices.getInfoVerificationSifeService(
      { params: { docId, type } },
      { userId },
    );
    return data;
  },

  async generateSoraId(payload) {
    const response = await contractsServices.generateSoraIdService(payload);
    return response;
  },

  async updateUserSoraId(payload) {
    const response = await contractsServices.updateUserSoraIdService(payload);
    return response;
  },
  async uploadContractsMassive(formData) {
    const response = await contractsServices.uploadContractsMassiveService(
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response;
  },

  async getExcelReport(data) {
    const response = await contractsServices.getExcelReportService(data);
    return response;
  },
};

export default ContractActions;
