export const UpdateSignersModalTranslations = {
  es: {
    texts: {
      h1: 'Actualiza la información de tu usuario',
      p1: 'Actualizando firmante',
      p2: 'Ingresa el rfc al que quieres redireccionar el contrato',
      p3: 'No puedes actualizar RFC y Nombre porque el usuario ya está registrado',
      span1: 'Verificar identidad cuando firme este documento',
    },
    inputs: {
      inputPlaceholder1: 'Correo *',
      inputPlaceholder2: 'Elegir usuario',
      inputPlaceholder3: 'RFC',
      inputPlaceholder4: 'Nombre *',
    },
    buttons: {
      button1: 'Cerrar',
      button2: 'Actualizar',
    },
    notifications: {
      notifTitle1: 'Atención',
      notifBody1: 'El rfc no es correcto',
      notifTitle2: 'Error',
      notifBody2: 'No pudimos obtener la información del firmante',
      notifTitle3: 'Error',
      notifBody3: 'Hubo un error al actualizar', // TODO: translate response
      notifTitle4: 'Éxito',
      notifBody4: 'Firmante actualizado con éxito',
    },
  },
  en: {
    texts: {
      h1: 'Update your user information.',
      p1: 'Updating signer',
      p2: 'Enter the rfc you want to redirect the contract to',
      p3: 'You cannot update the RFC and Name because the user is already registered.',
      span1: 'Verify identity when signing this document.',
    },
    inputs: {
      inputPlaceholder1: 'Email *',
      inputPlaceholder2: 'Select user',
      inputPlaceholder3: 'RFC',
      inputPlaceholder4: 'Name *',
    },
    buttons: {
      button1: 'Close',
      button2: 'Update',
    },
    notifications: {
      notifTitle1: 'Attention',
      notifBody1: 'Rfc is not correct',
      notifTitle2: 'Error',
      notifBody2: 'We could not get signer information',
      notifTitle3: 'Error',
      notifBody3: 'There was an error updating', // TODO: translate response
      notifTitle4: 'Success',
      notifBody4: 'Signer updated successfully',
    },
  },
};
