export const ProfileRoutes = {
  updateUser: '/profile/userInfo/update/:userId',
  userInfo: '/profile/userInfo/:userId', // get
  notifyAPIRequest: '/profile/notify-api/:userId',
  couponContract: '/profile/coupon-contract', // * post
  updateCoupon: '/profile/coupons/:couponId', // * put
  addCoupon: '/profile/coupons/assign/:code',
  deleteTemplate: '/profile/template/delete/:id',
  addTemplate: '/profile/template/new',
  forceDelete: '/profile/user/delete/:id',
  removeAdmin: '/profile/admins/delete',
  removeSigner: '/profile/signers/delete',
  addEmail: '/profile/email/add',
  signUser: '/profile/user/sign/:id', // * post
  addSignerToProfile: '/profile/signers/add', // addSigner
  coupons: '/profile/coupons/:userId',
  templates: '/profile/templates/:userId',
  getLastSigners: '/profile/last-signers/:userId',
  admins: '/profile/admins/:userId',
  signers: '/profile/signers/:userId',
  getUserProfile: '/profile/user/:id',
  addProfileImage: '/profile/image/add',
  updateSigner: '/profile/update-signer',
  updateNotifications: '/profile/update-notifications',
  downloadContractToViewProfile: '/profile/downloadContractToViewProfile/:id',
  registerUsersToDownloadContracts: '/profile/registerAuthorizedUsers',
  getMyUsers: '/profile/getMyUsers/:id',
  createContractToAuthorizeDownload:
    '/profile/createContractToAuthorizeDownload',
  sendResponseToAuthorize: 'profile/sendResponseToAuthorize',
  searchUserByData: 'profile/searchUserData',
  updateUserData: '/profile/update-user-data/:userId',
};
