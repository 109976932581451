import React from 'react';
import { NotificationContainer } from 'react-notifications';

import Nav from '../../components/Landing/Nav';
import APIActions from '../../actions/API';
import moment from 'moment';

class PublicInfo extends React.Component {
  state = {
    contract: null,
    signers: [],
    user: null,
  };

  componentDidMount = async () => {
    const {
      params: { id, document },
    } = this.props.match;
    const info = `${id};${document}`;
    const response = await APIActions.getPublicInfo(info);
    if (!response.data || !response.data.success) {
      return (window.location = '/login');
    }

    const signers = response.data.signers.map((signer) => {
      return {
        ...signer,
        hide: false,
      };
    });
    this.setState({
      contract: response.data.contract,
      signers,
      user: response.data.user,
    });
  };

  onClickHideInfoSigner = (index) => {
    const { signers } = this.state;
    const allSigners = [...signers];
    const hide = allSigners[index]['hide'];
    allSigners[index]['hide'] = !hide;
    this.setState({ signers: allSigners });
  };

  render() {
    const { contract, signers, user } = this.state;
    if (!contract || !signers.length || !user) {
      return null;
    }
    return (
      <>
        <NotificationContainer />
        <Nav />
        <div className="container_public_info">
          <div className="public_info_contract page padded">
            <div className="header_public_info">
              <h3>Información del documento</h3>
              <a
                className="xml_button"
                href={contract.xmlUrl}
                download
                target="_blank"
                rel="noreferrer"
              >
                Descargar XML
              </a>
            </div>
            <div className="details_contract">
              <div>
                <small className="title_public_info">
                  Titulo del documento
                </small>
              </div>
              <div>
                <small className="data_public_info">{contract.title}</small>
              </div>
              <div>
                <small className="title_public_info">
                  Solicitante de la firma
                </small>
              </div>
              <div>
                <small className="data_public_info">{contract.legalName}</small>
              </div>
              <div>
                <small className="title_public_info">Fecha creación</small>
              </div>
              <div>
                <small className="data_public_info">
                  {moment(contract.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                </small>
              </div>
              <div>
                <small className="title_public_info">
                  Estado del documento
                </small>
              </div>
              <div>
                <small className="data_public_info">
                  Firmado por todas las partes
                </small>
              </div>
            </div>
          </div>

          <div className="public_info_signers page padded">
            <div className="header_info_signers">
              <h3>Información de firmantes</h3>
            </div>
            <div className="container_public_signers">
              {signers?.map((signer, index) => (
                <>
                  <div className="principal">
                    <div>
                      <small className="data_public_info">
                        {signer.legalName}
                      </small>
                      <span>{signer.signed ? 'Firmado' : 'Pendiente'}</span>
                    </div>
                    <i
                      className="fas fa-arrow-down"
                      onClick={() => this.onClickHideInfoSigner(index)}
                    />
                  </div>

                  {signer.hide ? null : (
                    <div className="public_signers">
                      {user?.showRfcSignature ? (
                        <>
                          <div>
                            <small className="title_public_info">
                              {signer.legalRfc ? 'RFC' : 'Correo'}
                            </small>
                          </div>
                          <div>
                            <small className="data_public_info">
                              {signer.legalRfc ||
                                signer.secondaryEmail ||
                                signer.email}
                            </small>
                          </div>
                        </>
                      ) : null}
                      <div>
                        <small className="title_public_info">
                          Fecha de firma
                        </small>
                      </div>
                      <div>
                        <small className="data_public_info">
                          {moment(signer.signedAt).format(
                            'YYYY-MM-DD HH:mm:ss',
                          )}
                        </small>
                      </div>
                      <div>
                        <small className="title_public_info">
                          Firma Electrónica
                        </small>
                      </div>
                      <div>
                        <small className="data_public_info">
                          {signer.hash}
                        </small>
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PublicInfo;
