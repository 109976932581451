import React from 'react';
import ChooseSignatureType from '../NewContract/ChooseSignatureType';
import { branch } from 'baobab-react/higher-order';

const AddSignerDisplay = ({
  handleTableSigners,
  showTableSigners,
  signers,
  generateShortId,
  signerInputChange,
  removeSigner,
  loading,
  addSigner,
  addMe,
  session,
  handleChangeTypeSign,
  handleChangeOrderSigners,
  orderedSigners,
  updateSigner,
  translations,
  lang,
}) => {
  const { texts, inputs, buttons } =
    translations[lang].NewContractMassive.AddSignersDisplay;
  return (
    <>
      <div className="dropdown_tables">
        <span style={{ color: 'black' }}>{texts.spanTitle1}</span>
        {showTableSigners ? (
          <i className="fas fa-chevron-up" onClick={handleTableSigners}></i>
        ) : (
          <i className="fas fa-chevron-down" onClick={handleTableSigners}></i>
        )}
      </div>
      <div className="signers_nc flex_div">
        <div style={{ width: '80%' }}>
          <ChooseSignatureType
            handleChangeTypeSign={handleChangeTypeSign}
            pageType="massive"
          />
        </div>
        <div className="switch_signer">
          <label className="switch">
            <input
              type="checkbox"
              checked={orderedSigners}
              name="orderedSigners"
              onChange={handleChangeOrderSigners}
            />
            <span className="slider round"></span>
          </label>
          <span style={{ paddingLeft: 10 }}>{inputs.switchLabel1}</span>
        </div>
      </div>

      {showTableSigners ? (
        <>
          {signers.length ? (
            signers.map((signer, index) => (
              <div key={index} className="container_inputs_signers_massive">
                <div className="input_signers_massive">
                  <div className="input_legalRfc_massive">
                    <small>{index + 1}.</small>
                    <input
                      type="email"
                      name="email"
                      autoComplete="nope"
                      value={signer.secondaryEmail || signer.email}
                      className="input_nc email_massive"
                      onChange={signerInputChange.bind(this, index)}
                      disabled={loading}
                      required
                      placeholder="email"
                    />
                  </div>
                  {signer?.disclaimerMultipleUsers ? (
                    <div className="select_display legalName_massive">
                      <select
                        name="signer"
                        onChange={(event) =>
                          updateSigner(
                            signer?.sameUsers[event.target.selectedIndex - 1],
                            index,
                            'signers',
                          )
                        }
                        autoComplete="nope"
                        className="input_nc legalName_massive"
                        // disabled={!this.disabledButton(localSigner)}
                      >
                        <option
                          value=""
                          disabled
                          selected={!Boolean(signer.userId)}
                        >
                          {texts.option1}
                        </option>
                        {signer?.sameUsers.map((user, i) => (
                          <option
                            key={i}
                            value={JSON.stringify(user)}
                            selected={signer.userId === user.id}
                          >
                            {user.legalName} - {user.legalRfc}
                          </option>
                        ))}
                      </select>
                      <i
                        className="fas fa-angle-down	"
                        style={{ color: 'black' }}
                      />
                    </div>
                  ) : (
                    <input
                      type="text"
                      name="legalName"
                      value={signer.legalName}
                      className="input_nc legalName_massive"
                      style={{ textTransform: 'capitalize' }}
                      required
                      maxLength="100"
                      minLength="2"
                      onChange={signerInputChange.bind(this, index)}
                      disabled={loading}
                      placeholder="Nombre"
                    />
                  )}
                </div>
                <div className="actions_massive">
                  <i
                    className={`fas fa-minus-circle ${loading ? 'hidden' : ''}`}
                    style={{
                      color: '#f95c5e',
                      cursor: 'pointer',
                    }}
                    onClick={() => removeSigner(signer)}
                  />
                </div>
              </div>
            ))
          ) : (
            <p>{texts.p1}</p>
          )}
          <div className="actions_signers">
            {!signers.find(
              (found) => found.legalRfc === session.user.legalRfc,
            ) ? (
              <div className="action_signer">
                <span onClick={addMe}>{buttons.button1}</span>
              </div>
            ) : null}
            <div className="action_signer">
              <span onClick={addSigner}>{buttons.button2}</span>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  AddSignerDisplay,
);
