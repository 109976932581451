import React from 'react';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import { Redirect } from 'react-router-dom';
import SessionActions from '../../actions/Session';
import Loader from '../../components/Loader';
import Nav from '../../components/Landing/Nav';
import { branch } from 'baobab-react/higher-order';
import Checkbox from 'react-simple-checkbox';

class Activation extends React.Component {
  state = {
    canRespond: true,
    loading: false,
    redirecting: false,
    redirect: false,
    checked: false,
    otp: '',
  };

  handleCheckbox = (checked) => {
    this.setState({ checked: !checked });
  };

  handleInputChange = (e) => {
    const { value } = e.target;
    this.setState({ otp: value.replace(/ /g, '') });
  };

  resendActivation = async () => {
    try {
      const { match } = this.props;
      const { legalRfc } = match.params;
      const resendEmail = true;
      const response = await SessionActions.activate(legalRfc, '', resendEmail);

      if (response?.error) {
        return NotificationManager.error(
          response?.error || 'No se pudo enviar el código de verificación',
          'Error',
          10000,
        );
      }
      NotificationManager.success(
        response?.message || 'Código de verificación enviado correctamente',
        'Activación',
        10000,
      );

      //setTimeout(() => this.props.history.push('/first-login'), 3000);
    } catch (error) {
      console.log(error);
      return NotificationManager.warning(
        'No se pudo enviar el código de verificación',
        'Advertencia',
        10000,
      );
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { otp } = this.state;
    const { match } = this.props;
    const { legalRfc } = match.params;
    this.setState({ loading: true });
    try {
      if (!otp) {
        return NotificationManager.warning(
          'El código de verificación no puede ir vacío',
          'Error',
          5000,
        );
      }
      const response = await SessionActions.activate(legalRfc, otp);

      if (response?.error) {
        this.setState({ redirecting: false, loading: false });
        return NotificationManager.error(
          response?.error || 'No se pudo activar la cuenta',
          'Error',
          10000,
        );
      }
      NotificationManager.success(
        response?.message || 'Cuenta activada correctamente',
        'Activación',
        10000,
      );
      setTimeout(() => this.setState({ redirect: true, loading: false }), 2000);
      //           setTimeout(() => this.props.history.push('/first-login'), 3000);
    } catch (error) {
      console.log(error);
      return NotificationManager.warning(
        'No se pudo activar la cuenta',
        'Advertencia',
        10000,
      );
    }
  };

  render() {
    const { loading, redirecting, redirect, otp } = this.state;

    const { match, translations, lang } = this.props;
    const { texts, inputs, buttons } = translations[lang].Activation;

    return (
      <>
        <NotificationContainer />
        <Nav />
        <div className="container_landing">
          <div className="login_wrapper register_landing">
            <div className="login_form redesign">
              <div className="inputs_login_form">
                <ol>
                  <li>
                    <i>
                      <u>{texts.ol1_li1_u1}</u>
                    </i>
                    &nbsp;{texts.ol1_li1_1}
                  </li>
                  <li>
                    {texts.text1}
                    <i>
                      <u> {texts.text2}</u>
                    </i>{' '}
                    {texts.text3}
                  </li>
                  <li>
                    {texts.ol1_li3_1}
                    <i>
                      <u>{texts.ol1_li3_u1}</u>
                    </i>
                    {texts.ol1_li3_2}
                  </li>
                </ol>
                {redirecting || loading ? (
                  <div className="center_loading">
                    <Loader />
                    <p>{texts.p1}</p>
                  </div>
                ) : (
                  <div className="activate_checkbox_landing">
                    <label htmlFor="canActivate" className="label_nc ">
                      {inputs.checkboxLabel1(match.params.legalRfc)}
                    </label>
                    <input
                      type="text"
                      name="otp"
                      value={otp}
                      className="input_nc"
                      required
                      maxLength="100"
                      minLength="2"
                      onChange={this.handleInputChange}
                      placeholder="Código de verificación"
                    />
                    <small>
                      {texts.small1}{' '}
                      <strong
                        style={{ cursor: 'pointer' }}
                        onClick={this.resendActivation}
                      >
                        {texts.small2}
                      </strong>
                    </small>
                  </div>
                )}

                <button
                  hidden={redirecting}
                  disabled={!Boolean(otp)}
                  type="button"
                  className="primary"
                  onClick={this.handleSubmit}
                >
                  {buttons.button1}
                </button>

                {redirect ? (
                  <Redirect
                    to={{
                      pathname: '/first-login',
                      state: {
                        from: '/activate',
                      },
                    }}
                  />
                ) : null}
              </div>
            </div>
            <div className="info_login_form redesign">
              <div className="container_info_login">
                <h1>{texts.h1}</h1>
                <div className="message_register">
                  <h2>{texts.h2}</h2>
                  <i className="fas fa-chevron-right fa-3x animated shake" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  Activation,
);
