const SignatureTranslations = {
  es: {
    texts: {
      error1:
        'Este no es un certificado publico, si no tienes e.firma tramitala en el SAT',
      error2: 'El archivo .key no es una llave privada',
      error3: 'RFC No tiene permitido firmar',
      error4: 'Contraseña incorrecta',
      error5: '.cer & .key son diferentes',
    },
  },
  en: {
    texts: {
      error1:
        'This is not a public certificate, if you do not have e.signature apply for it at the SAT',
      error2: 'The .key file is not a private key',
      error3: 'RFC is not allowed to sign',
      error4: 'Incorrect password',
      error5: '.cer & .key are different',
    },
  },
};

export const UtilsTranslations = {
  es: {
    texts: {
      status1: 'Firmado por todas las partes',
      status2: 'Listo para mi firma',
      status3: 'Contrato con copia',
      status4: 'Esperando otros firmantes',
      status5: 'Cancelado',
      status6: 'Esperando autorización',
      status7: 'Firmado por mi',
      status8: 'No autorizado',
    },
    Signature: { ...SignatureTranslations.es },
  },
  en: {
    texts: {
      status1: 'Signed by all parties',
      status2: 'Ready for my signature',
      status3: 'Contract with copy',
      status4: 'Waiting for other signers',
      status5: 'Cancelled',
      status6: 'Waiting for authorization',
      status7: 'Signed by me',
      status8: 'No authorized',
    },
    Signature: { ...SignatureTranslations.en },
  },
};
