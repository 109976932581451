import React from 'react';
import GenericConfirmModal from '../../components/Modals/GenericConfirmModal';
import { branch } from 'baobab-react/higher-order';

const InfoSignersSife = ({
  showVerificationButtons,
  signer,
  signerCheckboxChange,
  handleCancelPopUp,
  acceptDisclaimer,
  index,
  showTooltip,
  translations,
  lang,
}) => {
  const { texts, inputs, buttons } =
    translations[lang].NewContract.Signers.InfoSignersSife;

  return (
    <>
      {showVerificationButtons(signer) ? (
        <div className="checkbox_nc">
          <div className="checkbox_verification_signer">
            {signer.hideButtonCreateSign ? (
              <div className="verifyCreateSign">
                <div className="input_checkbox">
                  <input
                    type="checkbox"
                    id="cbox2"
                    checked={signer.verifyCreateSign}
                    name="verifyCreateSign"
                    onChange={(e) => signerCheckboxChange(index, e)}
                  />
                </div>
                <div className="tooltip_checkbox">
                  <span
                    onMouseEnter={(e) => showTooltip(e, 'createSign', 'enter')}
                    onMouseLeave={(e) => showTooltip(e, 'createSign', 'leave')}
                  >
                    {inputs.checkboxLabel2}
                  </span>
                </div>
              </div>
            ) : null}
            <div className="requestVerification">
              <div className="input_checkbox">
                <input
                  type="checkbox"
                  id="cbox2"
                  checked={signer.requestVerification}
                  name="requestVerification"
                  onChange={(e) => signerCheckboxChange(index, e)}
                />
              </div>
              <div className="tooltip_checkbox">
                <span
                  onMouseEnter={(e) => showTooltip(e, 'verification', 'enter')}
                  onMouseLeave={(e) => showTooltip(e, 'verification', 'leave')}
                >
                  {inputs.checkboxLabel1}
                </span>
              </div>
            </div>
          </div>
          {/* {showTooltipReqVer ? (
            <Tooltip
              title="Marca esta casilla para añadir seguridad extra."
              info="La verificación de identidad es la evidencia
                              de que el usuario es quien firma. Se le
                              solicita una captura de su selfie y su
                              identificación oficial"
              textCheckbox=""
              showTooltipReqVer={showTooltipReqVer}
            />
          ) : null} */}
          {/* {showTooltipReqCreateSign ? (
            <Tooltip
              title="Al ser un usuario nuevo, se recomienda
                                dejar marcada esta casilla para que
                                verifique su identidad al crear su firma."
              info="La verificación de identidad es la evidencia
                                de que el usuario es quien firma. Se le
                                solicita una captura de su selfie y de su ID
                                oficial."
              showTooltipReqCreateSign={showTooltipReqCreateSign}
            />
          ) : null} */}
        </div>
      ) : (
        <div className="checkbox_nc">
          <div className="checkbox_verification_signer">
            <div className="requestVerification">
              <div className="input_checkbox">
                <input
                  type="checkbox"
                  id="cbox2"
                  checked={signer.requestVerification}
                  name="requestVerification"
                  onChange={(e) => signerCheckboxChange(index, e)}
                />
              </div>
              <div className="tooltip_checkbox">
                <span
                  onMouseEnter={(e) => showTooltip(e, 'verification', 'enter')}
                  onMouseLeave={(e) => showTooltip(e, 'verification', 'leave')}
                >
                  {inputs.checkboxLabel1}
                </span>
              </div>
            </div>
          </div>
          {/* <Tooltip
            title="Marca esta casilla para añadir seguridad extra."
            info="La verificación de identidad es la evidencia
                              de que el usuario es quien firma. Se le
                              solicita una captura de su selfie y su
                              identificación oficial"
            textCheckbox=""
            showTooltipReqVer={showTooltipReqVer}
          /> */}
        </div>
      )}
      {signer.showModal ? (
        <GenericConfirmModal
          title={texts.genericModalTitle1}
          message={texts.genericModalBody1}
          textInfo={texts.genericModalInfo1}
          okButton={buttons.genericModalConfirmButton1}
          cancelButton={buttons.genericModalCancelButton1}
          cancelAction={() => handleCancelPopUp(index)}
          acceptAction={() => acceptDisclaimer(index)}
        />
      ) : null}
    </>
  );
};

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  InfoSignersSife,
);
