import React, { Fragment } from 'react';
import { branch } from 'baobab-react/higher-order';

class CopiedListDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  canEditRfc = (localSigner) => {
    const { admin, session, contract, allAuthorized } = this.props;
    const id = admin?.rootUser || session.user.id;

    if (!allAuthorized) {
      return false;
    }

    if (Boolean(localSigner.signed) || Boolean(localSigner.allDocsUploaded))
      return false;

    return (
      !Boolean(localSigner.signed) &&
      (contract.createdBy === id || contract.rootUser === id)
    );
  };

  render() {
    const { recipients, openModalToUpdateRfc, lang, translations } = this.props;
    const { texts } = translations[lang].ContractDetail.CopiedListDisplay;

    return (
      <div className="recipients_cd">
        <span>{texts.spanTitle}</span>
        {recipients.length ? (
          <div className="table_list_recipient_cd">
            {recipients.map((recipient, i) => (
              <Fragment key={i}>
                <div className="recipient_show_cd">
                  <p className="status_turn_copied">{i + 1} </p>
                  {/* <p className="legalRfc_copied wrap_text">
                  {recipient?.legalRfc || ''}
                </p> */}
                  {this.canEditRfc(recipient) ? (
                    <div className="secondaryEmail_copied flex_edit">
                      <p className="wrap_text">
                        {recipient?.secondaryEmail || recipient?.email}
                        <br></br>
                        <small>{recipient?.legalRfc}</small>
                      </p>
                      <i
                        className="fas fa-pen"
                        onClick={() =>
                          openModalToUpdateRfc(recipient, 'recipients')
                        }
                      />
                    </div>
                  ) : (
                    <p className="secondaryEmail_copied wrap_text">
                      {recipient?.secondaryEmail || recipient?.email}
                      <br></br>
                      <small>{recipient?.legalRfc}</small>
                    </p>
                  )}
                  <p className="legalName_copied wrap_text">
                    {recipient.legalName}
                  </p>
                </div>
              </Fragment>
            ))}
          </div>
        ) : (
          <p className="no_results">{texts.p1}</p>
        )}
      </div>
    );
  }
}
export default branch(
  {
    session: ['session'],
    contractUrl: ['contractUrl'],
    translations: ['translations'],
    lang: ['lang'],
  },
  CopiedListDisplay,
);
