import { branch } from 'baobab-react/higher-order';
import React from 'react';
import { NotificationManager } from 'react-notifications';
import AddendumActions from '../actions/AddendumActions';
import ContractActions from '../actions/Contract';
import { sifeAuthOptions, sifeAuthUrl } from '../API/instance';
import { renderStatus } from '../helpers/ContractAndPublicDetailHelpers';
import Signature from '../utils/signature';
import ViewPdf from '../containers/_common/ViewPdf';
import CerFields from '../containers/_common/CerFields';
// import { massiveDownloadFilesUtils } from '../utils/contractsContainerUtils';
// import { deviceUtil } from '../utils/deviceUtil';

class ContractPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signerSignType: '',
      loading: false,
      openNewViewInputs: false,
    };
  }

  componentDidMount = () => {
    const { signer } = this.props;
    const signerSignType = this.getTypeSignUser(signer.signType);
    this.setState({ signerSignType });
  };

  //Obtiene con lo que va a firmar el usuario
  getTypeSignUser = (signerSignType) => {
    const sessionType = localStorage.getItem('sessionType');
    console.log('SESSION USER', sessionType);
    if (
      (signerSignType === '1' && sessionType === 'efirma') ||
      (signerSignType === '2' && sessionType === 'efirma') ||
      (signerSignType === '2' && sessionType === 'sife')
    ) {
      return 'efirmaSign';
    }

    if (
      (signerSignType === '1' && sessionType === 'sife') ||
      (signerSignType === '3' && sessionType === 'sife')
    ) {
      return 'sifeSign';
    }

    if (signerSignType === '3' && sessionType === 'efirma') {
      return 'sifeSession';
    }
  };

  openNewIputs = () => {
    this.setState({ openNewViewInputs: true });
  };

  closeModal = () => {
    const { updateStateSignContract } = this.props;
    const { openNewViewInputs } = this.state;

    if (openNewViewInputs) {
      return this.setState({ openNewViewInputs: false });
    }
    updateStateSignContract({
      showModalContract: false,
      showModalAddendum: false,
    });
  };

  getDataFromInputs = (data) => {
    this.setState(data);
  };

  canSign = () => {
    const { key, cer, passphrase } = this.state;
    return passphrase?.length && key && cer;
  };

  onClickSignSife = async () => {
    const { type, updateStateSignContract } = this.props;
    const response = await this.sendDocumentToSife(type);
    if (response?.data?.success) {
      updateStateSignContract({
        showModalContract: false,
        showModalAddendum: false,
      });

      if (type === 'contract') {
        return updateStateSignContract({
          showIframeToSign: true,
        });
      }

      return updateStateSignContract({
        showIframeToSignAddendum: true,
      });
    }
  };

  handleButton(contract, signer) {
    const { signerSignType, isValidSign, openNewViewInputs } = this.state;
    const { type, isMyTurnToSign, width, lang, translations } = this.props;
    const { buttons } = translations[lang].ContractDetail.ContractPreview;
    const isNotMobile = width?.width > 992;
    if (!isMyTurnToSign || contract?.is_valid === false) {
      return (
        <button
          style={{
            background: '#fff',
            color: 'black',
            margin: 0,
            marginTop: 10,
          }}
          className="status"
          type="button"
        >
          {renderStatus(contract.lastSignedTurn, signer, contract)}
        </button>
      );
    }

    if (signerSignType === 'efirmaSign' && isMyTurnToSign) {
      const sign = type === 'contract' ? this.onSign : this.onSignAddendum;
      return isNotMobile ? (
        <button
          className="primary"
          type="button"
          disabled={!this.canSign()}
          // onClick={() => handleConfirm(this)}
          onClick={sign}
        >
          {buttons.button1}
        </button>
      ) : (
        <button
          className="primary"
          type="button"
          disabled={openNewViewInputs ? !this.canSign() : false}
          // onClick={() => handleConfirm(this)}
          onClick={openNewViewInputs ? sign : this.openNewIputs}
        >
          {openNewViewInputs ? buttons.button2 : buttons.button3}
        </button>
      );
    }

    if (signerSignType === 'sifeSign' || isValidSign) {
      return (
        <div
          className="elements_sign_button"
          onClick={() => this.onClickSignSife()}
        >
          <p className="text_button_sife">{buttons.button4}</p>
        </div>
      );
    }

    if (signerSignType === 'sifeSession') {
      return (
        <div
          className="elements_sign_button"
          onClick={() =>
            this.windowOpen({
              url: `${sifeAuthUrl}${
                sifeAuthOptions.login
              }${sifeAuthOptions.lang()}`,
              title: 'Sora Contratos',
              w: 700,
              h: 800,
              document: 'sifeSession',
            })
          }
        >
          <p className="text_button_sife">{buttons.button5}</p>
        </div>
      );
    }
  }

  onSign = async () => {
    const { contract, signer } = this.props;
    const credentials = this.state;
    try {
      const cuteSign = await Signature(
        credentials,
        contract.lastHash || contract.hash,
      );
      await this.signContract({
        contract,
        credentials,
        user: signer,
        cuteSign,
      });
    } catch (error) {
      console.log(error);
      // TODO: translate response
      return NotificationManager.warning(error.message, 'Atención', 10000);
    }
  };

  signContract = async (data) => {
    const { contract } = data;
    const {
      updateStateSignContract,
      signer,
      locationUser,
      lang,
      translations,
    } = this.props;
    const { notifications } = translations[lang].ContractDetail.ContractPreview;
    updateStateSignContract({ loading: true, showModalContract: false });
    NotificationManager.info(
      notifications.notifBody2,
      notifications.notifTitle2,
      8000,
    );

    try {
      const response = await ContractActions.sign({
        ...data,
        locationUser,
      });
      if (response !== 1) {
        updateStateSignContract({ loading: false });
        // TODO: translate response
        return NotificationManager.error(response.message, 'Atención', 5000);
      }

      const contractResponse = await ContractActions.view(contract.id);
      signer.signed = 1;
      signer.signedAt = new Date();
      updateStateSignContract({
        contract: contractResponse,
        isMyTurnToSign: false,
        loading: false,
        modal: 'contract',
        canAddAddendum: contractResponse.allPartiesSigned,
      });
      NotificationManager.success(
        notifications.notifBody4,
        notifications.notifTitle4,
        10000,
      );
    } catch (error) {
      console.log(error);
      updateStateSignContract({ loading: false });
      NotificationManager.error(
        notifications.notifBody5,
        notifications.notifTitle5,
        10000,
      );
    }
  };

  onSignAddendum = async () => {
    const { contract, signer } = this.props;
    const credentials = this.state;
    try {
      const cuteSign = await Signature(
        credentials,
        contract.lastHash || contract.hash,
      );
      await this.signAddendum({
        addendum: contract,
        credentials,
        user: signer,
        cuteSign,
      });
      this.setState({ loading: false });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
      // TODO: translate response
      return NotificationManager.warning(error.message, 'Atención', 10000);
    }
  };

  signAddendum = async (data) => {
    const {
      signer,
      originalContract,
      updateStateSignContract,
      locationUser,
      lang,
      translations,
    } = this.props;
    const { notifications } = translations[lang].ContractDetail.ContractPreview;
    updateStateSignContract({ loading: true, showModalAddendum: false });
    NotificationManager.info(
      notifications.notifBody7,
      notifications.notifTitle7,
      8000,
    );

    try {
      const response = await AddendumActions.signAddendum({
        ...data,
        locationUser,
      });
      if (response.message) {
        this.setState({ loading: false });
        // TODO: translate response
        return NotificationManager.error(response.message, 'Atención', 3000);
      }
      const addendums = await AddendumActions.getThisAddendums(
        originalContract.id,
      );
      signer.signed = true;
      updateStateSignContract({
        addendums,
        signerAddendum: signer,
        isMyTurnToSignAddendum: false,
        loading: false,
        modal: 'addendum',
      });
      NotificationManager.success(
        notifications.notifBody9,
        notifications.notifTitle9,
        10000,
      );
    } catch (error) {
      console.log(error);
      return NotificationManager.error(
        notifications.notifBody10,
        notifications.notifTitle10,
        10000,
      );
    }
  };

  sendDocumentToSife = async (document, type) => {
    const {
      contract,
      session,
      originalContract,
      locationUser,
      signer,
      signerConfig,
    } = this.props;
    try {
      if (document === 'contract') {
        const data = {
          contract,
          user: session.user,
          type,
          locationUser,
          signer,
        };
        return await ContractActions.createContractSife(data);
      }

      if (document === 'addendum') {
        const data = {
          addendum: contract,
          user: session.user,
          contract: originalContract,
          type,
          locationUser,
          signer,
          signerConfig,
        };
        return await AddendumActions.createAddendumSife(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleSignDocumentSife = async (e, document) => {
    const {
      contract,
      signer,
      originalContract,
      updateStateSignContract,
      lang,
      translations,
    } = this.props;
    const { notifications } = translations[lang].ContractDetail.ContractPreview;
    if (signer.requestVerification) {
      signer.verified = true;
      const participants = await ContractActions.getThisSigners(contract.id);
      updateStateSignContract({
        verificationDocs: participants.verificationDocs,
      });
    }

    if (e.data.message === 'DOC_SIGNED' && document === 'contract') {
      const contractResponse = await ContractActions.view(contract.id);
      signer.signed = 1;
      signer.signedAt = new Date();
      updateStateSignContract({
        contract: contractResponse,
        isMyTurnToSign: false,
        loading: false,
        modal: 'contract',
      });
      return NotificationManager.success(
        notifications.notifBody11,
        notifications.notifTitle11,
        5000,
      );
    }

    if (e.data.message === 'DOC_SIGNED' && document === 'addendum') {
      const addendums = await AddendumActions.getThisAddendums(
        originalContract.id,
      );
      signer.signed = true;
      updateStateSignContract({
        addendums,
        signerAddendum: signer,
        isMyTurnToSignAddendum: false,
        modal: 'addendum',
      });
      NotificationManager.success(
        notifications.notifBody12,
        notifications.notifTitle12,
        10000,
      );
    }
  };

  // INICIAR SESSION
  handleSifeMessageSession = async (e) => {
    if (/^react-devtools/gi.test(e.data.source)) {
      e.target.removeEventListener('message', this.handleSifeMessageSession);
      return null;
    }
    const { session, lang, translations } = this.props;
    const { notifications } = translations[lang].ContractDetail.ContractPreview;
    const messageReply = { success: false };
    try {
      // TODO
      if (e?.data?.message === 'openedReady') {
        return;
      }
      // This if validation is only for login and registration from sife
      // If object keys has 6 keys it is a login with sife
      if (e?.data?.rfc || e?.data?.email || e?.data?.id) {
        if (e.data.rfc !== session.user.legalRfc) {
          e.source.postMessage(messageReply, e.origin);
          return NotificationManager.warning(
            notifications.notifBody13,
            notifications.notifTitle13,
            5000,
          );
        }
        if (e.data.email !== session.user.secondaryEmail) {
          e.source.postMessage(messageReply, e.origin);
          return NotificationManager.warning(
            notifications.notifBody14,
            notifications.notifTitle14,
            5000,
          );
        }
        e.source.postMessage({ ...messageReply, success: true }, e.origin);
        this.setState({ isValidSign: true });
      }
    } catch (error) {
      console.log(error);
      e.source.postMessage(messageReply, e.origin);
      return NotificationManager.warning(
        notifications.notifBody15,
        notifications.notifTitle15,
        5000,
      );
    }
  };

  // ESTE ES PARA CONTRATOS
  onLoadFrame = async (document) => {
    let eventMethod = window.addEventListener
      ? 'addEventListener'
      : 'attachEvent';
    let eventer = window[eventMethod];
    let messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';
    const sendFunction =
      document === 'sifeSession'
        ? this.handleSifeMessageSession
        : this.handleSignDocumentSife;
    eventer(messageEvent, (e) => sendFunction(e, document));
  };

  windowOpen = ({ url, title, w, h, document }) => {
    // Fixes dual-screen position Most browsers      Firefox

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.screen.height;

    const left = width / 2 - w / 2;
    const top = height / 2 - h / 2;
    const newWindow = window.open(
      url,
      title,
      `
      scrollbars=yes,
      width=${w}, 
      height=${h}, 
      top=${top}, 
      left=${left}
      `,
    );
    // FIXME: for some reason loading executes onunload and not onload
    newWindow.onunload = this.onLoadFrame(document);
    newWindow.onload = function () {
      // console.log("heeeeeyyy");
    };

    if (window.focus) newWindow.focus();
    // setWindowObjRef(newWindow);
  };

  /* downloadViewFile = async () => {
    const { contract, signer, session } = this.props;
    const { data } = await ContractActions.massiveDownloadFiles(
      [contract],
      signer,
      true,
    );
    if (data.error) {
      return NotificationManager.error(
        'Error al realizar la descarga',
        'Error',
        10000,
      );
    }
    return massiveDownloadFilesUtils(data, session);
  }; */

  render() {
    const { signerSignType, openNewViewInputs } = this.state;
    const {
      contract,
      signer,
      contractUrl,
      isMyTurnToSign,
      width,
      lang,
      translations,
    } = this.props;
    const { buttons, texts } =
      translations[lang].ContractDetail.ContractPreview;
    return (
      <>
        <div class="modal-demo">
          <div class="modal-content-demo">
            <div class="modal-header-demo" style={{ height: '15px' }}>
              {/* {deviceUtil?.isMobile() ? (
                <i
                  className="fas fa-file-download"
                  style={{
                    marginRight: 7,
                    color: '#2851e5',
                  }}
                  onClick={() => this.downloadViewFile()}
                ></i>
              ) : null} */}
            </div>
            <div
              class="modal-body-demo"
              style={{ ...(Boolean(signer?.signed) && { height: '90%' }) }}
            >
              {!openNewViewInputs || width?.width > 992 ? (
                <ViewPdf contractUrl={contractUrl} />
              ) : null}

              {!signer?.signed &&
                signerSignType === 'efirmaSign' &&
                isMyTurnToSign && (
                  <div className="additional_info">
                    {width?.width > 992 ? (
                      <CerFields getDataFromInputs={this.getDataFromInputs} />
                    ) : openNewViewInputs ? (
                      <div className="mobile_version">
                        <div className="information">
                          <h3>{texts.h3}</h3>
                          <p>{texts.p1(contract.title)}</p>
                        </div>
                        <CerFields getDataFromInputs={this.getDataFromInputs} />
                      </div>
                    ) : null}
                  </div>
                )}
            </div>
            <div
              className="modal-footer-demo"
              // style={{ paddingRight: 10, paddingLeft: 10 }}
            >
              <button
                className="color-grey"
                type="button"
                onClick={this.closeModal}
              >
                {buttons.button6}
              </button>
              {this.handleButton(contract, signer)}
            </div>
          </div>
        </div>
        <div class="modal-overlay"></div>
      </>
    );
  }
}

export default branch(
  {
    session: ['session'],
    contractUrl: ['contractUrl'],
    width: ['width'],
    lang: ['lang'],
    translations: ['translations'],
  },
  ContractPreview,
);
