import { branch } from 'baobab-react/higher-order';
import React from 'react';
import { Fragment } from 'react';
import { BrowserRouter, Link, NavLink } from 'react-router-dom';

const Home = ({ user, location, history, translations, lang }) => {
  const translation = translations[lang].Home;
  const { texts } = translation;
  return (
    <div className="grid_main_home">
      <div className="legal_name_home">
        <h1>
          {texts.h1(user && user.legalName ? user.legalName.split(' ')[0] : '')}
        </h1>
      </div>
      <div className="grid_home" /* style={{ background: 'blue' }} */>
        <div className="title_shortcut">
          <p>{texts.p}</p>
        </div>
        <div className="shortcuts">
          <Link to="/nuevo" className="shortcut new_document">
            <i className="far fa-plus-square icon_home" />
            {texts.link1}
          </Link>
          <Link to="/carga-masiva" className="shortcut multiple_documents">
            <i className="far fa-copy icon_home" />
            {texts.link2}
          </Link>
          <Link to="/contratos" className="shortcut see_my_documents">
            <i className="fas fa-list icon_home" />
            {texts.link3}
          </Link>
        </div>
      </div>
      <div className="grid_representing_admin">
        <div className="signers_home">
          {user?.representing?.length > 0 && (
            <div className="all_users_home">
              <div className="title_list">
                <p>{texts.p2}</p> {/* Legal representative profile*/}
              </div>
              <div className="users_home_list scrollable">
                {user.representing.map((signer, index) => (
                  <Fragment key={index}>
                    <BrowserRouter forceRefresh={true} key={index}>
                      <NavLink
                        to={{
                          pathname: `/contracts/on/${signer.rootUser}`,
                          state: {
                            from: location.pathname,
                          },
                        }}
                        className="home_list"
                      >
                        <i className="fas fa-user-tie"></i>
                        {signer.legalName}
                      </NavLink>
                    </BrowserRouter>
                    {/* <p
                      className="user_permission"
                      onClick={() => {
                        history.push({
                          pathname: `/contracts/on/${signer.rootUser}`,
                          state: {
                            from: location.pathname,
                          },
                        });
                      }}
                      key={index}
                    >
                      <i className="fas fa-user-tie"></i>
                      {signer.legalName}
                    </p> */}
                  </Fragment>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="admins_home">
          {user?.permissions?.length > 0 && (
            <div className="all_users_home">
              <div className="title_list">
                <p>{texts.p3}</p> {/* Administrator profile*/}
              </div>
              <div className="users_home_list scrollable">
                {user.permissions.map((admin, index) => (
                  <Fragment key={index}>
                    {/* <p
                      className="user_permission"
                      onClick={() => {
                        history.push({
                          pathname: `/admin/of/${admin.rootUser}`,
                          state: {
                            from: location.pathname,
                          },
                        });
                      }}
                      key={index}
                    >
                      <i className="fas fa-user-tie"></i>
                      {admin.legalName}
                    </p> */}
                    <BrowserRouter forceRefresh={true} key={index}>
                      <NavLink
                        to={{
                          pathname: `/admin/of/${admin.rootUser}`,
                          state: {
                            from: location.pathname,
                          },
                        }}
                        className="home_list"
                      >
                        <i className="fas fa-user-tie"></i>
                        {admin.legalName}
                      </NavLink>
                    </BrowserRouter>
                  </Fragment>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default branch(
  {
    translations: ['translations'],
    lang: ['lang'],
  },
  Home,
);
