import { branch } from 'baobab-react/higher-order';
import { NotificationManager } from 'react-notifications';
import React from 'react';
// actions
import ContractActions from '../actions/Contract';
import LabelActions from '../actions/LabelActions';
import ProfileActions from '../actions/Profile';
// components
import ConfirmContractModal from '../components/Modals/ConfirmContractModal';
import Loader from '../components/Loader';
import GenericConfirmModal from '../components/Modals/GenericConfirmModal';
import ContractInputsDisplay from './NewContract/ContractInputsDisplay';
import ChipsDisplay from './NewContract/ChipsDisplay';
import DropzoneDisplay from './NewContract/DropzoneDisplay';
import AuthorizersDisplay from './NewContract/AuthorizersDisplay';
import SignersDisplay from './NewContract/SignersDisplay';
import RecipientsDisplay from './NewContract/RecipientsDisplay';
import UploadButtonDisplay from './NewContract/UploadButtonDisplay';

class NewContract extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      description: '',
      ttl: 40,
      file: null,
      signers: [],
      recipients: [],
      loading: false,
      myLabels: [],
      coupon: null,
      date: null,
      checkAuthorizers: false,
      authorizers: [],
      orderedSigners: true,
      signTypeSigner: '',
      contractUser: '',
      adminOrSigner: false,
      chips: [],
      showConfirmModal: false,
      activeButtonUploadPdf: false,
      showModal: false,
    };
  }

  async componentDidMount() {
    const { session, signer, admin } = this.props;
    const { user } = session;
    const contractUser = signer || admin || user;
    try {
      const id = contractUser.rootUser || contractUser.id;
      const [coupon] = await ProfileActions.coupons(id);
      const myLabels = await LabelActions.myLabels(id);

      this.setState({
        myLabels,
        coupon: coupon.usesLeft ? coupon : 0,
        contractUser,
      });
      if (signer || admin) this.setState({ signerOrAdmin: true });
    } catch (error) {
      console.error(error);
    }
  }

  hideModal = () => {
    this.setState({ showConfirmModal: false });
  };

  createAndUploadContract = async () => {
    const { lang, translations } = this.props;
    const translation = translations[lang].NewContract;
    const { notifications } = translation;
    this.setState({ loading: true, showConfirmModal: false, showModal: false });
    const {
      title,
      description,
      chips,
      signers,
      recipients,
      checkAuthorizers,
      authorizers,
      file,
      coupon,
      date,
      orderedSigners,
      signTypeSigner,
      contractUser,
    } = this.state;
    if (file.size > 15728640) {
      NotificationManager.error(
        notifications.notifBody1(file.name),
        notifications.notifTitle1,
        10000,
      );
      this.setState({ loading: false });
      return;
    }
    try {
      const signersUpdated = signers.map((signer, index) => {
        return {
          ...signer,
          requestDocument: signer.requestDocument.size
            ? JSON.stringify(Array.from(signer.requestDocument))
            : null,
          signType: signTypeSigner ? signTypeSigner : signer.signType,
        };
      });

      const response = await ContractActions.create({
        title,
        description,
        file,
        expiresOn: date,
        checkAuthorizers,
        orderedSigners: !orderedSigners,
        createdBy: contractUser.rootUser ? contractUser.user : contractUser.id,
        rootUser: contractUser.rootUser,
        authorizers,
        signers: signersUpdated,
        recipients,
        labels: chips,
      });
      if (!response?.success) {
        this.setState({ loading: false });
        return NotificationManager.error(response?.message, 'Error', 4000);
      }

      const contract = response.contract;
      await ProfileActions.updateCoupon(coupon.userCouponId || coupon.id);
      await ProfileActions.couponContract(
        coupon.coupon || coupon.id,
        contract.id,
      );

      this.setState({ loading: false });
      this.redirectToContractDetail(contract);
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
      return NotificationManager.error(
        notifications.notifBody2,
        notifications.notifTitle2,
        4000,
      );
    }
  };

  updateStateNewContract = (data) => {
    this.setState(data);
  };

  redirectToContractDetail = (contract) => {
    const { history } = this.props;
    const { contractUser, recipients, signerOrAdmin } = this.state;
    const isRecipient = recipients.some(
      (rec) => rec.userId === contractUser.id,
    );
    const path =
      signerOrAdmin || isRecipient
        ? `/public/contracts/${contract.id}`
        : `/contratos/${contract.id}`;

    return history.push({
      pathname: path,
      state: {
        from: window.location.pathname,
        admin: signerOrAdmin ? contractUser : null,
      },
    });
  };

  handleCancelPopUp = () => {
    this.setState({ showModal: false });
  };

  render() {
    const {
      title,
      description,
      file,
      signers,
      recipients,
      myLabels,
      loading,
      showModal,
      showConfirmModal,
      coupon,
      checkAuthorizers,
      authorizers,
      signerOrAdmin,
      signTypeSigner,
      activeButtonUploadPdf,
      contractUser,
    } = this.state;

    const { lang, translations } = this.props;
    const translation = translations[lang].NewContract;
    const { texts, buttons } = translation;

    return (
      <div className={`new_contract_view ${signerOrAdmin ? 'admin_view' : ''}`}>
        <div className="title_nc">
          {!signerOrAdmin ? <h1>{texts.h1}</h1> : null}
        </div>
        <div className="complete_info_contract page padded shadow_new_contract">
          <ContractInputsDisplay
            updateStateNewContract={this.updateStateNewContract}
          />
          <DropzoneDisplay
            updateStateNewContract={this.updateStateNewContract}
          />
          <ChipsDisplay
            myLabels={myLabels}
            updateStateNewContract={this.updateStateNewContract}
          />
        </div>

        <div className="authorizers_nc page padded shadow_new_contract">
          <AuthorizersDisplay
            updateStateNewContract={this.updateStateNewContract}
            authorizers={authorizers}
            signer={this.props.signer}
            admin={this.props.admin}
          />
        </div>

        <div className="signers_nc page padded shadow_new_contract">
          <SignersDisplay
            updateStateNewContract={this.updateStateNewContract}
            signers={signers}
            signer={this.props.signer}
            admin={this.props.admin}
          />
        </div>

        <div className="recipients_nc page padded shadow_new_contract">
          <RecipientsDisplay
            updateStateNewContract={this.updateStateNewContract}
            recipients={recipients}
            signer={this.props.signer}
            admin={this.props.admin}
          />
        </div>

        <div className="send_contract_nc">
          {loading ? (
            <div className="center_loading">
              <Loader />
            </div>
          ) : (
            <UploadButtonDisplay
              authorizers={authorizers}
              signers={signers}
              recipients={recipients}
              checkAuthorizers={checkAuthorizers}
              signTypeSigner={signTypeSigner}
              title={title}
              file={file}
              activeButtonUploadPdf={activeButtonUploadPdf}
              updateStateNewContract={this.updateStateNewContract}
              coupon={coupon}
              addRecipientIsNotParticipant={this.addRecipientIsNotParticipant}
              contractUser={contractUser}
            />
          )}
        </div>

        {/* <div className="row center-xs mt-1">
          <Loader />
        </div> */}

        {showModal ? (
          <GenericConfirmModal
            title={texts.genericModalTitle1}
            message={`${
              !coupon
                ? texts.genericModalBody1
                : coupon.usesLeft < 10
                ? texts.genericModalBody2(coupon?.usesLeft)
                : null
            }`}
            okButton={buttons.genericModalConfirmButton1}
            cancelButton={buttons.genericModalCancelButton1}
            cancelAction={() => this.handleCancelPopUp()}
            acceptAction={() =>
              !coupon
                ? this.handleCancelPopUp()
                : this.createAndUploadContract()
            }
          />
        ) : null}

        {showConfirmModal ? (
          <ConfirmContractModal
            contract={{
              title,
              description,
              file,
            }}
            signers={signers}
            recipients={recipients}
            authorizers={checkAuthorizers ? authorizers : null}
            handleConfirmContract={() => this.createAndUploadContract()}
            handleCancel={this.hideModal}
          />
        ) : null}
      </div>
    );
  }
}

export default branch(
  {
    session: ['session'],
    myCoupons: ['myCoupons'],
    translations: ['translations'],
    lang: ['lang'],
  },
  NewContract,
);
