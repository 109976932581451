export const SignerModalTranslations = {
  es: {
    texts: {
      h2_1: 'Apoderado',
      h2_2: 'Administrador',
      spanTitle1: '¿El usuario tiene RFC?',
      spanTitle2: 'Datos del usuario:',
      spanTitle3: 'Seguridad del perfil:',
      spanTitle4: 'Permisos:',
      spanTitle5: 'Etiquetas para usuario:',
      tootltip1: 'ID generado por SORA para tu firmante',
      p1: 'Proteger con uso de e.firma (SAT)',
      p2: 'Crear documentos',
      p3: 'Ver documentos',
      p4: 'Crear administradores',
      p5: 'Crear apoderados',
      small1: 'El usuario está obligado a usar su e.firma (SAT)',
      small2: 'Este usuario puede crear documentos a tu nombre',
      small3: 'Puede ver todos tus documentos',
      small4: 'Este usuario puede crear admins a tu nombre',
      small5: 'Este usuario puede crear apoderados a tu nombre',
      small6:
        'Selecciona que documentos puede ver tu usuario en base a etiquetas',
      or: 'ó',
      option1: 'Elegir usuario',
    },
    inputs: {
      inputLabel1: 'Si',
      inputLabel2: 'No',
      inputPlaceholder1: 'Correo electrónico',
      inputSmall1: 'El formato del correo no es válido',
      inputPlaceholder2: 'Nombre completo',
      inputSmall2: 'Longitud del nombre invalida',
      inputPlaceholder3: 'RFC',
      inputPlaceholder4: 'Sora ID',
      inputSmall3: 'El formato del rfc no es válido',
      inputPlaceholder5: 'Crear nuevas etiquetas',
      inputSelectDefault1: 'Selecciona una etiqueta',
    },
    buttons: {
      button1: 'Regresar',
      button2: 'Guardar',
      button3: 'Editar',
    },
    notifications: {},
  },
  en: {
    texts: {
      h2_1: 'Proxy',
      h2_2: 'Administrator',
      spanTitle1: 'Does the user have RFC?',
      spanTitle2: 'User data:',
      spanTitle3: 'Profile security:',
      spanTitle4: 'Permissions:',
      spanTitle5: 'User tags:',
      tootltip1: 'ID generated by SORA for your signer',
      p1: 'Protect with use of e.firma (SAT)',
      p2: 'Create documents',
      p3: 'View documents',
      p4: 'Create administrators',
      p5: 'Create proxies',
      small1: 'The user is obliged to use e.firma (SAT)',
      small2: 'This user can create documents in your name',
      small3: 'This user can see all your documents',
      small4: 'This user can create admins in your name',
      small5: 'This user can create proxies in your name',
      small6: 'Select which documents your user can see based on tags',
      or: 'or',
      option1: 'Select user',
    },
    inputs: {
      inputLabel1: 'Yes',
      inputLabel2: 'No',
      inputPlaceholder1: 'Email',
      inputSmall1: 'Email format is invalid',
      inputPlaceholder2: 'Full name',
      inputSmall2: 'Invalid name length',
      inputPlaceholder3: 'RFC',
      inputPlaceholder4: 'Sora ID',
      inputSmall3: 'RFC format is invalid',
      inputPlaceholder5: 'Create new tags',
      inputSelectDefault1: 'Select a tag',
    },
    buttons: {
      button1: 'Back',
      button2: 'Save',
      button3: 'Edit',
    },
    notifications: {},
  },
};
