export const SelectContractsToSignersTranslations = {
  es: {
    texts: {
      h1: 'Los siguientes correos tienen 2 o más cuentas, por favor elige a que cuenta enviar el contrato',
      small1: 'Omitir los documentos con correos repetidos',
      small2: 'Nombre documento',
      small3: 'Usuario',
      option1: 'Elegir usuario',
    },
    inputs: {},
    buttons: {
      button1: 'Finalizar',
    },
    notifications: {
      notifBody1: 'Selecciona un usuario para cada uno de tus firmantes',
      notifTitle1: 'Error',
    },
  },
  en: {
    texts: {
      h1: 'The following emails have 2 or more accounts, please choose which account to send the contract to',
      small1: 'Skip documents with repeated emails',
      small2: 'Document name',
      small3: 'User',
      option1: 'Choose user',
    },
    inputs: {},
    buttons: {
      button1: 'Finish',
    },
    notifications: {
      notifBody1: 'Select a user for each of your signers.',
      notifTitle1: 'Error',
    },
  },
};
