import React from 'react';
import SuperUserActions from '../../../actions/SuperUser';
import { NotificationManager } from 'react-notifications';
import { emailRegex } from '../../../utils/constants';
import Loader from '../../../components/Loader';

class UpdateSignerSuperAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirming: false,
      signer: null,
      loading: false,
    };
  }

  componentDidMount() {
    const { signerToUpdate } = this.props;
    if (signerToUpdate?.hasOwnProperty('userId')) {
      return this.setState({
        signer: {
          emailSend: signerToUpdate?.emailSend,
          signType: signerToUpdate?.signType,
          requestVerification: Boolean(signerToUpdate?.requestVerification),
        },
      });
    } else {
      return this.setState({
        signer: {
          legalName: signerToUpdate?.legalName || '',
          email: signerToUpdate?.email,
          signType: signerToUpdate?.signType,
          requestVerification: Boolean(signerToUpdate?.requestVerification),
        },
      });
    }
  }

  handleChange = (event) => {
    const { name, value, checked } = event.target;
    const { signer } = this.state;
    if (name === 'requestVerification') {
      return this.setState({ signer: { ...signer, [name]: checked } });
    }

    if (name === 'email' || name === 'emailSend') {
      return this.setState({
        signer: { ...signer, [name]: value.replace(/ /g, '').toLowerCase() },
      });
    }

    return this.setState({ signer: { ...signer, [name]: value } });
  };

  handleUpdate = async () => {
    const { signer } = this.state;
    const { contract, signerToUpdate, searchContractsById, setExternalData } =
      this.props;
    try {
      const userExists = Boolean(signerToUpdate?.hasOwnProperty('userId'));
      if (userExists) {
        if (signer.emailSend && !emailRegex.test(signer.emailSend)) {
          return NotificationManager.error(
            'El email no es válido',
            'Error',
            5000,
          );
        }
      }

      if (!userExists) {
        if (!emailRegex.test(signer.email)) {
          return NotificationManager.error(
            'El email no es válido',
            'Error',
            5000,
          );
        }

        if (!signer.legalName) {
          return NotificationManager.error(
            'El nombre del firmante no es válido',
            'Error',
            5000,
          );
        }
      }

      if (!signer.signType) {
        return NotificationManager.error(
          'El tipo de firma del firmante no es válido',
          'Error',
          5000,
        );
      }

      this.setState({ loading: true });
      const table = userExists ? 'signer' : 'queue_contracts';
      let condition = {};
      if (userExists) {
        condition = { id: signerToUpdate.id, contractId: contract.id };
      } else {
        condition = { id: signerToUpdate.id, contract: contract.id };
      }

      const data = {
        table,
        condition,
        payload: signer,
      };
      const response = await SuperUserActions.updateSignerSuperUser(data);
      if (!response?.data?.success) {
        this.setState({ loading: false });
        return NotificationManager.error(
          response?.data?.message || 'Error al actualizar firmante',
          'Error',
          5000,
        );
      }
      searchContractsById();
      this.setState({ loading: false });
      setExternalData({ modalToUpdateSigner: false, signerToUpdate: null });
      return NotificationManager.success(
        'Firmante actualizado con éxito',
        'Éxito',
        5000,
      );
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
      return NotificationManager.error(
        'Hubo un error al actualizar el firmante',
        'Error',
        5000,
      );
    }
  };

  render() {
    const { signerToUpdate, handleCancel } = this.props;
    const { signer, loading } = this.state;
    return (
      <div className="modal-container mtm-20">
        <div className="modal" style={{ width: 900 }}>
          <h2 style={{ marginLeft: 18 }}>Editar firmante</h2>

          <div className="row" style={{ paddingBottom: 20 }}>
            {loading ? (
              <div className="center_loading" style={{ width: '100%' }}>
                <Loader />
                <p>Agregando usuario...</p>
              </div>
            ) : (
              <>
                {!signerToUpdate?.hasOwnProperty('userId') && (
                  <div className="col-sm-4">
                    <small>Nombre</small>
                    <input
                      type="text"
                      name="legalName"
                      className="input_nc"
                      value={signer?.legalName || ''}
                      minLength="10"
                      onChange={(e) => this.handleChange(e)}
                      placeholder="Nombre Completo"
                    />
                  </div>
                )}
                {signerToUpdate?.hasOwnProperty('userId') ? (
                  <div className="col-sm-4">
                    <small>Email</small>
                    <input
                      type="email"
                      name="emailSend"
                      className="input_nc"
                      value={signer?.emailSend || ''}
                      onChange={(e) => this.handleChange(e)}
                      required
                      placeholder="email"
                      autoComplete="nope"
                    />
                  </div>
                ) : (
                  <div className="col-sm-4">
                    <small>Email</small>
                    <input
                      type="email"
                      name="email"
                      className="input_nc"
                      value={signer?.email || ''}
                      onChange={(e) => this.handleChange(e)}
                      required
                      placeholder="email"
                      autoComplete="nope"
                    />
                  </div>
                )}
                <div className="col-sm-4">
                  <small>Tipo de firma</small>
                  <div className="dropdown_choose_signature">
                    <select
                      name="signType"
                      onChange={(e) => this.handleChange(e)}
                      autoComplete="nope"
                      value={signer?.signType || ''}
                      className="input_nc"
                    >
                      <option value="" disabled>
                        Elige el tipo de firma
                      </option>
                      <option value="1">Indistinta</option>
                      <option value="2">e.firma(SAT)</option>
                      <option value="3">Sife</option>
                    </select>
                  </div>
                </div>
              </>
            )}
          </div>
          {!loading ? (
            <div
              className="col-sm-6"
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 20,
                marginBottom: 30,
              }}
            >
              <small>Solicitar verificación</small>
              <input
                type="checkbox"
                id="cbox2"
                value={Boolean(signer?.requestVerification)}
                checked={Boolean(signer?.requestVerification)}
                name="requestVerification"
                onChange={(e) => this.handleChange(e)}
              />
            </div>
          ) : null}

          <div
            className="col-sm-12"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <button
              type="button"
              className="secondary"
              onClick={handleCancel}
              style={{ color: 'black' }}
            >
              Regresar
            </button>
            <button
              type="submit"
              className="primary"
              onClick={() => this.handleUpdate()}
            >
              Editar
            </button>
          </div>
        </div>
        <div className="background-screen" />
      </div>
    );
  }
}

export default UpdateSignerSuperAdmin;
