import { endpointHandler } from '../createEndpoint';

const {
  getThisLabelsEP,
  getThisSignersEP,
  listContractsEP,
  fileContractEP,
  createContractEP,
  createContractSifeEP,
  createContractSignerSifeEP,
  getContractSifeEP,
  viewContractEP,
  changeSignerEP,
  getThisRecipientsEP,
  searchByRfcEP,
  signContractEP,
  softDeleteContractEP,
  cancelContractEP,
  getAllContractsMonthlyEP,
  listContractAuthorizerEp,
  updateAuthorizerContractEP,
  getAuthorizerEP,
  updateSignerEP,
  sendEmailToUserEP,
  massiveDownloadEP,
  resetAllDownloadsEP,
  downloadPdfByDateEP,
  getContractsPendingToDownloadEP,
  downloadContractToViewEP,
  uploadIdentityDocumentsEP,
  verifyFrontIdEP,
  verifyBackIdEP,
  verifySelfieEP,
  verifyFacesMatchEP,
  getUserSifeEP,
  getInfoVerificationSifeEP,
  generateSoraIdEP,
  updateUserSoraIdEP,
  uploadContractsMassiveEP,
  getExcelReportEP,
} = endpointHandler();

export const contractsServices = {
  listContractsService: (reqConfig, urlVar) => {
    return listContractsEP.get(reqConfig, urlVar);
  },
  getThisSignersService: (reqConfig, urlVar) => {
    return getThisSignersEP.get(reqConfig, urlVar);
  },
  getThisLabelsService: (reqConfig, urlVar) => {
    return getThisLabelsEP.get(reqConfig, urlVar);
  },
  getContractSifeService: (reqConfig, urlVar) => {
    return getContractSifeEP.get(reqConfig, urlVar);
  },
  viewContractService: (reqConfig, urlVar) => {
    return viewContractEP.get(reqConfig, urlVar);
  },
  getThisRecipientsService: (reqConfig, urlVar) => {
    return getThisRecipientsEP.get(reqConfig, urlVar);
  },
  searchByRfcService: (reqConfig, urlVar) => {
    return searchByRfcEP.get(reqConfig, urlVar);
  },
  getAllContractsMonthlyService: (reqConfig, urlVar) => {
    return getAllContractsMonthlyEP.get(reqConfig, urlVar);
  },
  listContractAuthorizerService: (reqConfig, urlVar) => {
    return listContractAuthorizerEp.get(reqConfig, urlVar);
  },
  getAuthorizerService: (reqConfig, urlVar) => {
    return getAuthorizerEP.get(reqConfig, urlVar);
  },
  downloadContractToViewService: (reqConfig, urlVar) => {
    return downloadContractToViewEP.get(reqConfig, urlVar);
  },
  getUserSifeService: (reqConfig, urlVar) => {
    return getUserSifeEP.get(reqConfig, urlVar);
  },
  getInfoVerificationSifeService: (reqConfig, urlVar) => {
    return getInfoVerificationSifeEP.get(reqConfig, urlVar);
  },

  //POST
  fileContractService: (data, reqConfig, urlVar) => {
    return fileContractEP.post(data, reqConfig, urlVar);
  },
  createContractService: (data, reqConfig, urlVar) => {
    return createContractEP.post(data, reqConfig, urlVar);
  },
  createContractSifeService: (data, reqConfig, urlVar) => {
    return createContractSifeEP.post(data, reqConfig, urlVar);
  },
  createContractSignerSifeService: (data, reqConfig, urlVar) => {
    return createContractSignerSifeEP.post(data, reqConfig, urlVar);
  },
  signContractService: (data, reqConfig, urlVar) => {
    return signContractEP.post(data, reqConfig, urlVar);
  },
  updateAuthorizerContractService: (data, reqConfig, urlVar) => {
    return updateAuthorizerContractEP.post(data, reqConfig, urlVar);
  },
  updateSignerService: (data, reqConfig, urlVar) => {
    return updateSignerEP.post(data, reqConfig, urlVar);
  },
  sendEmailToUserWhenSignMassiveService: (data, reqConfig, urlVar) => {
    return sendEmailToUserEP.post(data, reqConfig, urlVar);
  },
  massiveDownloadFilesService: (data, reqConfig, urlVar) => {
    return massiveDownloadEP.post(data, reqConfig, urlVar);
  },
  resetAllDownloadsService: (data, reqConfig, urlVar) => {
    return resetAllDownloadsEP.post(data, reqConfig, urlVar);
  },

  downloadPdfByDateService: (data, reqConfig, urlVar) => {
    return downloadPdfByDateEP.post(data, reqConfig, urlVar);
  },
  getContractsPendingToDownloadService: (data, reqConfig, urlVar) => {
    return getContractsPendingToDownloadEP.post(data, reqConfig, urlVar);
  },
  uploadIdentityDocumentsService: (data, reqConfig, urlVar) => {
    return uploadIdentityDocumentsEP.post(data, reqConfig, urlVar);
  },
  verifyFrontIdService: (data, reqConfig, urlVar) => {
    return verifyFrontIdEP.post(data, reqConfig, urlVar);
  },
  verifyBackIdService: (data, reqConfig, urlVar) => {
    return verifyBackIdEP.post(data, reqConfig, urlVar);
  },
  verifySelfieService: (data, reqConfig, urlVar) => {
    return verifySelfieEP.post(data, reqConfig, urlVar);
  },
  verifyFacesMatchService: (data, reqConfig, urlVar) => {
    return verifyFacesMatchEP.post(data, reqConfig, urlVar);
  },
  generateSoraIdService: (data, reqConfig, urlVar) => {
    return generateSoraIdEP.post(data, reqConfig, urlVar);
  },
  updateUserSoraIdService: (data, reqConfig, urlVar) => {
    return updateUserSoraIdEP.post(data, reqConfig, urlVar);
  },
  uploadContractsMassiveService: (data, reqConfig, urlVar) => {
    return uploadContractsMassiveEP.post(data, reqConfig, urlVar);
  },
  getExcelReportService: (data, reqConfig, urlVar) => {
    return getExcelReportEP.post(data, reqConfig, urlVar);
  },

  //PUT
  changeSignerService: (data, reqConfig, urlVar) => {
    return changeSignerEP.put(data, reqConfig, urlVar);
  },
  softDeleteContractService: (data, reqConfig, urlVar) => {
    return softDeleteContractEP.put(data, reqConfig, urlVar);
  },
  cancelContractService: (data, reqConfig, urlVar) => {
    return cancelContractEP.put(data, reqConfig, urlVar);
  },
};
