import moment from 'moment-timezone';
import state from '../state';
import {
  createAddendumSifeService,
  fileAddendumService,
  getThisAddendumsService,
  getThisAddendumsWithIdService,
  getThisSignersAddendumService,
  signAddendumService,
  createAddendumService,
} from '../API/services/addendumServices';

const AddendumActions = {
  async createAddendumSife(data) {
    const { addendum, user, contract, type, locationUser, signerConfig } = data;
    const email = type === 'sign' ? user.secondaryEmail : user.email;
    const rfc = user.legalRfc;
    const rfcUser = user.legalRfc ? true : false;
    const userType = user.typeUser ? user.typeUser : 'efirma';
    const docsArray = [
      {
        docObjToHash: {
          docInfo: {
            fechaInicio: moment(addendum.createdAt).format('YYYY-MM-DD'),
            fechaFinal: moment(contract.validUntil).format('YYYY-MM-DD'),
            monto: '5000',
          },
        },
        docHash: addendum.lastHash || addendum.hash,
        docId: addendum.unique_id,
        docName: contract.title,
        userEmail: user.email,
        ...(user.legalRfc && { userRFC: user.legalRfc }),
        hashAlgorithm: 'SHA256',
        verifReqAtSigCreation: Boolean(signerConfig?.verifyCreateSign),
        verifReqAtDocSigning: Boolean(signerConfig?.requestVerification),
        geolocReqAtDocSigning: false,
      },
    ];
    const payload = {
      docsArray,
      userType,
      rfcUser,
      email,
      rfc,
      locationUser,
      userIdToUpdate: user.id,
      originalAddendumId: addendum.id,
    };
    const response = await createAddendumSifeService(payload);
    return response;
  },

  /**
   * Create a new Addendum
   * @param  {Object} properties Addendum properties
   * @return {Promise}
   */
  async createAddendum(properties) {
    const formData = new FormData();
    formData.append('file', properties.file);

    const payload = {
      contract: parseInt(properties.contract),
      description: properties.description,
      participants: properties.participants,
      rootUser: properties.rootUser ? properties.rootUser : null,
      cvv2: properties.cvv2,
      orderedSigners: properties.orderedSigners,
    };

    const { data: fileData } = await fileAddendumService(formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    payload.fileUrl = fileData.Location;
    payload.hash = fileData.hash;
    payload.lastSignedTurn = 0;
    const { data: createData } = await createAddendumService(payload);
    state.select(['contracts']).push(createData);
    return createData;
  },

  /**
   * Get signers for this contract
   * @returns {Promise<*>}
   */
  async getThisSignersAddendum(id) {
    const { data } = await getThisSignersAddendumService({}, { id });
    return data;
  },

  /**
   *
   * @param id
   * @returns {Promise<*>}
   */
  async getThisAddendums(id) {
    const { data } = await getThisAddendumsService({}, { id });
    return data;
  },

  async getThisAddendumsWithId(id) {
    const { data } = await getThisAddendumsWithIdService({}, { id });
    return data;
  },

  /**
   * Sign with State user
   * @param addendum
   * @param credentials
   * @param user
   * @param allPartiesSigned
   * @param cuteSign
   * @returns {Promise<*>}
   */
  async signAddendum(info) {
    const formData = new FormData();
    const { addendum, credentials, user, cuteSign, locationUser } = info;
    const { id } = addendum;
    formData.append('cer', credentials.cer);
    formData.append('pdf', addendum.fileUrl);
    formData.append('legalName', user.legalName);
    formData.append('legalRfc', user.legalRfc);
    if (user.representing) {
      formData.append('representing', user.representing);
    }
    if (user.representedRfc) {
      formData.append('representedRfc', user.representedRfc);
    }
    formData.append('cuteSign', cuteSign);
    formData.append('locationUser', JSON.stringify(locationUser));
    const { data } = await signAddendumService(
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
      { id },
    );
    return data;
  },
};

export default AddendumActions;
