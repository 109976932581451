import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { branch } from 'baobab-react/higher-order';
// Logic
import ProfileActions from '../../actions/Profile';
// Profile Modules
import MyInfo from './MyInfo';
// import MyAPI from './MyAPI';
// import MyTemplates from './MyTemplates';
// Modals
import AlertModal from '../../components/Modals/AlertModal';
import Notifications from './Notifications';
import MySignerOrMyAdmins from './MySignerOrMyAdmins';
import ContractAuthorization from './ContractAuthorization';

class Profile extends React.Component {
  /**
   * Constructs the Profile Container
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      showAddCouponVideo: false,
      showDemoModal: false,
    };
  }

  componentDidMount() {
    const { session } = this.props;
    const { user } = session;
    ProfileActions.signers(user.id);
    ProfileActions.admins(user.id);
    ProfileActions.coupons(user.id);
    ProfileActions.userInfo(user.id);
    if (user.apiUser) {
      ProfileActions.templates(user.id);
    }
    if (user.legalRfc === 'BAJF541014RB3') {
      this.showDemoModal();
    }
  }

  handleForm = (event) => {
    event.preventDefault();
  };

  hideAddCouponVideo = () => {
    this.setState({ showAddCouponVideo: false });
  };

  showDemoModal() {
    this.setState({ showDemoModal: true });
  }

  handleDemoModal() {
    this.setState({ showDemoModal: false });
  }

  // TODO: EJECUTANDO ESTA FUNCION CUANDO SEA DISPOSITIVO MOVIL PODRÍAMOS ADAPTAR EL TAB
  /* mobileTab = () => {
    const { width } = this.props;
    if (width?.width < 600) {
      return { width: this.props.width?.width - 120 }
    }
    style={}
  }; */

  render() {
    const {
      session,
      mySigners,
      myAdmins,
      location,
      myTemplates,
      history,
      translations,
      lang,
    } = this.props;

    const { showDemoModal } = this.state;
    const { user } = session;
    const { representing, permissions } = user;

    const { texts } = translations[lang].Profile;
    return (
      <div className="my_profile">
        <h1>{texts.h1}</h1>
        <div className="page padded">
          <Tabs>
            <TabList>
              <Tab>{texts.tab1}</Tab>
              <Tab>{texts.tab2}</Tab>
              <Tab>{texts.tab3}</Tab>
              {/* {user.apiUser ? (
                <Tab>{texts.tab4}</Tab>
              ) : (
                <Tab>{texts.tab5}</Tab>
              )} */}
              <Tab>{texts.tab6}</Tab>
              <Tab>{texts.tab7}</Tab>
            </TabList>

            {/* INFO */}
            <TabPanel>
              <MyInfo
                user={user}
                representing={representing}
                permissions={permissions}
              />
            </TabPanel>

            {/* MY SIGNERS */}
            {user.legalRfc !== 'BAJF541014RB3' ? (
              <TabPanel>
                <MySignerOrMyAdmins
                  mySigners={mySigners}
                  user={user}
                  history={history}
                  location={location}
                  type="signer"
                />
              </TabPanel>
            ) : (
              <TabPanel></TabPanel>
            )}

            {/* MY ADMINS */}
            {user.legalRfc !== 'BAJF541014RB3' ? (
              <TabPanel>
                <MySignerOrMyAdmins
                  mySigners={myAdmins}
                  history={history}
                  user={user}
                  location={location}
                  type="admin"
                />
              </TabPanel>
            ) : (
              <TabPanel></TabPanel>
            )}

            {/* MY API */}
            {/* {!user.apiUser && user.legalRfc !== 'BAJF541014RB3' ? (
              <TabPanel>
                <MyAPI />
              </TabPanel>
            ) : null} */}

            {/* MY TEMPLATES */}
            {/* {user.apiUser && user.legalRfc !== 'BAJF541014RB3' ? (
              <TabPanel>
                <MyTemplates myTemplates={myTemplates} location={location} />
              </TabPanel>
            ) : null} */}

            {user.legalRfc !== 'BAJF541014RB3' ? (
              <TabPanel>
                <ContractAuthorization
                  myTemplates={myTemplates}
                  location={location}
                />
              </TabPanel>
            ) : (
              <TabPanel></TabPanel>
            )}

            {user.legalRfc !== 'BAJF541014RB3' ? (
              <TabPanel>
                <Notifications myTemplates={myTemplates} location={location} />
              </TabPanel>
            ) : (
              <TabPanel></TabPanel>
            )}
          </Tabs>

          {showDemoModal ? (
            <AlertModal
              message={texts.alertModalBody1}
              header={texts.alertModalTitle1}
              okButton={() => this.handleDemoModal()}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default branch(
  {
    session: ['session'],
    mySigners: ['mySigners'],
    myAdmins: ['myAdmins'],
    myCards: ['myCards'],
    myTemplates: ['myTemplates'],
    myCoupons: ['myCoupons'],
    myInfo: ['myInfo'],
    width: ['width'],
    translations: ['translations'],
    lang: ['lang'],
  },
  Profile,
);
