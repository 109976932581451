import React from 'react';

const Loader = ({ totalLoading, color }) => (
  <>
    <div className="sk-chase">
      <div className={`sk-chase-dot ${color ? 'new_color' : ''}`} />
      <div className={`sk-chase-dot ${color ? 'new_color' : ''}`} />
      <div className={`sk-chase-dot ${color ? 'new_color' : ''}`} />
      <div className={`sk-chase-dot ${color ? 'new_color' : ''}`} />
      <div className={`sk-chase-dot ${color ? 'new_color' : ''}`} />
      <div className={`sk-chase-dot ${color ? 'new_color' : ''}`} />
      <br></br>
    </div>
    {totalLoading ? <p>{totalLoading}%</p> : null}
  </>
);

export default Loader;
