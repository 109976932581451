import { endpointHandler } from '../createEndpoint';

const {
  getCouponsEP,
  getUsedCouponsEP,
  getConservancyRecordsEP,
  createCouponEP,
  searchContractsEP,
  searchContractsUserEP,
  searchFileUrlEP,
  searchDateEP,
  getApiValidatedEP,
  getApiRequestsEP,
  updateApiUserEP,
  createApiUserEP,
  disableCouponEP,
  getContractCountEP,
  getUserCountEP,
  getContractsByMonthEP,
  getContractsInfoEP,
  getUsersInfoEP,
  getUsersByMonthEP,
  getTokenUsersEP,
  updateTokenEP,
  getSignerByDateEP,
  updateUserSuperAdminEP,
  deleteUserEP,
  getSignersOrAdminsEP,
  createContractsEP,
  updateSpUserCouponEP,
  deleteRecipientSuperAdminEP,
  addCopiedUserSuperAdminEP,
  updateSignerSuperUserEP,
} = endpointHandler();

export const superUserServices = {
  // GET
  getCouponsService: (reqConfig, urlVar) => {
    return getCouponsEP.get(reqConfig, urlVar);
  },
  getUsedCouponsService: (reqConfig, urlVar) => {
    return getUsedCouponsEP.get(reqConfig, urlVar);
  },
  getConservancyRecordsService: (reqConfig, urlVar) => {
    return getConservancyRecordsEP.get(reqConfig, urlVar);
  },
  searchContractsService: (reqConfig, urlVar) => {
    return searchContractsEP.get(reqConfig, urlVar);
  },
  searchContractsUserService: (reqConfig, urlVar) => {
    return searchContractsUserEP.get(reqConfig, urlVar);
  },
  searchFileUrlService: (reqConfig, urlVar) => {
    return searchFileUrlEP.get(reqConfig, urlVar);
  },
  searchDateService: (reqConfig, urlVar) => {
    return searchDateEP.get(reqConfig, urlVar);
  },
  getApiValidatedService: (reqConfig, urlVar) => {
    return getApiValidatedEP.get(reqConfig, urlVar);
  },
  getApiRequestsService: (reqConfig, urlVar) => {
    return getApiRequestsEP.get(reqConfig, urlVar);
  },
  getContractCountService: (reqConfig, urlVar) => {
    return getContractCountEP.get(reqConfig, urlVar);
  },
  getUserCountService: (reqConfig, urlVar) => {
    return getUserCountEP.get(reqConfig, urlVar);
  },
  getContractsByMonthService: (reqConfig, urlVar) => {
    return getContractsByMonthEP.get(reqConfig, urlVar);
  },
  getContractsInfoService: (reqConfig, urlVar) => {
    return getContractsInfoEP.get(reqConfig, urlVar);
  },
  getUsersInfoService: (reqConfig, urlVar) => {
    return getUsersInfoEP.get(reqConfig, urlVar);
  },
  getUsersByMonthService: (reqConfig, urlVar) => {
    return getUsersByMonthEP.get(reqConfig, urlVar);
  },
  getTokenUsersService: (reqConfig, urlVar) => {
    return getTokenUsersEP.get(reqConfig, urlVar);
  },
  getSinersByDateService: (reqConfig, urlVar) => {
    return getSignerByDateEP.get(reqConfig, urlVar);
  },
  getSignersOrAdminsService: (reqConfig, urlVar) => {
    return getSignersOrAdminsEP.get(reqConfig, urlVar);
  },

  // POST
  createCouponService: (data, reqConfig, urlVar) => {
    return createCouponEP.post(data, reqConfig, urlVar);
  },
  createApiUserService: (data, reqConfig, urlVar) => {
    return createApiUserEP.post(data, reqConfig, urlVar);
  },
  disableCouponService: (data, reqConfig, urlVar) => {
    return disableCouponEP.post(data, reqConfig, urlVar);
  },

  updateUserServiceSuperAdmin: (data, reqConfig, urlVar) => {
    return updateUserSuperAdminEP.post(data, reqConfig, urlVar);
  },

  deleteUserService: (data, reqConfig, urlVar) => {
    return deleteUserEP.post(data, reqConfig, urlVar);
  },

  createContractsService: (data, reqConfig, urlVar) => {
    return createContractsEP.post(data, reqConfig, urlVar);
  },
  updateSpUserCouponService: (data, reqConfig, urlVar) => {
    return updateSpUserCouponEP.post(data, reqConfig, urlVar);
  },
  deleteRecipientSuperAdminService: (data, reqConfig, urlVar) => {
    return deleteRecipientSuperAdminEP.post(data, reqConfig, urlVar);
  },
  addCopiedUserSuperAdminService: (data, reqConfig, urlVar) => {
    return addCopiedUserSuperAdminEP.post(data, reqConfig, urlVar);
  },
  updateSignerSuperUserService: (data, reqConfig, urlVar) => {
    return updateSignerSuperUserEP.post(data, reqConfig, urlVar);
  },

  // PUT
  updateTokenService: (data, reqConfig, urlVar) => {
    return updateTokenEP.put(data, reqConfig, urlVar);
  },
  updateApiUserService: (data, reqConfig, urlVar) => {
    return updateApiUserEP.put(data, reqConfig, urlVar);
  },
};
