import { NotificationManager } from 'react-notifications';
import ProfileActions from '../actions/Profile';

export const addParticipantInNewContract = async ({
  allParticipants = [],
  participantId,
  value,
  userType,
}) => {
  const response = await ProfileActions.searchUserByData({
    value,
  });
  const data = response?.data;
  if (data.type) {
    const { type, users, allVerified } = data;
    if (type === 'USER_NOT_FOUND') {
      allParticipants[participantId].legalRfc = '';
      allParticipants[participantId].disableLegalRfc = false;
      allParticipants[participantId].userId = null;
      allParticipants[participantId].disclaimerMultipleUsers = false;
      allParticipants[participantId].sameUsers = [];

      if (userType === 'signer') {
        allParticipants[participantId].verifyCreateSign = allVerified;
        allParticipants[participantId].hideButtonCreateSign = allVerified;
      }
    }

    if (type === 'SINGLE_USER') {
      const userExist = allParticipants.find(
        (participantId) => participantId.userId === users.id,
      );

      if (userExist && allParticipants.length > 1) {
        allParticipants[participantId].email = '';
        NotificationManager.warning(
          'Ya existe un usuario con la información proporcionada',
          'Atención',
          3000,
        );
        return { type, users, allParticipants };
      }
      allParticipants[participantId].legalRfc = users.legalRfc;
      allParticipants[participantId].legalName = users.legalName;
      allParticipants[participantId].disableLegalRfc = false;
      allParticipants[participantId].userId = users.id;
      allParticipants[participantId].disclaimerMultipleUsers = false;
      allParticipants[participantId].sameUsers = [];

      if (userType === 'signer') {
        allParticipants[participantId].verifyCreateSign = users.allVerified;
        allParticipants[participantId].hideButtonCreateSign = users.allVerified;
      }
    }

    if (type === 'MULTIPLE_USERS') {
      allParticipants[participantId].userId = null;
      allParticipants[participantId].legalRfc = '';
      allParticipants[participantId].legalName = '';
      allParticipants[participantId].disableLegalRfc = true;
      allParticipants[participantId].disclaimerMultipleUsers = true;
      allParticipants[participantId].sameUsers = users;
    }

    return { type, users, allParticipants };
  }
};
