import React from 'react';
import moment from 'moment';
// import Autocomplete from 'react-autocomplete';
import { Fragment } from 'react';
import { branch } from 'baobab-react/higher-order';
import ListSignersDocuments from '../_common/ListSignersDocuments';
import ListVerificationDocs from '../_common/ListVerificationDocs';
import GenericConfirmModal from '../../components/Modals/GenericConfirmModal';
import { NotificationManager } from 'react-notifications';
import ContractActions from '../../actions/Contract';
import { downloadFileToView } from '../../utils/downloadFilesUtils';
import IdentityDocumentModal from '../../components/Modals/IdentityDocumentModal';
import state from '../../state';

class SignersListDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showConfirmModal: false,
      value: '',
      signers: [],
      signer: null,
      showIdentityDocument: false,
      mimeTypeDoc: '',
      isCreator: false,
    };
  }

  componentDidMount = () => {
    const { signers, signer, contract } = this.props;
    const isCreator = this.updateCreatedBy();
    this.setState({ signers, signer, contract, isCreator });
  };

  updateCreatedBy = () => {
    const { contract, allAuthorized, user } = this.props;
    const isCreator =
      contract.createdBy === (user.rootUser || user.id) && allAuthorized;
    return isCreator;
  };

  showConfirmModal = (val) => {
    this.setState({ showConfirmModal: true });
    this.setState({ signerChanged: val });
  };

  hideConfirmModal = () => {
    this.setState({ showConfirmModal: false });
  };

  handleSignerChange = async () => {
    const { signers, signer, contract, signerChanged } = this.state;
    const { admin, lang, translations } = this.props;
    const { notifications } =
      translations[lang].ContractDetail.SignersListDisplay;

    this.setState({ loading: true });
    if (signers.find((found) => found.legalRfc === signerChanged)) {
      this.setState({ loading: false });
      return NotificationManager.warning(
        notifications.notifBody1,
        notifications.notifTitle1,
        10000,
      );
    }

    const user = await ContractActions.searchByRfc({
      legalRfc: signerChanged,
    });
    if (!user.legalRfc) {
      return NotificationManager.warning(
        notifications.notifBody2,
        notifications.notifTitle2,
        10000,
      );
    }
    await ContractActions.changeSigner(admin || signer, user, contract);
    window.location.href = `${window.location.origin}/contratos`;
  };

  onClickDownloadIdentityDocument = async (document, table, userId) => {
    const { updateStateIdentityDocuments, contract, lang, translations } =
      this.props;
    const { notifications } =
      translations[lang].ContractDetail.SignersListDisplay;
    updateStateIdentityDocuments({ loading: true });
    const isSifeUrl = document.fileUrl.includes('//sife-verify');
    const response = !isSifeUrl
      ? await downloadFileToView(document, table)
      : await ContractActions.getInfoVerificationSife({
          docId: contract.unique_id,
          userId,
          type: document.typeId,
        });
    if (isSifeUrl) {
      if (!response.success) {
        updateStateIdentityDocuments({ loading: false });
        return NotificationManager.error(
          notifications.notifBody3,
          notifications.notifTitle3,
          10000,
        );
      }
      state.select('contractUrl').set(response.url);
      updateStateIdentityDocuments({ loading: false });
      return this.setState({
        showIdentityDocument: true,
        mimeTypeDoc: 'image/png',
      });
    }

    if (!response.success) {
      return updateStateIdentityDocuments({ loading: false });
    }

    updateStateIdentityDocuments({ loading: false });
    return this.setState({
      showIdentityDocument: true,
      loading: false,
      mimeTypeDoc: response.headers['content-type'],
    });
  };

  onClickCloseIdentityDocumentModal = () => {
    this.setState({ showIdentityDocument: false });
  };

  disabledButton = (localSigner) => {
    const { contract, user, admin, allAuthorized } = this.props;
    if (!allAuthorized) {
      return false;
    }

    if (localSigner.signed) {
      return false;
    }
    const id = admin?.rootUser || user.id;
    if (id === (contract.rootUser || contract.createdBy)) {
      return true;
    }

    return false;
  };

  showEmailToCreator = () => {
    const { admin, session, contract } = this.props;

    const id = admin ? admin?.rootUser : session.user.id;

    return contract.createdBy === id || contract.rootUser === id;
  };

  canEditRfc = (localSigner) => {
    const { admin, session, contract, allAuthorized } = this.props;
    const id = admin ? admin?.rootUser : session.user.id;

    if (!allAuthorized) {
      return false;
    }

    if (Boolean(localSigner.signed) || Boolean(localSigner.allDocsUploaded))
      return false;

    return (
      !Boolean(localSigner.signed) &&
      (contract.createdBy === id || contract.rootUser === id)
    );
  };

  render() {
    const {
      signers,
      showIdentityDocument,
      signerChanged,
      showConfirmModal,
      mimeTypeDoc,
    } = this.state;

    const {
      contract,
      user,
      onChangeSignTypeInSigner,
      signersDocuments,
      verificationDocs,
      contractUrl,
      admin,
      page,
      openModalToUpdateRfc,
      lang,
      translations,
    } = this.props;

    const { texts, inputs, buttons } =
      translations[lang].ContractDetail.SignersListDisplay;

    return (
      <div className="signers_list_cd">
        <span>{texts.spanTitle1}</span>
        {signers.length ? (
          <div className={`table_list_signers_cd`}>
            {signers.map((localSigner, index) => (
              <Fragment key={index}>
                <div className="sign_document_data">
                  <p className="status_turn_signer">
                    {localSigner.turn}
                    {localSigner.signed ? (
                      <i
                        className="fas fa-check-circle"
                        style={{ color: '#7ED321' }}
                      />
                    ) : null}
                  </p>
                  {/* <p className="legalRfc_signer wrap_text">
                    {localSigner.legalRfc || ''}
                  </p> */}

                  {this.canEditRfc(localSigner) ? (
                    <div className="secondaryEmail_signer flex_edit">
                      <p className="wrap_text">
                        {localSigner?.secondaryEmail || localSigner?.email}
                        <br></br>
                        <small>{localSigner?.legalRfc}</small>
                      </p>
                      <i
                        className="fas fa-pen"
                        onClick={() =>
                          openModalToUpdateRfc(localSigner, 'signers')
                        }
                      />
                    </div>
                  ) : (
                    <div className="secondaryEmail_signer flex_edit">
                      <p className="wrap_text">
                        {localSigner?.secondaryEmail || localSigner?.email}
                        <br></br>
                        <small>{localSigner?.legalRfc}</small>
                      </p>
                    </div>
                  )}

                  <p className="legalName_signer wrap_text">
                    {localSigner.legalName}
                    <br></br>
                    {this.showEmailToCreator() ? (
                      <small>
                        {!localSigner.hasOwnProperty('hash')
                          ? localSigner.email
                          : localSigner.emailSend}
                      </small>
                    ) : null}
                  </p>
                  <div
                    className="change_signature_type_signer"
                    style={{
                      pointerEvents: this.disabledButton(localSigner)
                        ? ''
                        : 'none',
                      opacity: this.disabledButton(localSigner) ? '1' : '0.4',
                      width: '250px',
                    }}
                  >
                    <select
                      name="signType"
                      onChange={(e) => onChangeSignTypeInSigner(e, index)}
                      autoComplete="nope"
                      className="input_nc"
                      disabled={!this.disabledButton(localSigner)}
                    >
                      <option value="" disabled selected>
                        {inputs.selectOptionDefault}
                      </option>
                      <option
                        value={1}
                        selected={
                          parseInt(localSigner.signType) === 1 ? 'selected' : ''
                        }
                      >
                        {inputs.selectOption1}
                      </option>
                      <option
                        value={2}
                        selected={
                          parseInt(localSigner.signType) === 2 ? 'selected' : ''
                        }
                      >
                        {inputs.selectOption2}
                      </option>
                      <option
                        value={3}
                        selected={
                          parseInt(localSigner.signType) === 3 ? 'selected' : ''
                        }
                      >
                        {inputs.selectOption3}
                      </option>
                    </select>
                    <i
                      className="fas fa-angle-down	"
                      style={{ color: 'black' }}
                    />
                  </div>
                  <p className="signature_date_signer column wrap_text">
                    {localSigner.signedAt
                      ? `${moment(localSigner.signedAt).format(
                          'DD MMM YYYY hh:mm a',
                        )}`
                      : false}
                  </p>
                </div>
                <div className="list_documents">
                  <ListSignersDocuments
                    contract={contract}
                    localSigner={localSigner}
                    signersDocuments={signersDocuments}
                    user={user}
                    onClickDownloadIdentityDocument={
                      this.onClickDownloadIdentityDocument
                    }
                    page={page}
                    admin={admin}
                  />
                  {/* VERIFICATION DATA */}
                </div>
                <div className="list_documents">
                  <ListVerificationDocs
                    contract={contract}
                    user={user}
                    localSigner={localSigner}
                    verificationDocs={verificationDocs}
                    onClickDownloadIdentityDocument={
                      this.onClickDownloadIdentityDocument
                    }
                    admin={admin}
                  />
                </div>
              </Fragment>
            ))}
            {showConfirmModal ? (
              <GenericConfirmModal
                title={texts.genericModalTitle1}
                message={texts.genericModalBody1(signerChanged)}
                okButton={buttons.genericModalConfirmButton1}
                cancelButton={buttons.genericModalCancelButton1}
                cancelAction={() => this.hideConfirmModal()}
                acceptAction={() => this.handleSignerChange()}
              />
            ) : null}

            {showIdentityDocument ? (
              <IdentityDocumentModal
                contractUrl={contractUrl}
                mimeTypeDoc={mimeTypeDoc}
                onClickCloseIdentityDocumentModal={
                  this.onClickCloseIdentityDocumentModal
                }
              />
            ) : null}
          </div>
        ) : (
          <p className="no_results">{texts.p1}</p>
        )}
      </div>
    );
  }
}
export default branch(
  {
    session: ['session'],
    contractUrl: ['contractUrl'],
    translations: ['translations'],
    lang: ['lang'],
  },
  SignersListDisplay,
);
