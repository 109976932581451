import React from 'react';
import { branch } from 'baobab-react/higher-order';
// Actions
import { Fragment } from 'react';
import { BrowserRouter, Link, NavLink } from 'react-router-dom';
import SessionActions from '../actions/Session';
import LanguageSwitcher from './Translations/LanguageSwitcher';

const ACTIONS_HOME = [
  {
    title: 'Nuevo Documento',
    pathname: '/nuevo',
    icon: 'fas fa-plus-square',
    translationId: 'link1',
  },
  {
    title: 'Mis documentos',
    pathname: '/contratos',
    icon: 'fas fa-list',
    translationId: 'link2',
  },
  {
    title: 'Configuración',
    pathname: '/perfil',
    icon: 'fas fa-cog',
    translationId: 'link3',
  },
];

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      user,
      location,
      onClickShowList,
      showListSigners,
      showListAdmins,
      width,
      showSidebar,
      translations,
      lang,
    } = this.props;

    const translation = translations[lang].Sidebar;
    const { texts } = translation;
    return (
      <nav className="sidebar">
        <div className="logo_sidebar">
          <Link className="logo" to="/">
            <img
              src="/logo_principal_sora.png"
              alt="Logo"
              onClick={() =>
                width?.width > 992 ? null : onClickShowList('sidebar')
              }
            />
          </Link>
        </div>
        {ACTIONS_HOME?.map((action) => {
          const title = texts[action.translationId];
          return (
            <NavLink
              to={{
                pathname: action.pathname,
                state: {
                  from: location.pathname,
                },
              }}
              className="only_nav"
              onClick={() =>
                width?.width > 992 ? null : onClickShowList('sidebar')
              }
              key={'sidebar_' + action.translationId}
            >
              <i className={action.icon} />
              {title}
            </NavLink>
          );
        })}

        <div className="scrollable_sidebar">
          {user.representing?.length > 0 && (
            <div
              className="dropdown_representing"
              onClick={() => onClickShowList('signer')}
            >
              <div className="user_representing">
                <i className="fas fa-user-tag" />
                {texts.link4}
              </div>
              <div
                className={`list_representing ${
                  showListSigners ? 'active' : ''
                }`}
              >
                {user.representing?.map((signer, index) => (
                  <Fragment key={index}>
                    <BrowserRouter forceRefresh={true} key={index}>
                      <NavLink
                        to={{
                          pathname: `/contracts/on/${signer.rootUser}`,
                          state: {
                            from: location.pathname,
                          },
                        }}
                        className="only_nav"
                        onClick={() =>
                          width?.width > 992 ? null : onClickShowList('sidebar')
                        }
                      >
                        <i className="far fa-user"></i>
                        {signer.legalName}
                      </NavLink>
                    </BrowserRouter>
                  </Fragment>
                ))}
              </div>
            </div>
          )}

          {user.permissions?.length > 0 && (
            <div
              className="dropdown_admin"
              onClick={() => onClickShowList('admin')}
            >
              <div className="user_admin">
                <i className="fas fa-user-shield" />
                {texts.link5}
              </div>
              <div className={`list_admin ${showListAdmins ? 'active' : ''}`}>
                {user.permissions?.map((admin, index) => (
                  <Fragment key={index}>
                    <BrowserRouter forceRefresh={true} key={index}>
                      <NavLink
                        to={{
                          pathname: `/admin/of/${admin.rootUser}`,
                          state: {
                            from: location.pathname,
                          },
                        }}
                        className="only_nav"
                      >
                        <i className="far fa-user"></i>
                        {admin.legalName}
                      </NavLink>
                    </BrowserRouter>
                  </Fragment>
                ))}
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            position: 'absolute',
            bottom: '30px',
            width: '100%',
            padding: '15px',
            boxSizing: 'border-box',
            color: '#fff',
          }}
        >
          <LanguageSwitcher flagGap={showSidebar ? 8 : 100} user={user} />
        </div>
        <div className="close_session" onClick={() => SessionActions.logout()}>
          <i className="fas fa-sign-out-alt"></i>
          {texts.link6}
        </div>
      </nav>
    );
  }
}

export default branch(
  {
    session: ['session'],
    lang: ['lang'],
    translations: ['translations'],
  },
  Sidebar,
);
