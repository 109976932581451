import React from 'react';
import { branch } from 'baobab-react/higher-order';
import { NotificationManager } from 'react-notifications';
import { emailRegex } from '../../../utils/constants';
import SuperUserActions from '../../../actions/SuperUser';
import Loader from '../../../components/Loader';
import ProfileActions from '../../../actions/Profile';

class AddRecipientSuperUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recipient: {
        legalName: '',
        legalRfc: '',
        email: '',
      },
      loading: false,
      sameUsers: [],
      multipleUsers: false,
      selectedRecipient: null,
    };
  }

  componentDidMount = () => {};

  handleChange = async (event) => {
    const { name, value } = event.target;
    const { recipient } = this.state;
    let newValue = value;
    if (name === 'legalRfc') {
      newValue = value.replace(/ /g, '').toUpperCase();
    }

    if (name === 'email') {
      newValue = value.replace(/ /g, '').toLowerCase();
    }

    this.setState({
      recipient: {
        ...recipient,
        [name]: newValue,
      },
    });

    if (newValue.match(emailRegex)) {
      await this.searchUserByData(newValue);
    }
  };

  searchUserByData = async (value) => {
    const { recipient } = this.state;
    const response = await ProfileActions.searchUserByData({
      value,
    });
    const data = response?.data;

    if (data.type) {
      const { type, users } = data;
      this.setState({ selectedRecipient: null });
      if (type === 'USER_NOT_FOUND') {
        this.setState({
          recipient: {
            ...recipient,
            legalName: '',
            legalRfc: '',
            email: value,
            userId: null,
          },
          multipleUsers: false,
          sameUsers: [],
        });
        return;
      }

      if (type === 'SINGLE_USER') {
        this.setState({
          recipient: {
            ...recipient,
            email: value,
            legalName: users.legalName,
            legalRfc: users.legalRfc || '',
            userId: users.id,
          },
          multipleUsers: false,
          sameUsers: [],
        });
        return;
      }

      if (type === 'MULTIPLE_USERS') {
        this.setState({
          recipient: {
            ...recipient,
            email: value,
            legalName: '',
            legalRfc: '',
            userId: null,
          },
          multipleUsers: true,
          sameUsers: users,
        });
        return;
      }
    }
  };

  handleSubmit = async () => {
    const { recipient } = this.state;
    const { contract, searchContractsById, handleCancel } = this.props;
    if (!contract) {
      return NotificationManager.error('El contrato no existe', 'Error', 5000);
    }
    if (!recipient.legalName || !recipient.email) {
      return NotificationManager.error(
        'Debes completar todos los campos.',
        'Error',
        5000,
      );
    }
    if (
      recipient.legalRfc &&
      (recipient.legalRfc < 12 || recipient.legalRfc > 13)
    ) {
      return NotificationManager.error('El rfc no es válido', 'Error', 5000);
    }

    if (!emailRegex.test(recipient.email)) {
      return NotificationManager.error('El email no es válido', 'Error', 5000);
    }
    this.setState({ loading: true });

    try {
      const response = await SuperUserActions.addCopiedUserSuperAdmin({
        contractId: contract.id,
        ...recipient,
      });
      if (!response?.data?.success) {
        this.setState({ loading: false });
        return NotificationManager.error(
          response?.data?.message || 'Ocurrió un error al agregar copiado',
          'Error',
          5000,
        );
      }

      NotificationManager.success(
        response?.data?.message || 'Copiado agregado correctamente',
        'Éxito',
        5000,
      );
      searchContractsById();
      this.setState({ loading: false });
      handleCancel();
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
      return NotificationManager.error(
        'Ocurrió un error al agregar copiado',
        'Error',
        5000,
      );
    }
  };

  handleUpdateUser = (user) => {
    const { recipient } = this.state;
    this.setState({
      recipient: {
        ...recipient,
        legalName: user.legalName,
        legalRfc: user.legalRfc || '',
        userId: user.id,
      },
      selectedRecipient: user,
    });
  };

  render() {
    const { handleCancel } = this.props;
    const { recipient, loading, multipleUsers, sameUsers, selectedRecipient } =
      this.state;

    return (
      <>
        <div className="modal-container mtm-20">
          <div className="modal" style={{ width: 600 }}>
            <h2>Agregar copiado</h2>
            <div className="row" style={{ paddingBottom: 20, paddingLeft: 10 }}>
              {!loading && (
                <>
                  <input
                    type="text"
                    name="email"
                    className="input_nc"
                    required
                    onChange={this.handleChange}
                    placeholder="Ingresa correo"
                    value={recipient.email}
                  />
                  {multipleUsers && (
                    <div className="select_display" style={{ width: '100%' }}>
                      <select
                        name="selectedRecipient"
                        onChange={(event) =>
                          this.handleUpdateUser(
                            sameUsers[event.target.selectedIndex - 1],
                          )
                        }
                        value={
                          selectedRecipient
                            ? JSON.stringify(selectedRecipient)
                            : ''
                        }
                        autoComplete="nope"
                        className="input_nc"
                        // disabled={!this.disabledButton(localSigner)}
                      >
                        <option value="" disabled selected>
                          Selecciona un usuario
                        </option>
                        {sameUsers.map((user, i) => (
                          <option key={i} value={JSON.stringify(user)}>
                            {user.legalName} - {user.legalRfc}
                          </option>
                        ))}
                      </select>
                      <i
                        className="fas fa-angle-down	"
                        style={{ color: 'black' }}
                      />
                    </div>
                  )}
                  <input
                    type="text"
                    name="legalRfc"
                    className="input_nc"
                    maxLength="13"
                    minLength="12"
                    onChange={this.handleChange}
                    placeholder="Ingresa RFC"
                    value={recipient.legalRfc}
                  />
                  <input
                    type="text"
                    name="legalName"
                    className="input_nc"
                    required
                    onChange={this.handleChange}
                    placeholder="Ingresa nombre completo"
                    value={recipient.legalName}
                  />
                </>
              )}
              {loading && (
                <div className="center_loading" style={{ width: '100%' }}>
                  <Loader />
                  <p>Agregando usuario...</p>
                </div>
              )}
            </div>

            <div
              className="col-sm-12"
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <button
                type="button"
                className="secondary"
                onClick={handleCancel}
                style={{ color: 'black' }}
              >
                Regresar
              </button>
              <button
                type="button"
                className="primary"
                onClick={this.handleSubmit}
                disabled={loading}

                // style={{ color: 'black' }}
              >
                Agregar
              </button>
            </div>
          </div>
          <div className="background-screen" />
        </div>
      </>
    );
  }
}

export default branch(
  {
    session: ['session'],
  },
  AddRecipientSuperUser,
);
