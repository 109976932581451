import React from 'react';
import { branch } from 'baobab-react/higher-order';
import Row from './Row';

class TableContracts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {}

  /**
   * Gets the labels for each contract
   * @param labels
   * @param contractId
   * @returns {[]}
   */
  labelsForThisContract = (labels, contractId) => {
    const newList = [];
    labels.map((label) =>
      label.contract === contractId ? newList.push(label) : false,
    );
    return newList;
  };

  onClickSelectAllCheckBox = () => {
    const { updateState, contracts = [], globalContracts = [] } = this.props;
    const { checked } = document.getElementById('myCheckOnly');

    const allContracts = [...contracts];

    const activateContracts = allContracts.map((contract) => {
      const active = { ...contract };
      const isForMe = Boolean(contract.status === 'forMe');
      const hasUploadedDocs = Boolean(
        contract.requestDocument && !contract.allDocsUploaded,
      );
      const hasBeenVerified = Boolean(
        contract.requestVerification && !contract.verified,
      );
      if (isForMe && !hasUploadedDocs && !hasBeenVerified) {
        active.isActive = checked;
      } else {
        active.isActive = false;
      }
      return active;
    });

    const globalContractsUpdated = globalContracts.map((contract) => {
      const active = { ...contract };
      const isForMe = Boolean(contract.status === 'forMe');
      const hasUploadedDocs = Boolean(
        contract.requestDocument && !contract.allDocsUploaded,
      );
      const hasBeenVerified = Boolean(
        contract.requestVerification && !contract.verified,
      );
      const contractExist = activateContracts.find(
        (con) => con.id === contract.id,
      );
      if (
        isForMe &&
        !hasUploadedDocs &&
        !hasBeenVerified &&
        Boolean(contractExist)
      ) {
        active.isActive = checked;
      } else {
        active.isActive = false;
      }
      return active;
    });

    updateState({
      contracts: activateContracts,
      globalContracts: globalContractsUpdated,
    });
  };

  onClickCheckboxContractRow = (event, contract) => {
    const { contracts = [], globalContracts = [], updateState } = this.props;
    const { checked } = event.target;
    const copiedContracts = [...contracts];
    const copiedGlobalContracts = [...globalContracts];
    const contractFound = contracts.find((con) => con.id === contract.id);
    const contractGlobalFOund = contracts.find((con) => con.id === contract.id);
    contractFound.isActive = checked;
    contractGlobalFOund.isActive = checked;

    updateState({
      contracts: copiedContracts,
      globalContracts: copiedGlobalContracts,
    });
  };

  onClickDownloadFileByFile = (contract) => {
    const { contracts = [], globalContracts = [], updateState } = this.props;
    const checked = contract.isDownload ? false : true;
    const copiedContracts = [...contracts];
    const copiedGlobalContracts = [...globalContracts];
    const contractFound = contracts.find((con) => con.id === contract.id);
    const contractGlobalFOund = contracts.find((con) => con.id === contract.id);
    contractFound.isDownload = checked;
    contractGlobalFOund.isDownload = checked;

    updateState({
      contracts: copiedContracts,
      globalContracts: copiedGlobalContracts,
    });
  };

  render() {
    const {
      signerOrAdmin,
      contracts,
      history,
      session,
      labels,
      currentPage,
      contractsPerPage,
    } = this.props;

    const indexOfLastContract = currentPage * contractsPerPage;
    const indexOfFirstContract = indexOfLastContract - contractsPerPage;
    const contractsToShow = contracts.slice(
      indexOfFirstContract,
      indexOfLastContract,
    );

    return (
      <table
        className={`table_list_contracts ${signerOrAdmin ? 'isAdmin' : ''}`}
      >
        <thead>
          <tr>
            {!signerOrAdmin ? (
              <th className="center_table_th">
                <input
                  type="checkbox"
                  name="check"
                  id={`myCheckOnly`}
                  onChange={this.onClickSelectAllCheckBox}
                />
              </th>
            ) : (
              <th></th>
            )}
            <th>Titulo</th>
            <th>Creado</th>
            <th>Emisor</th>
            <th>Vigencia</th>
            {!signerOrAdmin ? <th>Adendums</th> : null}
            <th className="center_table_th">Acciones</th>
          </tr>
        </thead>
        <tbody id="contractsTable" className="scrollable">
          {contractsToShow?.length
            ? contractsToShow.map((contract, index) => (
                <Row
                  contract={contract}
                  key={index}
                  selectContractToSign={this.onClickCheckboxContractRow}
                  user={session?.user}
                  history={history}
                  labels={this.labelsForThisContract(labels, contract.id)}
                  signerOrAdmin={signerOrAdmin}
                  downloadFile={this.onClickDownloadFileByFile}

                  //   actualPage={actualPage}
                />
              ))
            : null}
        </tbody>
      </table>
    );
  }
}

export default branch(
  {
    session: ['session'],
    labels: ['labels'],
  },
  TableContracts,
);
