import { branch } from 'baobab-react/dist-modules/higher-order';
import React from 'react';
import Loader from './Loader';

class BackDrop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inactivityModal: false,
      showListSigners: true,
      showListAdmins: true,
      showSidebar: false,
    };
  }

  render() {
    const { backdrop } = this.props;
    return (
      <>
        {backdrop?.show ? (
          <div className="loading-backdrop">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
              }}
            >
              <Loader color="white" />
              <p style={{ color: 'white' }}>{backdrop?.message}</p>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

export default branch(
  {
    backdrop: ['backdrop'],
  },
  BackDrop,
);
