export const ContractAndPublicDetailHelpersTranslations = {
  es: {
    texts: {
      span1: 'Cancelado',
      span2: 'Firmado por todas las partes',
      span3: 'Listo para mi firma',
      span4: 'Firmado por mí',
      span5: 'Esperando a otros firmantes',
      span6: 'Autorizado por todos',
      span7: 'No autorizado',
      span8: 'Pendiente de autorización',
      span9: 'Firmado por todas las partes',
      span10: 'Esperando a otros firmantes',
      span11: 'Autorizado por mí',
    },
    inputs: {},
    buttons: {},
    notifications: {
      notifTitle1: 'Éxito',
      notifBody1: 'Documento eliminado',
      notifTitle2: 'Atención',
      notifBody2: 'Error al intentar eliminar el documento',
      notifTitle3: 'Éxito',
      notifBody3: 'Documento cancelado',
      notifTitle4: 'Atención',
      notifBody4: 'Error al intentar cancelar el documento',
    },
  },
  en: {
    texts: {
      span1: 'Canceled',
      span2: 'Signed by all parties',
      span3: 'Ready for my signature',
      span4: 'Signed by me',
      span5: 'Waiting for other signers',
      spna6: 'Authorized by all',
      span7: 'Not authorized',
      span8: 'Pending authorization',
      span9: 'Signed by all parties',
      span10: 'Waiting for other signers',
    },
    inputs: {},
    buttons: {},
    notifications: {
      notifTitle1: 'Success',
      notifBody1: 'Document deleted',
      notifTitle2: 'Attention',
      notifBody2: 'Error trying to delete the document',
      notifTitle3: 'Success',
      notifBody3: 'Document canceled',
      notifTitle4: 'Attention',
      notifBody4: 'Error trying to cancel the document',
    },
  },
};
