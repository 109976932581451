import React from 'react';
import { branch } from 'baobab-react/higher-order';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
// Logic
import Pagination from '../components/Pagination';
// Actions
import ContractActions from '../actions/Contract';
import LabelActions from '../actions/LabelActions';
import Loader from '../components/Loader';

import FilterToolbarDisplay from './Contracts/FilterToolbarDisplay';

import GenericConfirmModal from '../components/Modals/GenericConfirmModal';
import { getLocationUtils } from '../utils/locationUtils';

import ButtonsActionsFilesDisplay from './Contracts/ButtonsActionsFilesDisplay';
import TableContracts from './Contracts/TableContracts';

class Contracts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contracts: [],
      loading: true,
      currentPage: 1,
      contractsPerPage: 10,
      getcontract: true,
      contract: null,
      contractAuthorizer: [],
      contractsSign: [],
      locationUser: null,
      cannotSign: false,
      showAlertLocation: false,
      newMessageAlert: false,
      rootLabels: [],
      searchFilter: '',
      labelsFilter: [],
      statusFilter: null,
    };
  }

  async componentDidMount() {
    const { signer, session, lang, translations } = this.props;
    const { notifications } = translations[lang].Contracts;
    const pathname = window.history?.state?.state?.from;
    if (!pathname?.includes('/contratos/')) {
      localStorage.removeItem('currentPage');
    }
    if (!signer) {
      try {
        const contracts = await ContractActions.list({ id: session.user.id });

        const allContracts = [...contracts];

        this.setState({
          globalContracts: allContracts,
          contracts: allContracts,
          contractsSign: allContracts,
        });

        await LabelActions.list();
        await LabelActions.myLabels(session.user.id);
        this.setState({ loading: false });
        this.setState({ getcontract: false });
        // const locationUser = getLocationUtils(
        //   this.onSuccessLocation,
        //   this.onErrorLocation,
        //   this.verifyIfUserHasLocation,
        // );

        // if (locationUser) {
        //   this.setState({ cannotSign: true });
        //   return NotificationManager.warning(
        //     notifications.notifBody1,
        //     notifications.notifTitle1,
        //     10000,
        //   );
        // }

        return;
      } catch (error) {
        this.setState({ loading: false });
        return NotificationManager.error(
          notifications.notifBody2,
          notifications.notifTitle2,
          10000,
        );
      }
    }

    let listContracts = [],
      hash = {};
    const idLabels = signer.protectedDocs
      ? JSON.parse(signer.protectedDocs)
      : null;
    const contracts = await ContractActions.list({ id: signer.rootUser });

    const labels = await LabelActions.listRoot(signer.rootUser);
    await LabelActions.rootLabels(signer.rootUser);
    if (idLabels) {
      const labelsList = labels.filter((label) => {
        return idLabels.indexOf(label.id.toString()) > -1;
      });

      listContracts = labelsList.map((label) => {
        return contracts.find((contract) => contract.id === label.contract);
      });

      //Verifica si al listar algun contrato, alguno no venga como undefined
      //Si viene, filtramos por los que esten cargados
      if (listContracts.includes(undefined)) {
        listContracts = listContracts.filter((contract) => contract);
      }
    }

    //Si no tiene ningún id de etiqueta asignado se muestra todos los contratos
    const filteredContracts = idLabels ? listContracts : contracts;
    let hashedContracts = filteredContracts.filter((o) =>
      hash[o.id] ? false : (hash[o.id] = true),
    );
    return this.setState({
      loading: false,
      getcontract: false,
      contracts: hashedContracts,
      globalContracts: hashedContracts,
    });
  }

  verifyFilters = () => {
    const { searchFilter, labelsFilter, statusFilter } = this.state;

    return Boolean(searchFilter || labelsFilter.length || statusFilter);
  };

  onPageChanged = (page) => {
    this.setState({ currentPage: page || 1 });
  };

  onSuccessLocation = (position) => {
    const {
      coords: { latitude, longitude },
    } = position;
    this.setState({
      locationUser: {
        latitude,
        longitude,
      },
    });
    this.setState({ showAlertLocation: false });
    this.setState({ cannotSign: false });
  };

  onErrorLocation = (error) => {
    this.setState({ cannotSign: true });
    this.setState({ showAlertLocation: false });
    this.setState({ newMessageAlert: true });
  };

  verifyIfUserHasLocation = (result) => {
    if (result.state === 'prompt') {
      this.setState({ showAlertLocation: true });
      this.setState({ cannotSign: true });
    }
  };

  updateState = (state) => {
    this.setState(state);
  };

  render() {
    const { myLabels, history, signer, lang, translations } = this.props;
    const { texts, buttons } = translations[lang].Contracts;

    const {
      getcontract,
      cannotSign,
      showAlertLocation,
      newMessageAlert,
      currentPage,
      contractsPerPage,
      locationUser,
      globalContracts,
      contracts,
    } = this.state;

    return (
      <div className={`grid_list_contracts ${signer ? 'admin_view' : ''}`}>
        {!signer ? (
          <div className="title_page_contracts">
            <h1>{texts.h1}</h1>
            <Link to="/nuevo">{texts.link1}</Link>
          </div>
        ) : null}
        {getcontract ? (
          <div className="center_loading">
            <Loader />
            <p>{texts.p1}</p>
          </div>
        ) : globalContracts?.length ? (
          <>
            <div className="filter_contracts">
              <FilterToolbarDisplay
                contracts={contracts}
                globalContracts={globalContracts}
                updateState={this.updateState}
                myLabels={myLabels}
                signer={signer}
              />
            </div>
            <div className="action_contracts">
              <Pagination
                totalRecords={contracts.length}
                pageLimit={contractsPerPage}
                pageNeighbours={2}
                onPageChanged={this.onPageChanged}
              />
              <ButtonsActionsFilesDisplay
                contracts={contracts}
                globalContracts={globalContracts}
                signer={signer}
                cannotSign={cannotSign}
                signerOrAdmin={signer}
                locationUser={locationUser}
                updateState={this.updateState}
                hasFilters={Boolean(
                  this.state.searchFilter ||
                    this.state.labelsFilter ||
                    this.state.statusFilter,
                )}
                admin={signer}
              />
            </div>
            <div className="list_contracts page">
              <TableContracts
                contracts={contracts}
                signerOrAdmin={signer}
                history={history}
                updateState={this.updateState}
                globalContracts={globalContracts}
                currentPage={currentPage}
                contractsPerPage={contractsPerPage}
              />

              {showAlertLocation ? (
                <GenericConfirmModal
                  title={
                    newMessageAlert
                      ? texts.genericModalTitle3
                      : texts.genericModalTitle4
                  }
                  message={
                    newMessageAlert
                      ? texts.genericModalBody3
                      : texts.genericModalBody4
                  }
                  okButton={buttons.genericModalConfirmButton3}
                  cancelButton={buttons.genericModalCancelButton3}
                  cancelAction={() =>
                    this.setState({ showAlertLocation: false })
                  }
                  acceptAction={() =>
                    this.setState({ showAlertLocation: false })
                  }
                />
              ) : null}
            </div>
          </>
        ) : (
          <div className="empty">
            <h2>{texts.h2}</h2>
            {!signer ? <Link to="/nuevo">{texts.link2}</Link> : null}
          </div>
        )}
      </div>
    );
  }
}

export default branch(
  {
    contracts: ['contracts'],
    session: ['session'],
    labels: ['labels'],
    myLabels: ['myLabels'],
    translations: ['translations'],
    lang: ['lang'],
  },
  Contracts,
);
