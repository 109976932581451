import moment from 'moment';
import React, { Fragment } from 'react';

export const ListParticipantsContracts = ({
  participants,
  onInputContractChange,
  setExternalData,
  canUpdateSigners,
}) => {
  const roleType = (participant) => {
    if (participant.role === 'AUTHORIZER') {
      return {
        signType: 'AUTORIZADOR',
        disabled: true,
        type: 'Autorizador',
        turn: 'Sin turno',
        signedAt: participant.authorizedAt
          ? `${moment(participant.authorizedAt).format('DD MMM YYYY h:mm a')}`
          : 'Sin autorizar',
        registered: participant.userId ? 'SI' : 'NO',
        rfc:
          participant.legalRfc || ''
            ? participant.legalRfc
            : participant.secondaryEmail || participant.email,
        email: participant.secondaryEmail || participant.email,
      };
    }
    if (participant.role === 'RECIPIENT') {
      return {
        signType: 'COPIADO',
        disabled: true,
        type: 'Copiado',
        turn: 'Sin turno',
        signedAt: '---',
        registered: 'ES COPIADO',
        rfc:
          participant.legalRfc || ''
            ? participant.legalRfc
            : participant.secondaryEmail || participant.email,
        email: participant.secondaryEmail || participant.email,
      };
    }
    if (participant.role === 'SIGNER') {
      return {
        signType: participant.signType,
        disabled: true,
        type: 'Firmante',
        turn: participant.turn,
        signedAt: participant.signedAt
          ? `${moment(participant.signedAt).format('DD MMM YYYY h:mm a')}`
          : 'Sin firmar',
        registered: participant.userId ? 'SI' : 'NO',
        rfc:
          participant.legalRfc || ''
            ? participant.legalRfc
            : participant.secondaryEmail || participant.email,
        email: participant.secondaryEmail || participant.email,
      };
    }
  };

  return (
    <div className="list_signers_contract_sp" style={{ marginBottom: 60 }}>
      <div className="header_list_signers_sp">
        <small className="bold">RFC</small>
        <small className="bold">Tipo</small>
        <small className="bold">Turno</small>
        <small className="bold">Firma Asignada</small>
        <small className="bold">Estatus Firma</small>
        <small className="bold">Registrado</small>
        <small className="bold">Verificación</small>
        <small className="bold">Acciones</small>
      </div>
      <div className="body_list_signers_sp">
        {participants.map((participant, index) => (
          <Fragment key={index}>
            <small className="signer_data">
              {participant.role !== 'SIGNER'
                ? roleType(participant).rfc || roleType(participant).email
                : participant.legalRfc}

              <br></br>
              <small className="signer_data normal">
                {participant.legalName}
              </small>
              <br></br>
              {participant.role === 'SIGNER' &&
                (participant?.hasOwnProperty('userId') ? (
                  <small className="signer_data normal">
                    {participant.emailSend || ''}
                  </small>
                ) : null)}
              {!participant?.hasOwnProperty('userId') ? (
                <small className="signer_data normal">
                  {participant.email || ''}
                </small>
              ) : null}
            </small>
            <small className="signer_data">{roleType(participant).type}</small>
            <small className="signer_data">{roleType(participant).turn}</small>
            <input
              className="input_nc"
              type="text"
              defaultValue={roleType(participant).signType}
              onChange={(e) => onInputContractChange(e, index)}
              disabled={roleType(participant).disabled}
              name="signType"
            />
            <small className="signer_data">
              {roleType(participant).signedAt}
            </small>
            <small className="signer_data">
              {roleType(participant).registered}
            </small>
            {participant.role === 'SIGNER' ? (
              <input
                type="checkbox"
                id="cbox2"
                checked={Boolean(participant.requestVerification)}
                name="requestVerification"
                onChange={(e) => onInputContractChange(e, index)}
                disabled={true}
              />
            ) : (
              <small>Sin verif.</small>
            )}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {participant?.role === 'SIGNER' && canUpdateSigners && (
                <i
                  className="far fa-edit"
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    setExternalData({
                      modalToUpdateSigner: true,
                      signerToUpdate: participant,
                    })
                  }
                />
              )}
              {participant.role === 'RECIPIENT' && canUpdateSigners && (
                <i
                  className="far fa-trash-alt"
                  style={{ cursor: 'pointer', fontSize: 20 }}
                  onClick={() =>
                    setExternalData({
                      modalToDeleteRecipient: true,
                      recipientToDelete: participant,
                    })
                  }
                />
              )}
            </div>
          </Fragment>
        ))}
      </div>
      <div className="actions_signers">
        <div
          className="action_signer"
          onClick={() => setExternalData({ modalToAddRecipient: true })}
        >
          <span>Agregar copiado</span>
        </div>
      </div>
    </div>
  );
};
