import React from 'react';
import { branch } from 'baobab-react/higher-order';
import ProfileActions from '../../actions/Profile';
import { usersMostUsedNewContract } from '../../utils/newContractMassiveUtils';
import { emailRegex } from '../../utils/constants';
import { addParticipantInNewContract } from '../../helpers/newContract.helpers';
import { cleanText } from '../../utils/cleanText';

class RecipientsDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTableCopied: false,
      recipients: [],
      contractUser: '',
      searchRecipient: '',
    };
  }

  componentDidMount = async () => {
    const {
      session: { user },
      signer,
      admin,
    } = this.props;
    const contractUser = signer || admin || user;
    const id = contractUser.rootUser || contractUser.id;
    const recipientsArr = await ProfileActions.getLastSigners({
      userId: id,
      legalRfc: contractUser.legalRfc,
      typeUser: 'recipients',
      isAdmin: signer || admin ? 'isAdmin' : 'isUser',
    });

    this.setState({
      contractUser,
      lastRecipients: recipientsArr,
      allRecipients: recipientsArr,
    });
    document.addEventListener('mousedown', this.handleClickOutside);
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    const { searchRecipient, lastRecipients } = this.state;
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      searchRecipient &&
      lastRecipients.length
    ) {
      this.setState({ lastRecipients: [] });
    }
  };

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  onChangeSearchRecipients = (event) => {
    const { allRecipients } = this.state;
    const value = event.target.value;
    const lastChar = value[value.length - 1];
    if (lastChar === '\\' || lastChar === '?') return;
    this.setState({ searchRecipient: value });
    const limitedRecipients = usersMostUsedNewContract(value, allRecipients);
    this.setState({ lastRecipients: limitedRecipients });
  };

  onClickSelectRecipient = async (recipient) => {
    const { updateStateNewContract, recipients } = this.props;
    recipient.userId = recipient.id;
    recipient.disableLegalRfc = true;
    this.setState({ searchRecipient: '', lastRecipients: [] });
    updateStateNewContract({ recipients: [...recipients, recipient] });
  };

  handleTableCopied = () => {
    const { showTableCopied } = this.state;
    this.setState({ showTableCopied: !showTableCopied });
  };

  recipientInputChange = async (recipientId, event) => {
    const { name, value } = event.target;
    const { updateStateNewContract, recipients } = this.props;
    const array = [...recipients];
    let newValue = '';
    if (name === 'legalRfc' || name === 'email') {
      newValue = cleanText({ removeSpace: true, string: value });
    } else {
      newValue = cleanText({ removeSpace: false, string: value });
    }

    array[recipientId][name] = newValue;

    if (name === 'email' && !newValue.match(emailRegex)) {
      array[recipientId].userId = null;
    }

    if (newValue.match(emailRegex)) {
      await this.searchUserByData(newValue, recipientId);
    }

    updateStateNewContract({ recipients: array });
  };

  removeRecipient = (e) => {
    const { updateStateNewContract, recipients } = this.props;
    const array = [...recipients];
    const filteredArray = array.filter((recipient) => recipient !== e);
    updateStateNewContract({ recipients: filteredArray });
  };

  addRecipient = () => {
    const { updateStateNewContract, recipients } = this.props;
    const newRecipient = {
      legalRfc: '',
      legalName: '',
      email: '',
      disableLegalRfc: true,
    };
    updateStateNewContract({ recipients: [...recipients, newRecipient] });
  };

  updateSigner = async (recipient, recipientId) => {
    const { updateStateNewContract, recipients } = this.props;
    const allRecipients = [...recipients];
    allRecipients[recipientId].legalRfc = recipient.legalRfc || '';
    allRecipients[recipientId].legalName = recipient.legalName;
    allRecipients[recipientId].disableLegalRfc = false;
    allRecipients[recipientId].userId = recipient.id;
    return updateStateNewContract({ recipients: allRecipients });
  };

  searchUserByData = async (value, recipientId) => {
    const { updateStateNewContract, recipients } = this.props;
    const allRecipients = [...recipients];

    const { allParticipants } = await addParticipantInNewContract({
      allParticipants: allRecipients,
      participantId: recipientId,
      value,
      userType: 'recipient',
    });

    return updateStateNewContract({ recipients: allParticipants });
  };

  onKeyUpSearchByEmail = async (e, recipientId) => {
    /* const { value } = e.target;
    if (value.match(emailRegex)) {
      await this.searchUserByData(value, recipientId);
    } */
  };

  onClickEnable = (recipientId, type) => {
    const { updateStateNewContract, recipients } = this.props;
    const allRecipients = [...recipients];
    if (type === 'closeDisclaimer') {
      allRecipients[recipientId].disclaimerMultipleUsers = false;
    }

    allRecipients[recipientId].disableLegalRfc = true;
    updateStateNewContract({ recipients: allRecipients });
  };

  render() {
    const { showTableCopied, lastRecipients, searchRecipient } = this.state;
    const { recipients, translations, lang } = this.props;
    const { texts, inputs, buttons } =
      translations[lang].NewContract.Recipients;

    return (
      <>
        <div className="dropdown_tables" style={{ marginTop: 20 }}>
          <span style={{ color: 'black' }}>{texts.spanTitle}</span>
          {showTableCopied ? (
            <i
              className="fas fa-chevron-up"
              onClick={this.handleTableCopied}
            ></i>
          ) : (
            <i
              className="fas fa-chevron-down"
              onClick={this.handleTableCopied}
            ></i>
          )}
        </div>
        {showTableCopied ? (
          <>
            <div className="container_config_recipients">
              <div className="container_search_recipient">
                <div className="input_search_signer">
                  <i
                    className="fas fa-search iconoInput"
                    style={{ color: '#6C6C6C' }}
                  />
                  <input
                    id="myRecipients"
                    name="myRecipients"
                    placeholder={inputs.inputPlaceholder1}
                    onChange={(e) => this.onChangeSearchRecipients(e)}
                    value={searchRecipient}
                    type="text"
                    autoComplete="off"
                    className="input_nc search_signer"
                  />
                </div>
                <div className="dropdown">
                  <div
                    ref={this.setWrapperRef}
                    className="dropdown-content"
                    style={{
                      display:
                        lastRecipients?.length && searchRecipient
                          ? 'block'
                          : 'none',
                    }}
                  >
                    {lastRecipients?.map((recipient, i) => (
                      <div
                        onClick={() => this.onClickSelectRecipient(recipient)}
                        key={i}
                      >
                        <p style={{ marginBottom: 4, marginTop: 0 }}>
                          {recipient.legalName}{' '}
                        </p>
                        <small>
                          {recipient.legalRfc} /{' '}
                          {recipient.secondaryEmail || recipient.email}{' '}
                        </small>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="list_recipients_nc">
              {recipients.length ? (
                recipients.map((recipient, index) => (
                  <div key={index} className="container_inputs_recipients">
                    <div className="inputs_recipients">
                      <div className="legalRfc_recipient_nc">
                        <small>{index + 1}.</small>
                        <input
                          type="email"
                          name="email"
                          autoComplete="nope"
                          value={recipient.email}
                          className="input_nc email_recipient_nc"
                          onChange={(e) => this.recipientInputChange(index, e)}
                          required
                          placeholder="email"
                          // onKeyUp={(e) => this.onKeyUpSearchByEmail(e, index)}
                        />
                      </div>
                      {recipient.disclaimerMultipleUsers ? (
                        <div className="select_display legalName_recipient_nc">
                          <select
                            name="signer"
                            onChange={(event) =>
                              this.updateSigner(
                                recipient?.sameUsers[
                                  event.target.selectedIndex - 1
                                ],
                                index,
                              )
                            }
                            autoComplete="nope"
                            className="input_nc legalName_recipient_nc"
                          >
                            <option value="" disabled selected>
                              {texts.option1}
                            </option>
                            {recipient?.sameUsers.map((user, i) => (
                              <option key={i} value={JSON.stringify(user)}>
                                {user.legalName} - {user.legalRfc}
                              </option>
                            ))}
                          </select>
                          <i
                            className="fas fa-angle-down	"
                            style={{ color: 'black' }}
                          />
                        </div>
                      ) : (
                        <input
                          type="text"
                          minLength="10"
                          name="legalName"
                          value={recipient.legalName}
                          className="input_nc legalName_recipient_nc"
                          required
                          onChange={(e) => this.recipientInputChange(index, e)}
                          placeholder="Nombre"
                        />
                      )}
                    </div>
                    <div className="action_delete_recipient_nc">
                      <small>{index + 1}.</small>
                      <i
                        className={`fas fa-minus-circle`}
                        style={{
                          color: '#f95c5e',
                          cursor: 'pointer',
                        }}
                        onClick={() => this.removeRecipient(recipient)}
                      />
                    </div>
                  </div>
                ))
              ) : (
                <p>{texts.p1}</p>
              )}
            </div>
            <div className="actions_signers">
              <div className="action_signer">
                <span onClick={this.addRecipient}>{buttons.button1}</span>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  }
}

export default branch(
  {
    session: ['session'],
    translations: ['translations'],
    lang: ['lang'],
  },
  RecipientsDisplay,
);
