export const ContractRowTranslations = {
  es: {
    texts: {
      td1: 'Indefinido',
    },
    inputs: {},
    buttons: {},
    notifications: {},
  },
  en: {
    texts: {
      td1: 'Not defined',
    },
    inputs: {},
    buttons: {},
    notifications: {},
  },
};
export const ContractListTranslations = {
  es: {
    texts: {
      th1: 'Titulo',
      th2: 'Creado',
      th3: 'Emisor',
      th4: 'Vigencia',
      th5: 'Adendums',
      th6: 'Acciones',
    },
    inputs: {},
    buttons: {},
    notifications: {},
  },
  en: {
    texts: {
      th1: 'Title',
      th2: 'Created',
      th3: 'Issuer',
      th4: 'Validity',
      th5: 'Addendums',
      th6: 'Actions',
    },
    inputs: {},
    buttons: {},
    notifications: {},
  },
};

export const ButtonsActionsFilesTranslations = {
  es: {
    texts: {
      p1: 'Descargando documentos',
      div1: 'Descargar Archivos',
      div2: 'Descargar Archivos',
    },
    inputs: {},
    buttons: {
      button1: 'Activa tu ubucación para poder firmar',
      button2: 'Firmar documentos',
    },
    notifications: {
      notifBody1: 'Error al realizar la descarga',
      notifTitle1: 'Error',
      notifBody2: 'Descarga Exitosa',
      notifTitle2: 'Éxito',
      notifBody3: 'No hay documentos en ese rango de fechas',
      notifTitle3: 'Warning',
      notifBody4: 'No se encontraron documentos para firmar.',
      notifTitle4: 'Warning',
      notifBody5:
        'No pudimos obtener la ubicación del usuario para firmar los documentos.',
      notifTitle5: 'Warning',
      notifBody6: 'Firmando documentos...\nEsto puede tardar unos minutos.',
      notifTitle6: 'Warning',
      notifBody7: 'Documentos firmados con éxito',
      notifTitle7: 'Éxito',
      notifBody8:
        'Hubo algunos contratos que no se firmaron. Recarga y vuelve a intentar',
      notifTitle8: 'Error',
      error1: 'An error occurred in the signature',
    },
  },
  en: {
    texts: {
      p1: 'Downloading documents',
      div1: 'Download Files',
      div2: 'Download Files',
    },
    inputs: {},
    buttons: {
      button1: 'Enable your location to sign',
      button2: 'Sign documents',
    },
    notifications: {
      notifBody1: 'Error while downloading',
      notifTitle1: 'Error',
      notifBody2: 'Download Successful',
      notifTitle2: 'Success',
      notifBody3: 'No documents found within that date range',
      notifTitle3: 'Warning',
      notifBody4: 'No documents found to sign.',
      notifTitle4: 'Warning',
      notifBody5: 'Could not obtain user location to sign documents.',
      notifTitle5: 'Warning',
      notifBody6: 'Signing documents...\nThis may take a few minutes.',
      notifTitle6: 'Warning',
      notifBody7: 'Documents signed successfully',
      notifTitle7: 'Success',
      notifBody8: 'Some contracts were not signed. Reload and try again',
      notifTitle8: 'Error',
    },
  },
};

// ! NO NEED TO TRANSLATE
export const PagintaionTranslations = {
  es: {
    texts: {},
    inputs: {},
    buttons: {},
    notifications: {},
  },
  en: { texts: {}, inputs: {}, buttons: {}, notifications: {} },
};

export const FilterToolbarTranslations = {
  es: {
    texts: {
      labelTitle1: 'Filtrar por etiquetas',
      labelText1: 'No hay etiquetas',
      labelTitle2: 'Filtrar por estatus',
      labelTooltip1: 'Habilita todos tus documentos para descargar nuevamente',
      labelTitle3: 'Restablecer descargas',
      li1: 'Esperando a otros firmantes',
      li2: 'Listo para mi firma',
      li3: 'Firmado por mi',
      li4: 'Firmado por todas las partes',
      li5: 'Copiados',
      li6: 'Cancelados',
    },
    inputs: {
      inputPlaceholder1: 'Buscar contratos...',
    },
    buttons: {},
    notifications: {},
  },
  en: {
    texts: {
      labelTitle1: 'Filter by labels',
      labelText1: 'No labels',
      labelTitle2: 'Filter by status',
      labelTooltip1: 'Enable all your documents to be download again',
      labelTitle3: 'Reset downloads',
      li1: 'Waiting for other signers',
      li2: 'Ready for my signature',
      li3: 'Signed by me',
      li4: 'Signed by all parties',
      li5: 'Copied',
      li6: 'Canceled',
    },
    inputs: {
      inputPlaceholder1: 'Search contracts...',
    },
    buttons: {},
    notifications: {},
  },
};

export const GenericSignModal = {
  es: {
    texts: {
      text1: 'Firmando documentos...',
      small1: 'documentos seleccionados',
      small2: 'documentos firmados',
      small3: 'Documentos Firmados con éxito',
      small4: '*No guardamos ninguna clave de la firma en Sora',
      small5:
        'Los siguientes documentos no se firmaron porque ocurrió un error. Guarda el listado y contacta a soporte',
      text2: 'Firmar documentos',
      text3: 'No hay contratos para firmar',
      small6: 'Titulo',
      small7: 'Error',
    },
    inputs: {
      inputPlaceholder1: 'Certificado (.cer)',
      inputPlaceholder2: 'Llave privada (.key)',
      inputPlaceholder3: 'Clave privada (contraseña)',
    },
    buttons: {
      button1: 'Regresar',
      button2: 'Firmar',
    },
    notifications: {},
  },
  en: {
    texts: {
      text1: 'Signing documents...',
      small1: 'selected documents',
      small2: 'signed documents',
      small3: 'Documents signed successfully',
      small4: '*We do not store any signature keys in Sora',
      small5:
        'The following documents were not signed due to an error. We advise you to save the list and contact support.',
      text2: 'Sign documents',
      text3: 'No contracts to sign',
      small6: 'Titlte',
      small7: 'Error',
    },
    inputs: {
      inputPlaceholder1: 'Certificate (.cer)',
      inputPlaceholder2: 'Private key (.key)',
      inputPlaceholder3: 'Private code (password)',
    },
    buttons: {
      button1: 'Back',
      button2: 'Sign',
    },
    notifications: {},
  },
};

export const ContractsTranslations = {
  es: {
    texts: {
      h1: 'Mis Documentos',
      h2: 'No hay documentos',
      link1: 'Nuevo +',
      link2: 'Agregar uno nuevo +',
      p1: 'Cargando documentos',
      li1: 'Esperando a otros firmantes',
      li2: 'Listo para mi firma',
      li3: 'Firmado por mi',
      li4: 'Firmado por todas las partes',
      li5: 'Copiados',
      li6: 'Cancelados',
      genericModalTitle1: 'Cancelar documento',
      genericModalBody1:
        'Puedes cancelar este documento ya que no ha sido firmado por todas las partes. ¿Estás seguro que deseas cancelar este documento?',
      genericModalTitle2: 'Eliminar documento',
      genericModalBody2:
        'Este documento se eliminará de manera permanente. ¿Estás seguro que deseas eliminarlo?',
      genericModalTitle3: 'Ubicación bloqueada',
      genericModalBody3:
        'Porfavor busca en la configuración de tu navegador como restablecer los permisos de la ubiación, de otra manera no podrás firmar tus documentos.',
      genericModalTitle4: 'Permiso de usar tu ubicación',
      genericModalBody4:
        'Para poder usar tu ubicación necesitamos que des click en permitir. Esto es necesario ya que de otra manera el navegador bloqueará nuestro acceso a tu ubicación y no podrás firmar',
      noResults: 'La búsqueda no arrojó resultados',
    },
    inputs: {},
    buttons: {
      genericModalConfirmButton1: 'Cancelar',
      genericModalCancelButton1: 'Volver',
      genericModalConfirmButton2: 'Eliminar',
      genericModalCancelButton2: 'Volver',
      genericModalConfirmButton3: 'Continuar',
      genericModalCancelButton3: 'Volver',
    },
    notifications: {
      notifTitle1: 'Atención',
      notifBody1: 'La geolocalización no está soportada por tu navegador',
      notifTitle2: 'Atención',
      notifBody2:
        'Error al obtener documentos. \n Contacte al equipo de Sora Contratos.',
      notifTitle3: 'Éxito',
      notifBody3: 'Documento eliminado',
      notifTitle4: 'Atención',
      notifBody4: 'Error al intentar eliminar documento',
      notifTitle5: 'Atención',
      notifBody5: 'Error al redireccionar',
      notifTitle6: 'Éxito',
      notifBody6: 'Documento cancelado',
      notifTitle7: 'Atención',
      notifBody7: 'Error al intentar cancelar documento',
      notifTitle8: 'Informativo',
      notifBody8: 'Firmando documentos...\nEsto puede tardar unos minutos.',
      // TODO: translate response
      notifTitle9: '',
      notifBody9: '',
      // TODO: translate response
      notifTitle10: '',
      notifBody10: '',
      //
      notifTitle11: 'Éxito',
      notifBody11: (num) => `${num} Documentos firmados con éxito`,
      notifTitle12: 'Atención',
      notifBody12: 'No se pudo firmar.',
      notifTitle13: 'Error',
      notifBody13: 'Error al realizar la descarga',
      notifTitle14: 'Error',
      notifBody14: 'Error al realizar la descarga',
      // TODO: translate response
      notifTitle15: '',
      notifBody15: '',
      // TODO: translate response
      notifTitle16: '',
      notifBody16: '',
      // TODO: translate response
      notifTitle17: '',
      notifBody17: '',
      //
      notifTitle18: 'Atención',
      notifBody18: 'Descarga Exitosa',
      notifTitle19: 'Atención',
      notifBody19: 'No hay documentos en ese rango de fechas',
      notifTitle20: 'Atención',
      notifBody20: 'Descarga Exitosa',
      // TODO: translate response
      notifTitle21: '',
      notifBody21: '',
      //
    },
    FilterToolbar: { ...FilterToolbarTranslations.es },
    ButtonsActionsFiles: { ...ButtonsActionsFilesTranslations.es },
    ContractList: { ...ContractListTranslations.es },
    ContractRow: { ...ContractRowTranslations.en },
    GenericSignModal: { ...GenericSignModal.es },
  },
  en: {
    texts: {
      h1: 'My Documents',
      h2: 'No documents',
      link1: 'New +',
      link2: 'Add a new one +',
      p1: 'Loading documents',
      li1: 'Waiting for other signers',
      li2: 'Ready for my signature',
      li3: 'Signed by me',
      li4: 'Signed by all parties',
      li5: 'Copied',
      li6: 'Canceled',
      genericModalTitle1: 'Cancel document',
      genericModalBody1:
        'You can cancel this document because it has not been signed by all parties. Are you sure you want to cancel this document?',
      genericModalTitle2: 'Delete document',
      genericModalBody2:
        'This document will be permanently deleted. Are you sure you want to delete it?',
      genericModalTitle3: 'Location blocked',
      genericModalBody3:
        'Please look in the settings of your browser as to reset the location permissions, otherwise you will not be able to sign your documents.',
      genericModalTitle4: 'Permission to use your location',
      genericModalBody4:
        'To be able to use your location we need you to click on allow. This is necessary because otherwise the browser will block our access to your location and you will not be able to sign',
      noResults: "Search didn't match any documents",
    },
    inputs: {},
    buttons: {
      genericModalConfirmButton1: 'Cancel',
      genericModalCancelButton1: 'Back',
      genericModalConfirmButton2: 'Delete',
      genericModalCancelButton2: 'Back',
      genericModalConfirmButton3: 'Continue',
      genericModalCancelButton3: 'Back',
    },
    notifications: {
      notifTitle1: 'Attention',
      notifBody1: 'Geolocation is not supported by your browser',
      notifTitle2: 'Attention',
      notifBody2:
        'Error getting documents. \n Contact the Sora Contracts team.',
      notifTitle3: 'Success',
      notifBody3: 'Document deleted',
      notifTitle4: 'Attention',
      notifBody4: 'Error trying to delete document',
      notifTitle5: 'Attention',
      notifBody5: 'Error redirecting',
      notifTitle6: 'Success',
      notifBody6: 'Document canceled',
      notifTitle7: 'Attention',
      notifBody7: 'Error trying to cancel document',
      notifTitle8: 'Information',
      notifBody8: 'Signing documents...\nThis can take a few minutes.',
      // TODO: translate response
      notifTitle9: '',
      notifBody9: '',
      // TODO: translate response
      notifTitle10: '',
      notifBody10: '',
      //
      notifTitle11: 'Success',
      notifBody11: (num) => `${num} Documents signed successfully`,
      notifTitle12: 'Attention',
      notifBody12: 'Could not sign.\n Contact the Sora Contracts team.',
      notifTitle13: 'Error',
      notifBody13: 'Error downloading',
      notifTitle14: 'Error',
      notifBody14: 'Error downloading',
      // TODO: translate response
      notifTitle15: '',
      notifBody15: '',
      // TODO: translate response
      notifTitle16: '',
      notifBody16: '',
      // TODO: translate response
      notifTitle17: '',
      notifBody17: '',
      //
      notifTitle18: 'Attention',
      notifBody18: 'Successful download',
      notifTitle19: 'Attention',
      notifBody19: 'There are no documents in that date range',
      notifTitle20: 'Attention',
      notifBody20: 'Successful download',
      // TODO: translate response
      notifTitle21: '',
      notifBody21: '',
      //
    },
    FilterToolbar: { ...FilterToolbarTranslations.en },
    ButtonsActionsFiles: { ...ButtonsActionsFilesTranslations.en },
    ContractList: { ...ContractListTranslations.en },
    ContractRow: { ...ContractRowTranslations.en },
    GenericSignModal: { ...GenericSignModal.en },
  },
};
