import moment from 'moment-timezone';
import { contractStatusConstant } from './constants';

export const massiveDownloadFilesUtils = (data, nameZip) => {
  if (data.type === 'zip') {
    const linkSource = `data:application/zip;base64,${data.base64}`;
    const downloadLink = document.createElement('a');
    const fileName = `DOCUMENTOS-${nameZip}`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    return;
  }

  for (let i = 0; i < data.base64.length; i++) {
    const pdf = data.base64[i];
    const linkSource = `data:application/pdf;base64,${pdf.base64}`;
    const downloadLink = document.createElement('a');
    const fileName = `${pdf.name}`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
};

export const getContractsToSign = (contracts, user) => {
  let contractsToSign = [];
  if (!user.typeUser) {
    contractsToSign = contracts.filter(
      (contract) =>
        contract.isActive &&
        contract.status === 'forMe' &&
        (contract.signType === '1' ||
          contract.signType === '2' ||
          !contract.signType),
    );
  }

  if (user.typeUser) {
    contractsToSign = contracts.filter(
      (contract) =>
        contract.isActive &&
        contract.status === 'forMe' &&
        contract.signType === '2',
    );
  }

  return contractsToSign;
};

export const statusInContractUtils = (status) => {
  if (status === 'ready') {
    return contractStatusConstant.ready();
  }

  if (status === 'forMe') {
    return contractStatusConstant.forMe();
  }

  if (status === 'copied') {
    return contractStatusConstant.copied();
  }

  if (status === 'waitingOthers') {
    return contractStatusConstant.waitingOthers();
  }

  if (status === 'cancelled') {
    return contractStatusConstant.cancelled();
  }

  if (status === 'authPending') {
    return contractStatusConstant.authPending();
  }

  if (status === 'noAuthorized') {
    return contractStatusConstant.noAuthorized();
  }

  return contractStatusConstant.readyByMe();
};

export const contractsToDownloadExcelUtils = (contracts, fromDate, toDate) => {
  const allContracts = contracts.map((contract) => ({
    Titulo: contract.title,
    FechaCreacion: moment(contract.createdAt).format('YYYY-MM-DD'),
    Descripcion: contract.description,
    Estatus: statusInContractUtils(contract.status),
    FirmadoPorMi: contract.signed ? 'SI' : 'NO',
    FueDescargado: contract.downloadedPdf ? 'DESCARGADO' : 'NO_DESCARGADO',
    Emisor: contract.legalName,
  }));

  const filteredDateContracts = allContracts.filter(
    (contract) =>
      contract.FechaCreacion >= fromDate && contract.FechaCreacion <= toDate,
  );

  return filteredDateContracts;
};
