import React from 'react';
import { Link } from 'react-router-dom';
import { branch } from 'baobab-react/higher-order';
import { NotificationManager } from 'react-notifications';
// import moment from 'moment';
import ContractActions from '../actions/Contract';
import ProfileActions from '../actions/Profile';
import GenericContractPreview from '../components/GenericContractPreview';
import Loader from '../components/Loader';
import {
  renderStatus,
  renderStatusAuthorizer,
} from '../helpers/ContractAndPublicDetailHelpers';
import AuthorizersListDisplay from './ContractDetails/AuthorizersListDisplay';
import CopiedListDisplay from './ContractDetails/CopiedListDisplay';
import AddendumActions from '../actions/AddendumActions';
import { downloadFileToView } from '../utils/downloadFilesUtils';
import LabelActions from '../actions/LabelActions';
import StatusContractDisplay from './ContractDetails/StatusContractDisplay';
import LabelsDisplay from './ContractDetails/LabelsDisplay';
import SignersListDisplay from './ContractDetails/SignersListDisplay';
import AddendumsDisplay from './ContractDetails/AddendumsDisplay';
import ActionButtons from './_common/ActionButtons';
import UpdateSignerModal from '../components/Modals/UpdateSignerModal';

class PublicContractDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signers: [],
      mySigners: [],
      recipients: [],
      admin: {},
      contract: null,
      value: '',
      loading: false,
      showDeletePopUp: false,
      showCancelPopUp: false,
      array: [],
      allAuthorized: false,
      allAuthorizersList: [],
      dropdown: ['Usar cualquier firma', 'e.firma (SAT)', 'SIFE'],
      signersDropdown: false,
      showAddendumForm: false,
      addendums: [],
      showAddendumModalSigned: false,
      addendum: null,
      signersDocuments: [],
      showIdentityDocument: false,
      mimeTypeDoc: '',
      myLabels: [],
      labels: [],
      showDialogToEditRfc: false,
      signerToUpdate: null,
      typeOfUpdate: '',
    };
  }

  async componentDidMount() {
    localStorage.removeItem('redirect');
    const { match, session, location } = this.props;
    const { user } = session;
    const admin = location.state ? location.state.admin : null;
    this.setState({ loadInfo: true });

    const contract = await ContractActions.view(match.params.contractId);
    const participants = await ContractActions.getThisSigners(contract.id);
    const signers = participants.allSignersAuth.length
      ? participants.allSignersAuth
      : participants.allSigners;
    this.redirectIfUserDoesnExist({
      allAuthorizers: participants.allAuthorizers,
      signers,
      recipients: participants.recipients,
      admin,
    });
    const signer = admin || user;
    const labels = await ContractActions.getThisLabels(contract.id);
    const id = admin ? admin.rootUser : user.id;
    const myLabels = await LabelActions.rootLabels(id);
    const mySigners = await ProfileActions.signers(id);

    const addendums = await AddendumActions.getThisAddendums(contract.id);
    const addendum = addendums.find((addendum) => !addendum.allPartiesSigned);
    this.setState({ signerAddendum: signer });
    this.setState({
      contract,
      signers: signers.sort((a, b) => {
        return a.turn - b.turn;
      }),
      signer,
      myLabels,
      labels,
      mySigners,
      admin,
      authorizers: participants.allAuthorizers,
      signersDocuments: participants.documents,
      myDocuments: participants.documents.filter(
        (doc) => doc.userId === user.id && !doc.uploaded,
      ),
      verificationDocs: participants.verificationDocs,
      value: admin ? admin.legalName : user.legalName,
      recipients: participants.recipients,
      addendums,
      addendum,
      canAddAddendum: contract.allPartiesSigned,
    });
  }

  redirectIfUserDoesnExist = (data) => {
    const { session, history, location } = this.props;
    const { allAuthorizers, signers, recipients, admin } = data;
    const userId = admin ? admin.rootUser : session.user.id;
    const allUsers = [...allAuthorizers, ...signers, ...recipients];
    const userExist = allUsers.some((user) => user.userId === userId);
    const updateBySignerExist = signers.some(
      (signer) => signer.updatedBy === userId,
    );

    if (!userExist && !updateBySignerExist) {
      history.push({
        pathname: '/contratos',
        state: {
          from: location.pathname,
        },
      });
    }
  };

  showModal = async () => {
    const { contract } = this.state;
    this.setState({ loading: true });
    const response = await downloadFileToView(contract, 'contract');
    if (!response.success) {
      return this.setState({ loading: false });
    }
    return this.setState({ loading: false, showModal: true });
  };

  hideModal = () => {
    this.setState({ showModal: false });
    this.setState({ showAddendumModalSigned: false });
    this.setState({ addendum: null });
  };

  showAddendumModalSigned = async (addendum) => {
    this.setState({ addendum, loading: true });
    const response = await downloadFileToView(addendum, 'contract_addendum');
    if (!response.success) {
      return this.setState({ loading: false });
    }
    return this.setState({ loading: false, showAddendumModalSigned: true });
  };

  onChangeSignTypeInSigner = (e, index) => {
    const { name, value } = e.target;
    const { signers } = this.state;
    const finalSigners = [...signers];
    finalSigners[index][name] = value;
    this.setState({ signers: finalSigners });
    this.setState({ signersDropdown: true });
  };

  onClickUpdateSigner = async () => {
    const { signers, contract } = this.state;
    const { translations, lang } = this.props;
    const { notifications } = translations[lang].PublicContractDetail;
    let updatedSignersDropdown = [];
    // eslint-disable-next-line
    signers.map((signer) => {
      updatedSignersDropdown.push({
        id: signer.userId ? signer.userId : signer.id,
        legalRfc: signer.legalRfc,
        signType: signer.signType,
        secondaryEmail: signer.secondaryEmail || signer.email,
      });
    });
    const response = await ContractActions.updateSigner(
      updatedSignersDropdown,
      contract,
    );
    if (response.status === 200) {
      this.setState({ signersDropdown: false });
      return NotificationManager.success(
        notifications.notifBody1,
        notifications.notifTitle1,
        3000,
      );
    }
  };

  getNewLabels = async (label, type) => {
    const { labels, admin } = this.state;
    const { session } = this.props;

    if (type === 'delete') {
      const filteredLabels = labels.filter(
        (labelFilter) => labelFilter.id !== label.id,
      );
      return this.setState({ labels: filteredLabels });
    }

    this.setState({ labels: [...labels, label] });
    if (type === 'add') {
      const id = admin ? admin.rootUser : session.user.id;
      const myLabels = await LabelActions.myLabels(id);
      this.setState({ myLabels });
    }
  };

  updateStateIdentityDocuments = (data) => {
    this.setState(data);
  };

  updateStateAddendum = (data) => {
    this.setState(data);
  };

  openModalToUpdateRfc = (signer, type) => {
    console.log('🚀 ---> type:', type);
    this.setState({
      showDialogToEditRfc: true,
      signerToUpdate: signer,
      typeOfUpdate: type,
    });
  };

  closeModal = () => {
    this.setState({
      showDialogToEditRfc: false,
      signerToUpdate: null,
      typeOfUpdate: '',
    });
  };

  render() {
    const {
      contract,
      signer,
      authorizers,
      showModal,
      signers,
      mySigners,
      recipients,
      loading,
      admin,
      dropdown,
      signersDropdown,
      addendums,
      addendum,
      showAddendumModalSigned,
      signersDocuments,
      myLabels,
      labels,
      verificationDocs,
      value,
      canAddAddendum,
      showDialogToEditRfc,
      signerToUpdate,
      typeOfUpdate,
    } = this.state;
    const { session, totalLoading, contractUrl, translations, lang } =
      this.props;
    const { texts, buttons } = translations[lang].PublicContractDetail;
    if (!contract)
      return (
        <div className="center_loading">
          <Loader totalLoading={totalLoading} />
        </div>
      );
    return (
      <div className="contract_detail">
        {admin && admin.rootUser ? (
          <Link
            to={{
              pathname: admin.fileUrl?.includes('/admins/')
                ? `/admin/of/${admin.rootUser}`
                : `/contracts/on/${admin.rootUser}`,
              state: {
                from: window.location.pathname,
                admin,
              },
            }}
            className="back"
          >
            &lt; {texts.link1}
          </Link>
        ) : (
          <Link to="/contratos" className="back">
            &lt; {texts.link2}
          </Link>
        )}
        <div className="titlebar">
          <h1>
            {contract.title.length <= 45
              ? contract.title
              : `${contract.title.substring(0, 45)}...`}
          </h1>
          {contract.imageUrl && <img src={contract.imageUrl} alt="Logo Sora" />}
        </div>

        <div className="grid_contract_details page">
          <StatusContractDisplay
            allAuthorized={authorizers.every((auth) => auth.signed)}
            signer={signer}
            contract={contract}
            authorizers={authorizers}
            renderStatus={renderStatus}
            renderStatusAuthorizer={renderStatusAuthorizer}
          />
          <LabelsDisplay
            contract={contract}
            labels={labels}
            user={session.user}
            myLabels={myLabels}
            getNewLabels={this.getNewLabels}
            admin={admin}
          />

          <AuthorizersListDisplay
            authorizers={authorizers}
            renderStatusAuthorizer={renderStatusAuthorizer}
          />

          <SignersListDisplay
            signers={signers}
            contract={contract}
            allAuthorized={authorizers.every((auth) => auth.signed)}
            mySigners={mySigners}
            user={admin || session.user}
            onChangeSignTypeInSigner={this.onChangeSignTypeInSigner}
            dropdown={dropdown}
            value={value}
            signersDocuments={signersDocuments}
            verificationDocs={verificationDocs}
            signer={signer}
            admin={admin}
            page="public"
            updateStateIdentityDocuments={this.updateStateIdentityDocuments}
            openModalToUpdateRfc={this.openModalToUpdateRfc}
          />

          <CopiedListDisplay
            recipients={recipients}
            allAuthorized={authorizers.every((auth) => auth.signed)}
            contract={contract}
            openModalToUpdateRfc={this.openModalToUpdateRfc}
            admin={signer || admin}
          />

          <div className="sign_contracts_cd">
            {loading ? (
              <div className="center_loading">
                <Loader />
              </div>
            ) : (
              <>
                {signersDropdown ? (
                  <button
                    className="update"
                    type="button"
                    onClick={this.onClickUpdateSigner}
                  >
                    {buttons.button1}
                  </button>
                ) : null}
                <br></br>
                <button
                  style={{ background: '#6C6C6C' }}
                  type="button"
                  onClick={this.showModal}
                >
                  {buttons.button2}
                </button>
                <br></br>
                {contract.allPartiesSigned ? null : (
                  <ActionButtons contract={contract} admin={admin} />
                )}
              </>
            )}
          </div>

          <AddendumsDisplay
            addendums={addendums}
            isMyTurnToSignAddendum={false}
            showModalAddendum={this.showAddendumModalSigned}
            canAddAddendum={canAddAddendum}
            showAddendumForm={this.showAddendumForm}
            signers={signers}
            contract={contract}
            updateStateAddendum={this.updateStateAddendum}
          />

          {showModal ? (
            <GenericContractPreview
              handleCancel={this.hideModal}
              type="demo"
              contract={contract}
              contractUrl={contractUrl}
            />
          ) : null}

          {showAddendumModalSigned ? (
            <GenericContractPreview
              handleCancel={this.hideModal}
              type="demo"
              contract={addendum}
              contractUrl={contractUrl}
            />
          ) : null}

          {showDialogToEditRfc ? (
            <UpdateSignerModal
              contract={contract}
              signerToUpdate={signerToUpdate}
              closeModal={this.closeModal}
              typeOfUpdate={typeOfUpdate}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default branch(
  {
    session: ['session'],
    totalLoading: ['totalLoading'],
    contractUrl: ['contractUrl'],
    translations: ['translations'],
    lang: ['lang'],
  },
  PublicContractDetail,
);
